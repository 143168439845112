import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusCodes } from 'http-status-codes';
import { ACCESS_TOKEN_NAME, REGISTRATION_TOKEN_NAME } from 'src/environments/environment';
import { APIPathKey } from '../config/consts';
import { API_ENDPOINTS } from '../config/routes';
import { GenericAPIResp } from '../models/api.model';
import { ErrorCodeObj } from '../models/error.model';
import { ApiService } from './api.service';
import * as HttpStatus from 'http-status-codes';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  constructor(
    private api: ApiService
  ) { }

  async authDevAccess(code: string): Promise<boolean> {
    let ep = API_ENDPOINTS.get(APIPathKey.GET_APP_ACCESS_TOKEN);
    const body =
    {
      code: code
    }

    return new Promise<boolean>((resolve: (isValid: boolean) => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
        const token = resp.body?.token;
        if( !!token )
        {
          localStorage.setItem(ACCESS_TOKEN_NAME, token);
          resolve(true);
        }else{
          resolve(false);
        }

      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          if( badResp.statusCode == StatusCodes.NOT_FOUND ){
            resolve(false);
          }else{
            reject(ep.errorCodeGenerator(badResp));
          }
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      })
    });
  }



  async hasAppDevAccess(): Promise<boolean> {

    const ep = API_ENDPOINTS.get(APIPathKey.REFRESH_APP_ACCESS_TOKEN);

    return new Promise<boolean>((resolve: (isValid: boolean) => void, reject: (code: ErrorCodeObj) => void) => {
      const token = localStorage.getItem(ACCESS_TOKEN_NAME);
      if( !!token ){

        const body = {
          token: token
        };

        this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
          const token = resp.body?.token;
          if( !!token )
          {
            localStorage.setItem(ACCESS_TOKEN_NAME, token);
            resolve(true);
          }else{
            localStorage.removeItem(ACCESS_TOKEN_NAME);
            resolve(false);
          }

        }).catch((badResp: GenericAPIResp) => {
          localStorage.removeItem(ACCESS_TOKEN_NAME);
          if (ep !== undefined) {
            reject(ep.errorCodeGenerator(badResp));
          } else {
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });

      }else{
        resolve(false)
      }

    });
  }

  async hasAuth(): Promise<boolean> {

    const ep = API_ENDPOINTS.get(APIPathKey.REFRESH_ACCESS_TOKEN);

    return new Promise<boolean>((resolve: (isValid: boolean) => void, reject: (code: ErrorCodeObj) => void) => {
      const token = localStorage.getItem(REGISTRATION_TOKEN_NAME);
      if( !!token ){

        const body = {
          token: token
        };

        this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
          const token = resp.body?.token;
          if( !!token )
          {
            localStorage.setItem(REGISTRATION_TOKEN_NAME, token);
            resolve(true);
          }else{
            localStorage.removeItem(REGISTRATION_TOKEN_NAME);
            resolve(false);
          }

        }).catch((badResp: GenericAPIResp) => {
          localStorage.removeItem(REGISTRATION_TOKEN_NAME);
          if (ep !== undefined) {
            reject(ep.errorCodeGenerator(badResp));
          } else {
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });

      }else{
        resolve(false)
      }

    });
  }

}
