<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar></app-toolbar>
<div class="content" role="main">
  <ng-container class="form" [formGroup]="form">
    <div class="form-group">
      <hr class="bg-primary">
      <h3 class="text-left mt-4 mb-2text-dark">Dependent Contact Info</h3>
      <div class="text-left" style="font-size: 18px;"><strong class="text-primary">Please provide following contact information for the dependent you are adding</strong></div>
      <hr class="bg-primary mt-4">
      <app-address-info contactName="Dependent" formGroupName="addressInfo" [primaryAddress]="primary?.address"></app-address-info>
      <app-contact-info contactName="Dependent" formGroupName="contactInfo" [primaryContact]="primary?.contact" [loading]="loading" [type]="dependent?.info?.type"></app-contact-info>

      <div class="container mt-4">
        <div class="row">
          <div class="col text-center">
            <div class="card-container">
              <button class="card btn card-small bg-secondary text-white" (click)="onClickBack()">BACK</button>
            </div>
          </div>
          <div class="col text-center">
            <div class="card-container">
              <button class="card btn card-small bg-secondary text-white" (click)="onClickAdd()" [disabled]="form.invalid">ADD</button>
              </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>
