import { Registration, RegistrationAdapter } from "./registration.model";
import { ServiceSortPipe } from "../pipes/service-sort.pipe";
import { ClientInfo } from "./client.model";

export interface SubscriberInfo
{
  id: string;
  type: string;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  genderType: string;
  //clientName: string;
}

export interface SubData
{
  sub: Subscription,
  isSelected: boolean,
  expand: boolean,
  //code: string
};


export interface SubscriberAddress
{
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface SubscriberContact
{
  mobilePhone: string;
  phone: string;
  email: string;
}

export interface SubscriberClient
{
  id: string;
  name: string;
}

export interface ServiceInfo
{
  status: string;
  startDate: any;
  endDate: any;
  serviceTags: Array<string>;
}

export interface DependentListInfo
{
  id: string;
  lastName: string;
  firstName: string;
  type: string;
}

export interface Subscriber
{
  info: SubscriberInfo,
  address: SubscriberAddress,
  contact: SubscriberContact,
  service: ServiceInfo,
  client: SubscriberClient
}

export interface Subscription
{
  reg: Registration,
  primary: Subscriber,
  dependents: Array<Subscriber>
}


export class SubscriberAdapater {

  static adaptInfo(response: any, subscriberType?: string, id?: string): SubscriberInfo
  {
     return {
        id: id,
        type: subscriberType,
        firstName: response?.firstName,
        lastName: response?.lastName,
        dateOfBirth:  response?.dateOfBirth,
        genderType:  String(response?.genderType?.name).toUpperCase()
     } as SubscriberInfo;
  }

  static adaptAddress(response: any): SubscriberAddress
  {
     return {
        addressLine1: response?.addressLine1,
        city: response?.city,
        state: response?.state,
        zipCode: response?.zipCode
      } as SubscriberAddress;
  }

  static adaptContact(response: any): SubscriberContact
  {
     return {
        mobilePhone: response?.mobilePhone,
        phone: response?.phone,
        email: response?.email,
      } as SubscriberContact;
  }

  static adaptClient(response: any): SubscriberClient

  {
     return {
      id: response?.id,
      name: response?.name,
      } as SubscriberClient;
  }

  static adaptService(response: any): ServiceInfo
  {
    const tags = new Array<string>();

    response.serviceInfo.services.forEach((service: any) =>
    {
      tags.push(service.name);
    });

     return {
        startDate: response.startDate,
        endDate: response.endDate,
        serviceTags: tags.length > 0 ? tags : ["PHONE ONLY"],
        status: !!response.subscriberStatus?.name ? response.subscriberStatus?.name : 'UNKNOWN'
      } as ServiceInfo;
  }


  static adaptSingle(response: any, parseServices: boolean = true ): Subscriber
  {
    const service = parseServices ?  SubscriberAdapater.adaptService(response) : {};
    return {
      info: SubscriberAdapater.adaptInfo(response?.person?.personalInfo, response?.subscriberType?.name, response?.id ),
      address: SubscriberAdapater.adaptAddress(response?.person?.address),
      contact: SubscriberAdapater.adaptContact(response?.person?.contact),
      service: service,
      client: SubscriberAdapater.adaptClient(response?.client)
    }as Subscriber;
  }

  static adaptMultiple( response: any, parseServices: boolean = true): Subscriber[]
  {
    const subs = new Array<Subscriber>();

    response.forEach((sub: any) =>
    {
      subs.push( SubscriberAdapater.adaptSingle(sub,parseServices));
    });

    return subs;
  }
}


export class DependentAdapater {

  static adaptList(response: any ): DependentListInfo[]
  {
    let dependents = new Array<DependentListInfo>();

    response.forEach( (item: any) => {
      dependents.push(
        {
          id: item?.id,
          firstName: item?.person?.personalInfo?.firstName,
          lastName: item?.person?.personalInfo?.lastName,
          type: item?.subscriberType?.name
        } as DependentListInfo
      )
    });

    return dependents;
  }
}



export class SubscriptionAdapater {

  static adaptSingle(response: any): Subscription
  {
    const reg = RegistrationAdapter.adaptSingle(response);

    let primary!: Subscriber;
    let dependents = new Array<Subscriber>();

    response?.subscribers.forEach( (item: any) => {

      if( item?.subscriberType?.name === 'PRIMARY')
      {
        primary = SubscriberAdapater.adaptSingle(item);
      }else{
        dependents.push(SubscriberAdapater.adaptSingle(item));
      }
    });

    return {
      reg: reg,
      primary: primary,
      dependents: dependents
    }

  }

  static adaptMultiple(response: any): Array<Subscription>
  {
    let subscriptions = new Array<Subscription>();

    response?.forEach( (item: any) => {
      subscriptions.push( SubscriptionAdapater.adaptSingle(item) );
    });



    return subscriptions;
  }

  static adaptSubTags(serviceInfo: ServiceInfo | undefined) : string
  {

    if( !!serviceInfo && (serviceInfo?.serviceTags?.length > 0) )
    {
      return  new ServiceSortPipe().transform(serviceInfo?.serviceTags).join('/');
    }else{
      return 'NONE';
    }

  }
}
