<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar-admin actionName="Services"></app-toolbar-admin>

<div style="width: 100%; margin-left: 95px">
  <ul class="nav nav-tabs">
    <!--
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':activeTab==='query'}" (click)="setTab('query')">Search Services</a>
    </li>
    -->
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':activeTab==='providers'}" (click)="setTab('providers')">Add/Edit
        Providers</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':activeTab==='upload'}" (click)="setTab('upload')">Upload Service Data</a>
    </li>
  </ul>

  <div class="tab-content">

    <div *ngIf="activeTab==='query'">
      <div class="content" role="main">
        <h3 class="">Find Services By</h3>
        <div style="width: 100%; margin: auto">

          <div class="input-group mt-2">
            <label for="searchZip" class="mt-1 labelBox">Zip Code</label>
            <input class="form-control searchBox" placeholder="Enter Zip Code" id="searchZip"
              [(ngModel)]="searchZipcode">
            <div class="input-group-append ">
              <button class="btn btn-outline-secondary" (click)="onClickServiceByZip()" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>

          <!--
          <div class="input-group mt-2">
            <label for="searchZip" class="mt-1 labelBox">County</label>
            <input class="form-control searchBox " placeholder="County" id="searchCounty">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="onClickServiceByCounty()" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          </div>
          -->

          <div *ngIf="searchComplete">
            <h3 class="mt-5">Search Results</h3>
            <div style="width: 100%; margin: auto">

              <h5 class="mt-3 text-primary">County Info</h5>

              <div class="input-group mt-2">
                <label class="col-sm-4 col-form-label labelBox">County</label>
                <input class="form-control" [ngModel]="searchResult.county.name" disabled>
              </div>

              <div class="input-group mt-2">
                <label class="col-sm-4 col-form-label">State</label>
                <input class="form-control" [ngModel]="searchResult.state.longName" disabled>
              </div>

              <h5 class="mt-5 text-primary">Providers</h5>

              <div *ngFor="let provider of searchResult.providers; let i = index">
                <div class="input-group mt-2">
                  <label class="col-sm-4 col-form-label">Provider {{i+1}}</label>
                  <input class="form-control" [ngModel]="provider.name" disabled>
                </div>
              </div>

              <h5 class="mt-5 text-primary">Services Available</h5>

              <div *ngFor="let service of searchResult.services; let i = index">
                <div class="input-group mt-3">
                  <label class="col-sm-4 col-form-label">Service {{i+1}}</label>
                  <input class="form-control" [ngModel]="service.name " disabled>
                </div>

                <!--<span class="text-secondary" style="margin-left: 200px">Provided By</span>-->

                <div *ngFor="let provider of service.providers; let j = index" class="input-group mt-2"  style="margin-left: 200px" >
                  <label  class="col-sm-3 col-form-label">Provider {{j+1}}</label>
                  <input  style="width: 225px" [ngModel]="provider.name " disabled>
                </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    </div>

    <div *ngIf="activeTab==='providers'">
      <div class="content" role="main">
        <h3 class="">Manage Service Providers</h3>

        <div class="card-body mt-4">
          <p class="card-text"><em>Future Development</em></p>
        </div>

      </div>
    </div>

    <div *ngIf="activeTab==='upload'">
      <div class="content" role="main">
        <h3 class="">Manage Services Uploads</h3>

        <div class="card-body mt-4">
          <p class="card-text"><em>These upload options are still experimental, please use with caution</em></p>
        </div>

        <div class="container mt-3">
          <div class="row">
            <div class="col text-center">
              <div class="card-container">
                <input id='uploadZipCounty' type='file' hidden (change)="fileChangeZipCounty($event)" />
                <button class="card card-small bg-light text-dark btn" (click)="onClickUploadZipCounty()">Upload
                  Zip-County</button>
              </div>
            </div>
          </div>

          <div class="container mt-3">
            <div class="row">
              <div class="col text-center">
                <div class="card-container">
                  <input id='uploadServiceCoverage' type='file' hidden (change)="fileChangeServiceCoverage($event)" />
                  <button class="card card-small bg-light text-dark btn" (click)="onClickUploadServiceCoverage()">Upload
                    Service Coverage</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
