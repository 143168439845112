<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar-admin actionName="Clients"></app-toolbar-admin>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':deleteModalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete Client</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>You are about to delete this client!</p>
        <p>Are you sure?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="deleteClient()">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">No</button>
      </div>
    </div>
  </div>
</div>

<div class="" style="margin:auto; margin-top: 20px; margin-bottom:20px; width: 400px">
    <input type="text" placeholder="Search..." [(ngModel)]="searchText" style="width: 100%;">
</div>

<div class="table-wrap">

  <table class="table table-borderless table-striped Dependent-table">
    <thead>
    <tr>
      <th scope="col"><input type="checkbox" (click)="onClickSelectAll()" [checked]="allSelect"></th>
      <th scope="col" style="width:75px"></th>
      <th scope="col">Actions</th>
      <th scope="col">Client Name</th>
      <th scope="col">Address Line 1</th>
      <th scope="col">Address Line 2</th>
      <th scope="col">City</th>
      <th scope="col">State</th>
      <th scope="col">ZipCode</th>
      <th scope="col">Email</th>
      <th scope="col">Phone</th>
      <th scope="col">Fax</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let item of data | clientFilter : searchText; index as i">
      <td scope="row"><input type="checkbox" (click)="onClickSelectClient(i)" [checked]="item.isSelected"></td>
      <td scope="row"><strong>{{ i + 1 }}</strong></td>
      <td style="padding: 0" >
        <div class="row">
          <div class="col text-center text-dark" style="font-size: 25px; padding-top:5px; padding-left: 5px; padding-right: 5px;">
            <i class="fa fa-address-card" (click)="onClickContact(i)" style="cursor: pointer;"></i>
          </div>
          <div class="col text-center text-dark" style="font-size: 25px; padding-top: 7px; padding-left: 5px; padding-right: 5px;">
            <i class="fa fa-edit" (click)="onClickEditClient(i)" style="cursor: pointer;"></i>
          </div>
          <div class="col text-center text-dark" style="font-size: 25px; padding-top: 5px; padding-left: 0px; padding-right: 5px;">
            <i class="fa fa-trash" (click)="onClickDeleteClient(i)" style="cursor: pointer;"></i>
          </div>
        </div>
      </td>
      <td>{{ item.client.name }}</td>
      <td>{{ item.client?.address?.addressLine1 }}</td>
      <td>{{ item.client?.address?.addressLine2 }}</td>
      <td>{{ item.client?.address?.city }}</td>
      <td>{{ item.client?.address?.state }}</td>
      <td>{{ item.client?.address?.zipCode }}</td>
      <td>{{ item.client?.contact?.email }}</td>
      <td>{{ item.client?.contact?.phone }}</td>
      <td>{{ item.client?.contact?.fax }}</td>
    </tr>
    </tbody>
  </table>
  </div>

  <div class="container mt-5">
    <div class="row">
      <div class="col text-center">
        <div class="card-container">
          <button class="card card-small bg-light text-dark btn" [disabled]="lockControls" (click)="onClickAdd()">
            <i class="fa fa-plus"></i>ADD
          </button>
        </div>
      </div>
      <div class="col text-center">
        <div class="card-container">
          <input id='uploadClients' type='file' hidden (change)="fileChangeClient($event)"/>
          <button class="card card-small bg-light text-dark btn" (click)="onClickUpload()" [disabled]="lockControls"><i class="fa fa-upload"></i>UPLOAD</button>
          </div>
      </div>
      <div class="col text-center">
        <div class="card-container">
          <button class="card card-small bg-light text-dark btn" (click)="onClickDelete()" [disabled]="lockControls"><i class="fa fa-trash"></i>DELETE</button>
          </div>
      </div>
    </div>
  </div>

  <!--
  <div class="container mt-5">
    <div class="row">
      <div class="text-dark" style="font-size: 30px;" ><i class="fa fa-address-card" style="cursor: pointer;"></i></div>
      <div>Click to access Primary Contact Info</div>
    </div>
  </div>
-->

