import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserRole } from '../models/auth.model';
import { AccessService } from '../services/access.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public router: Router,
    private accessService: AccessService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean>
  {
     return Promise.resolve(true);
  }

}

@Injectable()
export class UserAuthGuardService implements CanActivate {

  constructor(
    public router: Router,
    private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean>
  {
    const roles = route.data.roles as Array<UserRole>;

    return this.authService.hasUserRole(roles).then((hasAcess: boolean)=>{
      if( hasAcess )
      {
        return true;
      }else{
        return this.router.navigate(['/admin/login']);
      }
    });

  }

}

