<!--
<div class="input-group mt-2">
  <em>Company Information</em>
</div>
-->

<div *ngIf="employerNames.length == 1">
  <div class="container mt-3 text-center">
    <strong  class="text-dark">{{employerNames[0]}}</strong>
  </div>

  <div class="container mt-2 text-center">
    Please confirm your company name is correct.
  </div>
</div>


