export const environment = {
  production: false
};

export const API_CONFIG = {
  protocol: 'https',
  url: 'ydumogjnx8.execute-api.us-east-1.amazonaws.com',
  path: 'api',
  version: 'v1'
};

export const BACKEND_ACCESS_TOKEN = '';
export const HEADER_AUTHORIZATION = 'Authorization';

export const ACCESS_TOKEN_NAME = "ACCESS_TOKEN";
export const REGISTRATION_TOKEN_NAME = "REGISTRATION_TOKEN";
export const DEV_ACCESS_TOKEN_NAME = "DEV_ACCESS_TOKEN";
export const USER_TOKEN_NAME = "USER_TOKEN";


export const APP_CONFIG = {
  FORM_INPUTS: {
    EMAIL: {
      MIN: 3,
      MAX: 254
    },
    PASSWORD: {
      MIN: 3,
      MAX: 63
    },
    USER_NAMES: {
      MIN: 1,
      MAX: 26
    },
    ZIP: {
      MIN: 5,
      MAX: 32
    },
    LABELS: {
      MIN: 1,
      MAX: 50
    }
  },
  API: {
    TIMEOUT: 30000
  }
};
