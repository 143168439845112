<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar></app-toolbar>
<div class="content" role="main">
  <ng-container class="form" [formGroup]="form">
    <div class="form-group">
      <hr class="bg-primary">
      <h3 class="text-left mt-4 mb-2text-dark">Dependent Info</h3>
      <div class="text-left" style="font-size: 18px;"><strong class="text-primary">Please provide following information for the dependent you are adding</strong></div>
      <hr class="bg-primary mt-4">

      <app-personal-info contactName="Dependent" formGroupName="personalInfo"></app-personal-info>
      <app-Dependent-info contactName="Dependent" formGroupName="dependentInfo"></app-Dependent-info>

      <div class="container mt-4">
        <div class="row">
          <div class="col text-center">
            <div class="card-container">
              <button class="card btn card-small bg-secondary text-white" (click)="onClickCancel()">BACK</button>
            </div>
          </div>
          <div class="col text-center">
            <div class="card-container">
              <button class="card btn card-small bg-secondary text-white" (click)="onClickContinue()" [disabled]="form.invalid">CONTINUE</button>
              </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>
