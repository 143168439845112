<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar-admin actionName="Registrations"></app-toolbar-admin>

<ng-container class="form" [formGroup]="form">
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':editModalDisplay}">
    <div class="modal-dialog" role="document" style="width: 90%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Registration</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="form-group row">
            <label for="clientName" class="col-sm-4 col-form-label">Client Name</label>
            <div class="col-sm-8">
              <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">{{selectedClientName}}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                  <button *ngFor="let client of clients; index as i" ngbDropdownItem (click)="onClickClientName(client.name)">{{client.name}}</button>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" styl="width: 90%">
            <label for="firstName" class="col-sm-4 col-form-label">First Name</label>
            <div class="col-sm-8">
              <input class="form-control" placeholder="First Name" formControlName="firstName">
            </div>
          </div>

          <div class="form-group row">
            <label for="lastName" class="col-sm-4 col-form-label">Last Name</label>
            <div class="col-sm-8">
              <input class="form-control" placeholder="Last Name" formControlName="lastName">
            </div>
          </div>

          <div class="form-group row">
            <label for="phoneNumber" class="col-sm-4 col-form-label">Phone Number</label>
            <div class="col-sm-8">
              <ngx-intl-tel-input [preferredCountries]="preferredCountries" [onlyCountries]="onlyContries"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
              customPlaceholder="Enter Phone Number"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="'15'" [phoneValidation]="true"
              [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.International" formControlName="phoneNumber">
            </ngx-intl-tel-input>
            </div>
          </div>

          <div class="form-group row">
            <label for="email" class="col-sm-4 col-form-label">Email</label>
            <div class="col-sm-8">
              <input class="form-control" placeholder="Email" formControlName="email">
            </div>
          </div>

          <div class="form-group row">
            <label for="startDate" class="col-sm-4 col-form-label">Start Date</label>
            <div class="col-sm-6">
              <input class="form-control" placeholder="Select Start Date" formControlName="startDate" ngbDatepicker [minDate]="{year: 1900, month: 1, day: 1}" #sd="ngbDatepicker" readonly (click)="sd.toggle()">
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline-secondary calendar" (click)="sd.toggle()" type="button" style="height: 40px">
                  <i class="bi bi-calendar-plus"></i>
                </button>
            </div>
          </div>

          <div class="form-group row">
            <label for="endDate" class="col-sm-4 col-form-label">End Date</label>
            <div class="col-sm-6">
              <input class="form-control" placeholder="Select End Date" formControlName="endDate" ngbDatepicker [minDate]="{year: 1900, month: 1, day: 1}" #ed="ngbDatepicker" readonly (click)="ed.toggle()">
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline-secondary calendar" (click)="ed.toggle()" type="button" style="height: 40px">
                  <i class="bi bi-calendar-plus"></i>
                </button>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="saveRegistration()">Save</button>
          <button type="button" class="btn btn-secondary" (click)="dismissModal()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':smsModalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Send SMS Notifications?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>You are about to send ({{notificationCount}}) SMS notificaitons!</p>
        <p>Are you sure?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="sendSMS()">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':failedModalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Process Failures</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Some issues were experienced with ({{failedCount}}) of your items.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">OK</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':uploadErrorDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Error Reading Registrations</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>There was an error reading registrations from the selected file.</p>
        <p>Please check the data format and try again.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">OK</button>
      </div>
    </div>
  </div>
</div>


<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':emailModalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Send Email Notifications?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>You are about to send ({{notificationCount}}) Email notificaitons!</p>
        <p>Are you sure?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="sendEmail()">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':deleteModalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete Registrations?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>You are about to delete ({{notificationCount}}) registrations!</p>
        <p>Are you sure?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="deleteRegistrations()">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':deleteSingleModalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete Registration?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>You are about to delete a registration!</p>
        <p>Are you sure?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="deleteRegistration()">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-full" tabindex="-1" role="dialog" [ngStyle]="{'display':uploadModalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Upload Registrations?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-wrap">
          <table class="table table-borderless table-striped Dependent-table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Client</th>
                <th scope="col">Last Name</th>
                <th scope="col">First Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Start</th>
                <th scope="col">End </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of uploadData; index as i">
                <td scope="row">{{ i + 1 }}</td>
                <td>{{ item.clientName }}</td>
                <td>{{ item.lastName }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.phoneNumber }}</td>
                <td>{{ toDate(item?.startDate) | date : 'short'}}</td>
                <td>{{ toDate(item?.endDate) | date : 'short'}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="doUpload()">Upload</button>
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="" style="margin:auto; margin-top: 20px; margin-bottom:20px; width: 400px">

  <input type="text" placeholder="Search..." [(ngModel)]="searchText" style="width: 100%;">

</div>

<div class="table-wrap">

  <table class="table table-borderless table-striped Dependent-table">
    <thead>
      <tr>
        <th scope="col"><input type="checkbox" (click)="onClickSelectAll()" [checked]="allSelect"></th>
        <th scope="col"></th>
        <th scope="col">Actions</th>
        <th scope="col">Client</th>
        <th scope="col">Status</th>
        <th scope="col">Last Name</th>
        <th scope="col">First Name</th>
        <th scope="col">Email</th>
        <th scope="col">Phone</th>
        <th scope="col">Start</th>
        <th scope="col">End </th>
        <th scope="col">Modified</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data | registrationFilter: searchText; index as i">
        <td scope="row"><input type="checkbox" (click)="onClickBox(i)" [checked]="item.isSelected"></td>
        <td scope="row"><strong>{{ i + 1 }}</strong></td>
        <td style="padding: 0">
          <div class="row">
            <div class="col text-center text-dark"
              style="font-size: 25px; padding-top: 7px; padding-left: 5px; padding-right: 5px;">
              <i class="fa fa-edit" (click)="onClickEditRegistration(i)" style="cursor: pointer;"></i>
            </div>
            <div class="col text-center text-dark"
              style="font-size: 25px; padding-top: 5px; padding-left: 0px; padding-right: 5px;">
              <i class="fa fa-trash" (click)="onClickDeleteRegistration(i)" style="cursor: pointer;"></i>
            </div>
          </div>
        </td>
        <td>{{ item.reg.clientName }}</td>
        <td>{{ item.reg.status }}</td>
        <td>{{ item.reg.lastName }}</td>
        <td>{{ item.reg.firstName }}</td>
        <td>{{ item.reg.email }}</td>
        <td>{{ item.reg.phoneNumber }}</td>
        <td>{{ toDate(item.reg.startDate) | date : 'short'}}</td>
        <td>{{ toDate(item.reg.endDate) | date : 'short'}}</td>
        <td>{{ item.reg.lastModified | date : 'short'}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="container mt-5">
  <div class="row">
    <!--
      <div class="col text-center">
        <div class="card-container">
          <button class="card card-small bg-light text-dark btn" [disabled]="lockControls">
            <i class="fa fa-plus"></i>ADD
          </button>
        </div>
      </div>
      -->
    <div class="col text-center">
      <div class="card-container">
        <input id='uploadReg' type='file' hidden (change)="fileChange($event)"/>
        <button class="card card-small bg-light text-dark btn" (click)="onClickUpload()" [disabled]="lockControls"><i
            class="fa fa-upload"></i>UPLOAD</button>
      </div>
    </div>
    <!--
      <div class="col text-center">
        <div class="card-container">
          <button class="card card-small bg-light text-dark"><i class="fa fa-file"></i>EXPORT</button>
          </div>
      </div>
      -->
    <div class="col text-center">
      <div class="card-container">
        <button class="card card-small bg-light text-dark btn" (click)="onClickDelete()" [disabled]="lockControls"><i
            class="fa fa-trash"></i>DELETE</button>
      </div>
    </div>
    <div class="col text-center">
      <div class="card-container">
        <button class="card card-small bg-light text-dark btn" (click)="onClickEmail()" [disabled]="lockControls"><i
            class="fa fa-envelope"></i>EMAIL</button>
      </div>
    </div>
    <div class="col text-center">
      <div class="card-container">
        <button class="card card-small bg-light text-dark btn" (click)="onClickSMS()" [disabled]="lockControls"><i
            class="fa fa-comment"></i>SMS</button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showProgress" style="width: 90%; margin: auto; margin-top: 50px">
  <div class="container" style="width: 40%; float: left; text-align: right;">
    <span>{{progressType}}</span>
  </div>

  <div class="progress" style="height: 25px; width: 60%; float: left">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
      [ngStyle]="{'width': progress + '%'}" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
  </div>
</div>
