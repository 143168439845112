<div class="container mt-2 text-center">
  How would you like to receive your code?
</div>
<form autocomplete="off" [formGroup]="form">
<div class="input-group mt-4">
  <div class="container">
    <div class="row">
      <div class="col text-center">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="contactType" formControlName="contactType" id="phone" value="SMS"
            (click)="setContactType('SMS')" />
          <label class="form-check-label" for="phone">Text Message</label>
        </div>
        <div class="form-check form-check-inline">
          <input  class="form-check-input" type="radio" name="contactType" formControlName="contactType" id="email" value="EMAIL"
            (click)="setContactType('EMAIL')" />
          <label class="form-check-label" for="email">Email</label>
        </div>
      </div>
    </div>
  </div>
</div>


  <div *ngIf="contact === 'SMS'" class="mt-4">
    <div class="mx-auto" style="width: 300px">
      <div class="row">
        <div class="col text-center">
          <ngx-intl-tel-input [preferredCountries]="preferredCountries" [onlyCountries]="onlyContries"
            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
            customPlaceholder="Mobile Phone Number"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectedCountryISO]="CountryISO.UnitedStates"
            [maxLength]="'15'" [phoneValidation]="true"
            [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.International" formControlName="phoneNumber">
          </ngx-intl-tel-input>
        </div>
      </div>
    </div>
    <div class="container mt-3 text-center">
      <em>Cell phone number must match employer records</em>
    </div>
  </div>

  <div *ngIf="contact === 'EMAIL'" class="mt-4">
    <div class="mx-auto" style="width: 300px">
      <div class="row">
        <div class="col text-center">
          <input class="form-control" placeholder="Enter Email" formControlName="email" />
        </div>
      </div>
    </div>
    <div class="container mt-3 text-center">
      <em>Email must match employer records</em>
    </div>
  </div>

  <div class="container mt-4 text-center">
    Already have your code?
  </div>

  <div class="container mt-2 text-center">
    <a class="text-primary" style="text-decoration: underline" [routerLink]="['/enroll/start']">Enter Code Here</a>
  </div>

</form>
