import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { Registration } from 'src/app/models/registration.model';
import { ServiceSearchResults, ServiceServicesInfo, UploadCountyService, UploadService, ZipCountyMap } from 'src/app/models/service.model';
import { AdminService } from 'src/app/services/admin.service';
import { ExporterService } from 'src/app/services/exporter.service';



@Component({
  selector: 'app-admin-services',
  templateUrl: './admin-services.component.html',
  styleUrls: ['./admin-services.component.scss']
})
export class AdminServicesComponent implements OnInit {

  activeTab = 'providers';
  searchZipcode = '';
  countyName = '';
  searchComplete = false;

  searchResult:  ServiceSearchResults = {
    county: {
      id: '',
      name: ''
    },
    state: {
      id: '',
      shortName: '',
      longName: ''
    },
    providers: [],
    services: []
  };

  constructor(
    private spinner: NgxSpinnerService,
    private adminService: AdminService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit(): void {

  }

  setTab(name: string)
  {
    this.activeTab = name;
  }


  onClickServiceByCounty()
  {
    alert('Future Development');
  }

  onClickServiceByZip()
  {
    this.searchZipcode = this.searchZipcode.trim();

    if( this.searchZipcode.length === 5)
    {
      this.spinner.show().then(() =>
      {
        this.adminService.getServicesByZipCode(this.searchZipcode)
          .then((result: ServiceSearchResults)=>
          {
            this.searchResult = result;
            this.searchComplete = true;
          }).catch((err: any) => {
            console.error(err);
            this.searchComplete = false;
            this.toastr.error('Failed to find services for ' + this.searchZipcode);
          })
          .finally(()=> this.spinner.hide());
      });
    }
  }

  async fileChangeZipCounty(event: any)
  {
    let input = event.target;
    let reader = new FileReader();
    reader.readAsText(input.files[0]);
    reader.onloadstart = () =>
    {
      this.spinner.show();
    };

    reader.onload = async() => {

      const uploadData = new Array<ZipCountyMap>();
      let csvData = reader.result;
      let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

      csvRecordsArray = csvRecordsArray.splice(1);

      csvRecordsArray.forEach(element => {

        if (element.length > 0) {
          const splitData = element.split(',');

          if (!!splitData[0] && !!splitData[1] && !!splitData[2]) {

            const record: ZipCountyMap = {
              zipCode: splitData[0],
              city: splitData[1],
              county: splitData[2],
              state: splitData[3]
            };
            uploadData.push(record);
          }
        }
      });

      const chunkSize = 1000;
      const total = csvRecordsArray.length;
      const paritions = Math.floor(total / chunkSize);
      //const rem = total - (paritions*chunkSize);


      for( let i=0; i<paritions; i++)
      {
        console.log('Uploading chunk ' + (i+1) + ' of ' + (paritions + 1));
        const start = i*chunkSize;
        const end = start + chunkSize;
        const chunk = uploadData.slice(start, end);

        await this.adminService.addZipCountyMap(chunk)
          .then(() => {
            console.log('Chunk ' + (i+1) + ' complete');
          }).catch((err:ErrorCodeObj) => {
            console.error(err);
          });
      }


      console.log('Uploading chunk '+ (paritions + 1) + ' of ' + (paritions + 1));
      const lastChunk = uploadData.slice(paritions*chunkSize+1, total);

      await this.adminService.addZipCountyMap(lastChunk)
      .then(() => {
        console.log('Chunk '+ (paritions + 1) + ' complete');
      }).catch((err:ErrorCodeObj) => {
        console.error(err);
      });


      //this.uploadModalDisplay = 'block';
      input.value = null;
      this.spinner.hide();

    }

    reader.onerror = () =>
    {
      console.log('error uploading');
    }

    reader.onabort = () =>
    {
      console.log('reader aborted');
    }

  }

  onClickUploadZipCounty()
  {
    document.getElementById('uploadZipCounty')?.click();
  }

  async fileChangeServiceCoverage(event: any)
  {

    let input = event.target;
    let reader = new FileReader();
    reader.readAsText(input.files[0]);
    reader.onloadstart = () =>
    {
      this.spinner.show();
    };

    reader.onload = async() => {

      const uploadData = new Array<UploadCountyService>();
      let csvData = reader.result;
      let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

      csvRecordsArray = csvRecordsArray.splice(1);

      csvRecordsArray.forEach(element => {

        if (element.length > 0) {
          const splitData = element.split(',');

          if (!!splitData[0] && !!splitData[1] && !!splitData[2]) {

            const services = new Array<UploadService>();
            splitData.splice(3).forEach((serviceName: string) =>
            {
              services.push({
                serviceName: serviceName.trim()
              } as UploadService)
            })

            const record: UploadCountyService = {
              countyName: splitData[0].trim(),
              stateShort: splitData[1].trim(),
              serviceProviderName: splitData[2].trim(),
              services: services
            };
            uploadData.push(record);
          }
        }
      });

      const chunkSize = 10;
      const total = csvRecordsArray.length;
      const paritions = Math.floor(total / chunkSize);

      for( let i=0; i<paritions; i++)
      {
        console.log('Uploading chunk ' + (i+1) + ' of ' + (paritions + 1));
        const start = i*chunkSize;
        const end = start + chunkSize;
        const chunk = uploadData.slice(start, end);

        await this.adminService.addServiceByCounty(chunk)
          .then(() => {
            console.log('Chunk ' + (i+1) + ' complete');
          }).catch((err:ErrorCodeObj) => {
            console.error(err);
          });
      }


      console.log('Uploading chunk '+ (paritions + 1) + ' of ' + (paritions + 1));
      const lastChunk = uploadData.slice(paritions*chunkSize+1, total);

      await this.adminService.addServiceByCounty(lastChunk)
      .then(() => {
        console.log('Chunk '+ (paritions + 1) + ' complete');
      }).catch((err:ErrorCodeObj) => {
        console.error(err);
      });

      input.value = null;
      this.spinner.hide();

    }

    reader.onerror = () =>
    {
      console.log('error uploading');
    }

    reader.onabort = () =>
    {
      console.log('reader aborted');
    }

  }

  onClickUploadServiceCoverage()
  {
    document.getElementById('uploadServiceCoverage')?.click();
  }


}

