<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar></app-toolbar>
<div class="content" role="main">
  <form class="form">
    <div class="form-group">
      <hr class="bg-primary">
      <h3 class="text-left mt-4 text-dark">Add Dependents</h3>
      <div class="text-left" style="font-size: 18px;"><strong class="text-primary">Please add dependents you wish to enroll in EZaccessMD</strong></div>
      <hr class="bg-primary mt-4">
      <app-manage-Dependents [dependents]="dependents" [loading]="loading"></app-manage-Dependents>
      <div class="card-container">
        <button type="button" class="card card-small btn bg-primary text-white" tabindex="0" (click)="onClickAddDependent()">
          Add Dependent
        </button>
      </div>
      <div class="text-center mt-3"><em>When done adding dependents please submit</em></div>


      <div class="container mt-5">
        <div class="row">
          <div class="col text-center">
            <div class="card-container">
              <button type="button" class="card btn card-small bg-secondary text-white" (click)="onClickBack()">BACK</button>
            </div>
          </div>
          <div class="col text-center">
            <div class="card-container">
              <button type="button" class="card btn card-small bg-secondary text-white" (click)="onClickSubmit()">SUBMIT</button>
              </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>
