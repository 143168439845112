import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-ezenroll-provide-access-code',
  templateUrl: './ezenroll-provide-access-code.component.html',
  styleUrls: ['./ezenroll-provide-access-code.component.scss']
})
export class EzEnrollProvideAccessCodeComponent implements OnInit {

  form : FormGroup;

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({

      requestInfo: this.fb.group({
        code: ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
      })

    });
  }

  ngOnInit(): void {
  }

  onClickContinue()
  {

    const code = this.form.get('requestInfo')?.value?.code;

    if( !!code ) {
      this.spinner.show().then(()=>
      {
        this.authService.requestRegistrationToken(code)
        .then((success: boolean) =>
        {
          if(success)
          {
            this.router.navigate(['enroll/primary-info']);
          }else{
            this.toastr.error('Invalid code');
          }
        }).catch((err: ErrorCodeObj) => {
          this.toastr.error(err.msg);
        }).finally(()=>{
          this.spinner.hide();
        })

      });
    }
  }

}
