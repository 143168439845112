import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-login-code',
  templateUrl: './admin-login-code.component.html',
  styleUrls: ['./admin-login-code.component.scss']
})
export class AdminLoginCodeComponent implements OnInit {

  form = new FormGroup({
		code: new FormControl(undefined, [Validators.required, Validators.pattern('[0-9]{6}')])
	});

  private phoneNumber!: string;

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.phoneNumber = this.route.snapshot.queryParams.phoneNumber;
  }

  async onClickLogin()
  {
    const code = this.form.get('code')?.value;

    if( !!code && !!this.phoneNumber )
    {
      this.spinner.show().then(()=>
      {
        this.authService.requestUserToken(code, this.phoneNumber)
        .then((sucess: boolean) => {
          if( sucess ){
            this.router.navigate(['admin/home']);
          }else{
            console.error('Failed to auth');
            this.toastr.error('Failed to authenicate secuitry code');
          }
        }).catch((err: ErrorCodeObj)=>{
            console.error('Failed to get auth token', err);
            this.toastr.error(err.msg);
        }).finally(()=>{
          this.spinner.hide();
        })

      });
    }
  }

  onClickRequestNewCode()
  {
    this.router.navigate(['/admin/login']);
  }
}
