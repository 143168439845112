import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonalInfoComponent } from './components/personal-info/personal-info.component';
import { AddressInfoComponent } from './components/address-info/address-info.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { SubscriberInfoComponent } from './components/dependent-info/dependent-info.component';
import { ManageDependentsComponent } from './components/manage-dependents/manage-dependents.component';
import { SubmissionComponent } from './components/submission/submission.component';
import { ContactValidationComponent } from './components/contact-validation/contact-validation.component';
import { AccessCodeComponent } from './components/access-code/access-code.component';
import { RequestCodeComponent } from './components/request-code/request-code.component';
import { AppRoutingModule } from './app-routing.module';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { EzEnrollConfirmationComponent } from './pages/ezenroll-steps/ezenroll-confirmation/ezenroll-confirmation.component';
import { EzEnrollProvideAccessCodeComponent } from './pages/ezenroll-steps/ezenroll-provide-access-code/ezenroll-provide-access-code.component';
import { EzEnrollRequestCodeComponent } from './pages/ezenroll-steps/ezenroll-request-code/ezenroll-request-code.component';
import { EzEnrollPrimaryInfoComponent } from './pages/ezenroll-steps/ezenroll-primary-info/ezenroll-primary-info.component';
import { EzEnrollPrimaryContactComponent } from './pages/ezenroll-steps/ezenroll-primary-contact/ezenroll-primary-contact.component';
import { EzEnrollDependentsComponent } from './pages/ezenroll-steps/ezenroll-dependents/ezenroll-dependents.component';
import { EzEnrollSubscriberInfoComponent } from './pages/ezenroll-steps/ezenroll-dependent-info/ezenroll-dependent-info.component';
import { ComingSoonComponent } from './pages/static/coming-soon/coming-soon.component';
import { AuthGuardService, UserAuthGuardService } from './guards/auth-guard.service';
import { AccessDeniedComponent } from './pages/static/access-denied/access-denied.component';
import { AppAccessComponent } from './pages/ezenroll-generic/app-access/app-access.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EzenrollSubscriberContactInfoComponent } from './pages/ezenroll-steps/ezenroll-dependent-contact-info/ezenroll-dependent-contact-info.component';
import { AccessGuardService } from './guards/access-guard.service';
import { RegistrationGuardService } from './guards/registration-guard.service';
import { authInterceptProvider } from './services/auth-interceptor.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AdminLoginComponent } from './pages/admin/admin-login/admin-login.component';
import { ToolbarAdminComponent } from './components/toolbar-admin/toolbar-admin.component';
import { AdminLoginCodeComponent } from './pages/admin/admin-login-code/admin-login-code.component';
import { AdminHomeComponent } from './pages/admin/admin-home/admin-home.component';
import { AdminRegistrationsComponent } from './pages/admin/admin-registrations/admin-registrations.component';
import { AdminSubscriptionsComponent } from './pages/admin/admin-subscriptions/admin-subscriptions.component';
import { RegistrationFilterPipe } from './pipes/registration-filter.pipe';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ExpandedDependentsPipe } from './pipes/subscription-expanded.pipe';
import { AdminAccessDeniedComponent } from './pages/admin/admin-access-denied/admin-access-denied.component';
import { AdminClientsComponent } from './pages/admin/admin-clients/admin-clients.component';
import { AdminServicesComponent } from './pages/admin/admin-services/admin-services.component';
import { DateStringToObjectPipe } from './pipes/date-string-to-object.pipe';
import { PhoneStringToObjectPipe } from './pipes/phone-string-to-object.pipe';
import { DateObjectToStringPipe } from './pipes/date-object-to-string.pipe';
import { SubscriptionFilterPipe } from './pipes/subscription-filter.pipe';
import { ClientFilterPipe } from './pipes/client-filter.pipe';
import { ServiceSortPipe } from './pipes/service-sort.pipe';
import { SubscriberFilterPipe } from './pipes/subscriber-sort.pipe';
import { SubscriberGroupSortPipe } from './pipes/subscriber-group-sort.pipe';

@NgModule({
  declarations: [
    AppComponent,
    PersonalInfoComponent,
    AddressInfoComponent,
    ContactInfoComponent,
    CompanyInfoComponent,
    SubscriberInfoComponent,
    ManageDependentsComponent,
    SubmissionComponent,
    ContactValidationComponent,
    AccessCodeComponent,
    RequestCodeComponent,
    ToolbarComponent,
    EzEnrollConfirmationComponent,
    EzEnrollProvideAccessCodeComponent,
    RequestCodeComponent,
    EzEnrollRequestCodeComponent,
    EzEnrollPrimaryInfoComponent,
    EzEnrollPrimaryContactComponent,
    EzEnrollDependentsComponent,
    EzEnrollSubscriberInfoComponent,
    ComingSoonComponent,
    AccessDeniedComponent,
    AppAccessComponent,
    EzenrollSubscriberContactInfoComponent,
    AdminLoginComponent,
    AdminLoginCodeComponent,
    ToolbarAdminComponent,
    AdminHomeComponent,
    AdminRegistrationsComponent,
    AdminSubscriptionsComponent,
    RegistrationFilterPipe,
    ExpandedDependentsPipe,
    AdminAccessDeniedComponent,
    AdminClientsComponent,
    AdminServicesComponent,
    DateStringToObjectPipe,
    PhoneStringToObjectPipe,
    DateObjectToStringPipe,
    SubscriptionFilterPipe,
    ClientFilterPipe,
    ServiceSortPipe,
    SubscriberFilterPipe,
    SubscriberGroupSortPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    //RouterModule.forRoot(routes),
    AppRoutingModule,
    NgxSpinnerModule,
    NgxIntlTelInputModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: false,
    }),
    GooglePlaceModule
  ],
  providers: [
    AuthGuardService,
    UserAuthGuardService,
    AccessGuardService,
    RegistrationGuardService,
    authInterceptProvider
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
