import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { SubscriberContact} from 'src/app/models/subscription.model';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit, OnChanges {

  @Input() contactName = "Primary Member";
  @Input() formGroupName!: string;
  @Input() primaryContact!: SubscriberContact | undefined;
  @Input() type!: string | undefined;

  form!: FormGroup;

  storedPhone: any;
  storedEmail!: string;
  storedPhoneType!: string;

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  onlyContries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Canada]


  constructor(
    private rootFormGroup: FormGroupDirective
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    //this.form.controls['phoneType'].setValue('MOBILE');
  }

  ngOnChanges()
  {
    if( this.type === 'SPOUSE' )
    {
      this.form.controls['email'].addValidators(Validators.email);
      this.form.controls['email'].addValidators(Validators.required);
    }
  }

  onClickPrimaryPhone(event: any)
  {
    if( event.target.checked )
    {
      this.storedPhoneType = this.form.controls['phoneType'].value;
      this.storedPhone = this.form.controls['phoneNumber'].value;

      const phoneNumber = this.primaryContact?.mobilePhone ? this.primaryContact.mobilePhone : this.primaryContact?.phone;
      const phoneType = this.primaryContact?.mobilePhone ? 'MOBILE': 'OTHER';

      this.form.patchValue(
        {
          phoneType: phoneType,
          phoneNumber: phoneNumber?.substr(2)
        }
      );



    }else{

      this.form.patchValue(
        {
          phoneType: this.storedPhoneType,
          phoneNumber: this.storedPhone
        }
      );
    }

  }

  onClickPrimaryEmail(event: any)
  {
    if( event.target.checked )
    {
      this.storedEmail = this.form.controls['email'].value;
      this.form.controls['email'].patchValue(this.primaryContact?.email);


    }else{
      this.form.controls['email'].patchValue(this.storedEmail);
    }
  }

  checkPrimaryPhone(_event: any)
  {
    const num = this.form.controls['phoneNumber'].value;

    if( num )
    {
      const inum = num.e164Number;
      const matchPrimary = this.form.controls['phoneType'].value === 'MOBILE' ?
        (inum === this.primaryContact?.mobilePhone) : (inum === this.primaryContact?.phone);

      this.form.controls['usePrimaryPhone'].patchValue(matchPrimary);
    }else{
      this.form.controls['usePrimaryPhone'].patchValue(false);
    }

  }

}
