<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar></app-toolbar>
<div class="content" role="main">
  <form class="form">
    <div class="form-group">

      <hr class="bg-primary">
      <app-submission></app-submission>

    </div>
  </form>
</div>
