<form autocomplete="off" [formGroup]="form">
  <div class="input-group mt-3">
    <strong class="text-dark">{{contactName}} Contact Information</strong>
  </div>

  <div *ngIf="contactName === 'Dependent'" class="form-check mt-3">
    <input class="form-check-input" type="checkbox" formControlName="usePrimaryPhone" value="" id="usePrimaryPhone" (change)="onClickPrimaryPhone($event)">
    <label class="form-check-label" for="usePrimaryPhone">
      Use Primary Phone
    </label>
  </div>

  <div class="input-group mt-2">
    <div style="margin: auto;">
    <ngx-intl-tel-input [preferredCountries]="preferredCountries" [onlyCountries]="onlyContries"
            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
            customPlaceholder="Enter Phone Number"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectedCountryISO]="CountryISO.UnitedStates"
            [maxLength]="'15'" [phoneValidation]="true"
            [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.International" formControlName="phoneNumber" (keyup)="checkPrimaryPhone($event)">
      </ngx-intl-tel-input>
    </div>
  </div>

  <div class="input-group mt-3">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="phoneType" id="phoneMobile" value="MOBILE" >
            <label class="form-check-label" for="phoneMobile">Cell Phone</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="phoneType" id="otherPhone" value="OTHER">
            <label class="form-check-label" for="otherPhone">Other</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--
  <div *ngIf="contactName === 'Dependent'" class="form-check mt-3">
    <input class="form-check-input" type="checkbox" value="" id="usePrimaryEmail" (change)="onClickPrimaryEmail($event)">
    <label class="form-check-label" for="usePrimaryEmail">
      Use Primary Email
    </label>
  </div>
  -->
  <div class="input-group mt-3">
    <input class="form-control" placeholder="Email" formControlName="email" >
  </div>

  <div  *ngIf="contactName === 'Dependent' && type !== 'SPOUSE'" class="text-center mt-3"><em>Email cannot be the same as the primary.<br>Email may be left blank.</em></div>
  <div  *ngIf="contactName === 'Dependent' && type === 'SPOUSE'" class="text-center mt-3"><em>Email is required for spouse, but cannot be the same as the primary</em></div>

</form>
