import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { AuthService } from 'src/app/services/auth.service';
import * as HttpStatus from 'http-status-codes';
@Component({
  selector: 'app-ezenroll-request-code',
  templateUrl: './ezenroll-request-code.component.html',
  styleUrls: ['./ezenroll-request-code.component.scss']
})
export class EzEnrollRequestCodeComponent implements OnInit {


  form : FormGroup;
  phoneSelected = true;

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({

      requestInfo: this.fb.group({
        phoneNumber: ['',this.phoneValidator],
        email: ['',this.emailValidator],
        contactType: ['SMS', Validators.required]
      }, )

    },);

    this.form.valueChanges.subscribe((value: any) =>{
     //this.phoneSelected = this.form.get('requestInfo')?.value?.contactType === 'SMS';
    })
  }

  phoneValidator(control: AbstractControl): { [key: string]: boolean } | null {

   return null;
  }

  emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    //TODO
    return null;
  }

  ngOnInit(): void {


  }

  onFormChange()
  {

  }

  async onClickRequest()
  {
    const contactType = this.form.get('requestInfo')?.value?.contactType;

    if( contactType === 'SMS') {
      const phoneNumber = this.form.get('requestInfo')?.value?.phoneNumber;
      if( !!phoneNumber)
      {
        const e164 = phoneNumber.e164Number;
        this.sendCodeSMS(e164);
      }

    }else if( contactType === 'EMAIL')
    {
      const email = this.form.get('requestInfo')?.value?.email;
      if( !!email )
      {
        this.sendCodeEmail(email);
      }
    }
  }

  async sendCodeSMS(phoneNumber: string)
  {
    this.spinner.show().then(()=>
    {
      this.authService.requestRegistrationTokenCodeSMS(phoneNumber).then(()=>{
        this.router.navigate(['enroll/start'])
      }).catch((err: ErrorCodeObj ) =>{
        console.error(err);
        this.toastr.error(err.msg);

      }).finally(()=>{
        this.spinner.hide();
      });
    });
  }

  async sendCodeEmail( email: string)
  {
    this.spinner.show().then(()=>
    {
      this.authService.requestRegistrationTokenCodeEmail(email).then(()=>{
        this.router.navigate(['enroll/start'])
      }).catch((err: ErrorCodeObj ) =>{
        console.error(err);
        this.toastr.error(err.msg);

      }).finally(()=>{
        this.spinner.hide();
      });
    });
  }
}
