import { GenericAPIResp } from './api.model';
import { ERROR_MESSAGES } from '../config/error';

export class ErrorCodeObj {
    constructor(
        readonly statusCode: number,
        readonly msg: string | undefined
    ) { }

    static generate(statusCode: number, messageMap: Map<number, string>): ErrorCodeObj {

      const errMsg = messageMap.get(statusCode);
      const errObj = !!errMsg ? new ErrorCodeObj(statusCode, errMsg) : new ErrorCodeObj(statusCode, 'Unexcepted error has occurred.');

      return errObj;
    }

}

export class ErrorCodeGenerator {
    static getErrorCode(resp: GenericAPIResp): ErrorCodeObj {

        const statusCode = resp.statusCode !== undefined ? resp.statusCode : 0;
        const code = ErrorCodeObj.generate(statusCode, ERROR_MESSAGES.GENERIC);

        return code;
    }
}
