<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar></app-toolbar>
<div class="content" role="main">
  <ng-container class="form" [formGroup]="form">
    <div class="form-group">

      <hr class="bg-primary">
      <h3 class="text-left mt-4 text-dark">Welcome!</h3>
      <div class="text-left" style="font-size: 18px; width: 100%">
        <strong class="text-primary">Before we begin, we need to confirm your eligibility to enroll in EZaccessMD</strong>
      </div>

      <hr class="bg-primary mt-4">
      <app-access-code formGroupName="requestInfo" ></app-access-code>

      <div class="container mt-5">
        <div class="row">
          <div class="col text-center">
            <div class="card-container">
              <button type="button" class="card card-small btn bg-secondary text-white" (click) = "onClickContinue()" [disabled]="form.invalid">CONTINUE</button>
              </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>
