export enum APIPathKey {
  GET_APP_ACCESS_TOKEN,
  REFRESH_APP_ACCESS_TOKEN,
  REFRESH_ACCESS_TOKEN,
  GET_REGISTRATION_TOKEN,
  GET_PRE_REGISTRATION_INFO,
  USER_AUTH_SMS,
  USER_AUTH,
  USER_AUTH_REFRESH,
  ADMIN_GET_REGISTRATION,
  ADMIN_SEND_SMS,
  REGISTRATION_AUTH_SMS,
  REGISTRATION_AUTH_EMAIL,
  REGISTRATION_GET_PRIMARY_INFO,
  REGISTRATION_POST_PRIMARY_INFO,
  REGISTRATION_GET_PRIMARY_ADDRESS,
  REGISTRATION_POST_PRIMARY_ADDRESS,
  REGISTRATION_GET_PRIMARY_CONTACT,
  REGISTRATION_POST_PRIMARY_CONTACT,
  REGISTRATION_GET_DEPENDENTS,
  REGISTRATION_GET_DEPENDENT_INFO,
  REGISTRATION_POST_DEPENDENT,
  REGISTRATION_PUT_DEPENDENT,
  REGISTRATION_POST_DEPENDENT_INFO,
  REGISTRATION_GET_DEPENDENT,
  REGISTRATION_DELETE_DEPENDENT,
  REGISTRATION_POST_DEPENDENT_CONTACT,
  REGISTRATION_POST_DEPENDENT_ADDRESS,
  REGISTRATION_ADD_PRIMARY,
  REGISTRATION_GET_PRIMARY,
  REGISTRATION_SUBMIT,
  ADMIN_SEND_EMAIL,
  ADMIN_ADD_REGISTRATION,
  ADMIN_GET_CLIENT,
  ADMIN_DELETE_REGISTRATION,
  ADMIN_GET_SUBSCRIPTIONS,
  ADMIN_GET_SERVICE_CODE_ZIP,
  ADMIN_UPDATE_SUBSCRIBER_DATES,
  ADMIN_UPDATE_REGISTRATION,
  ADMIN_ADD_ZIP_COUNTY,
  ADMIN_ADD_SERVICE_BY_COUNTY,
  ADMIN_GET_SERVICE_BY_COUNTY,
  ADMIN_UPDATE_SUBSCRIBERS,
  ADMIN_UPDATE_REGISTATION_STATUS,
  ADMIN_UPLOAD_MEDIORBIS,
  ADMIN_SET_SUBSCRIBER_STATUS,
  ADMIN_GET_SUBSCRIBER_STATUS_NAMES,
  ADMIN_ADD_CLIENTS,
  ADMIN_DELETE_CLIENT
}


export enum APIDynamicKey {
  VALIDATION_CODE = 'VALIDATION_CODE',
  SUBSCRIBER_ID = 'SUBSCRIBER_ID',
  REGISTRATION_ID = "REGISTRATION_ID",
  ZIPCODE = "ZIPCODE",
  CLIENT_ID = "CLIENT_ID"
}


