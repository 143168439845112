import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientInfo } from 'src/app/models/client.model';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { RegData, Registration } from 'src/app/models/registration.model';
import { RegistrationFilterPipe } from 'src/app/pipes/registration-filter.pipe';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-admin-registrations',
  templateUrl: './admin-registrations.component.html',
  styleUrls: ['./admin-registrations.component.scss']
})
export class AdminRegistrationsComponent implements OnInit {

  data = new Array<RegData>();
  selectedIndexes = new Array<boolean>();
  uploadData = new Array<Registration>();
  clients!:Array<ClientInfo>;

  currentEditId!: string;
  selectedClientName!: string;
  form: FormGroup;

  smsModalDisplay = 'none';
  emailModalDisplay = 'none';
  uploadModalDisplay = 'none';
  uploadErrorDisplay = 'none';
  deleteModalDisplay = 'none';
  editModalDisplay = 'none';
  deleteSingleModalDisplay = 'none';
  failedModalDisplay = 'none';

  failedCount = 0;
  notificationCount = 0;
  progress = 0;
  progressType = '';
  showProgress = false;
  lockControls = false;
  allSelect = false;
  searchText = '';

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  onlyContries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Canada]

  constructor(
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private parserFormatter: NgbDateParserFormatter,
    private toastr: ToastrService
  ) {
    this.form = this.fb.group(
      {
        clientName: [{ value: '', disabled: false }],
        firstName: [{ value: '', disabled: false }],
        lastName: [{ value: '', disabled: false }],
        email: [{ value: '', disabled: false }],
        phoneNumber: [{ value: '', disabled: false }],
        startDate: [{ value: '', disabled: false }],
        endDate: [{ value: '', disabled: false }]
      }
    );

  }

  ngOnInit(): void {
    this.updateRegistrations();
  }

  updateRegistrations() {
    this.spinner.show().then(() => {

      Promise.all([
        this.adminService.getRegistrations(),
        this.adminService.getClients()
      ]).then((values: any[]) =>
      {
        this.data = new Array<RegData>();
        this.clients = new Array<ClientInfo>();

        (values[0] as Registration[])?.forEach((reg: Registration) => this.data.push({ reg: reg, isSelected: false }));
        (values[1] as ClientInfo[])?.forEach((client: ClientInfo) => this.clients.push(client));

      }).catch((err: ErrorCodeObj) => {
        this.toastr.error(err.msg);
        console.error(err);
      }).finally(() => {
        this.spinner.hide();
      });
    });
  }

  onClickBox(index: number) {
    this.data[index].isSelected = !this.data[index].isSelected;
    if (this.data[index].isSelected === false) {
      this.allSelect = false;
    }
  }

  onClickSMS() {
    this.notificationCount = this.data.filter((item: RegData) => item.isSelected).length;
    if (this.notificationCount > 0) {
      this.smsModalDisplay = 'block';
    }

  }

  onClickEmail() {
    this.notificationCount = this.data.filter((item: RegData) => item.isSelected).length;
    if (this.notificationCount > 0) {
      this.emailModalDisplay = 'block';
    }
  }

  onClickDelete() {
    this.notificationCount = this.data.filter((item: RegData) => item.isSelected).length;
    if (this.notificationCount > 0) {
      this.deleteModalDisplay = 'block';
    }
  }

  async sendSMS() {
    this.lockControls = true;
    this.progress = 0;
    this.progressType = 'Sending Enrollment SMS';
    this.showProgress = true;
    this.smsModalDisplay = 'none';

    let count = 0;
    this.progress = Math.floor((count / this.notificationCount) * 100);
    this.failedCount = 0;
    this.data.forEach((item: RegData) => {
      if (item.isSelected) {
        if (!!item.reg.id) {
          this.adminService.sendSMS(item.reg.id).then(() => {

          }).catch((err: any) => {
            this.failedCount++;
            console.log('Failed to send sms', err);
            // TODO: add to failures
          }).finally(() => {
            count = count + 1;
            this.progress = Math.floor((count / this.notificationCount) * 100);
            if (count === this.notificationCount) {
              this.updateRegistrations();
              this.showProgress = false;
              this.lockControls = false;
              if( this.failedCount > 0 )
              {
                this.failedModalDisplay = 'block';
              }
            }
          });
        }
      }
    })
  }

  dismissModal() {
    this.smsModalDisplay = 'none';
    this.emailModalDisplay = 'none';
    this.uploadModalDisplay = 'none';
    this.deleteModalDisplay = 'none';
    this.editModalDisplay = 'none';
    this.deleteSingleModalDisplay = 'none';
    this.failedModalDisplay = 'none';
    this.uploadErrorDisplay = 'none';
  }

  async sendEmail() {
    this.lockControls = true;
    this.progress = 0;
    this.progressType = 'Sending Enrollment Emails';
    this.showProgress = true;
    this.emailModalDisplay = 'none';

    let count = 0;
    this.progress = Math.floor((count / this.notificationCount) * 100);

    this.failedCount = 0;

    this.data.forEach((item: RegData) => {
      if (item.isSelected) {
        if (!!item.reg.id) {
          this.adminService.sendEmail(item.reg.id).then(() => {
          }).catch((err: any) => {
            console.log('Failed to send sms', err);
            this.failedCount++;
          }).finally(() => {
            count = count + 1;
            this.progress = Math.floor((count / this.notificationCount) * 100);
            if (count === this.notificationCount) {
              this.showProgress = false;
              this.lockControls = false;
              this.updateRegistrations();
              if( this.failedCount > 0 ){
                this.failedModalDisplay = 'block';
              }
            }
          });
        }
      }
    });
  }

  async deleteRegistrations() {
    this.lockControls = true;
    this.progress = 0;
    this.progressType = 'Deleting Registrations';
    this.showProgress = true;
    this.deleteModalDisplay = 'none';

    let count = 0;
    this.progress = Math.floor((count / this.notificationCount) * 100);

    this.data.forEach((item: RegData) => {
      if (item.isSelected) {
        if (!!item.reg.id) {
          this.adminService.deleteRegistration(item.reg.id).then(() => {
          }).catch((err: any) => {
            console.log('Failed to delete registration', err);
          }).finally(() => {
            count = count + 1;
            this.progress = Math.floor((count / this.notificationCount) * 100);
            if (count === this.notificationCount) {
              this.allSelect = false;
              this.updateRegistrations();
              this.showProgress = false;
              this.lockControls = false;
            }
          });
        }
      }
    });
  }

  onClickSelectAll() {
    this.allSelect = !this.allSelect;

    new RegistrationFilterPipe().transform(this.data, this.searchText).forEach((item: RegData) => item.isSelected = this.allSelect);
  }

  onClickUpload() {
    document.getElementById('uploadReg')?.click();
  }

  fileChange(event: any) {
    let input = event.target;
    let reader = new FileReader();
    reader.readAsText(input.files[0]);

    reader.onload = () => {
      this.uploadData = new Array<Registration>();
      let csvData = reader.result;
      const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

      csvRecordsArray.slice(1).forEach(element => {

        if (element.length > 0) {
          const splitData = element.split(',');

          if (!!splitData[0] && !!splitData[1] && !!splitData[2]) {

            const record: Registration = {
              clientName: splitData[0],
              email: splitData[1],
              phoneNumber: splitData[2],
              firstName: !!splitData[3] ? splitData[3] : undefined,
              lastName: !!splitData[4] ? splitData[4] : undefined,
              startDate: !!splitData[5] ? new Date(splitData[5]) : undefined,
              endDate: !!splitData[6] ? new Date(splitData[6]) : undefined
            };
            this.uploadData.push(record);
          }
        }

        if( this.uploadData.length > 0 )
        {
          this.uploadModalDisplay = 'block';
          input.value = null;
        }else{
          this.uploadErrorDisplay = 'block';
        }
      });

      reader.onerror = () =>
      {
        console.log('error uploading');
      }

      reader.onabort = () =>
      {
        console.log('reader aborted');
      }

    };

  }

  doUpload() {

    this.spinner.show().then(() =>
    {
      let itemsLeft = this.uploadData.length;

      this.failedCount = 0;

      this.uploadData.forEach((item: Registration) => {
        this.adminService.addRegistration(item)
          .catch((err: ErrorCodeObj) => {
            this.failedCount++;
            console.error(err);
          }).finally(() => {
            itemsLeft--;
            if (itemsLeft === 0) {
              this.dismissModal();
              this.updateRegistrations();
              if( this.failedCount > 0 )
              {
                this.failedModalDisplay = 'block';
              }
            }
          });
      });
    });

  }

  onClickEditRegistration(index: number) {
    const reg = this.data[index].reg;

    if (!!reg && !!reg.id) {
      this.currentEditId = reg.id;

      let startDate = {};
      if( !!reg.startDate )
      {
        let [year, month, day] = reg?.startDate?.split('-');
        startDate = { year: parseInt(year), month: parseInt(month), day: parseInt(day) };
      }

      let endDate = {};
      if( !!reg.endDate )
      {
        let [year, month, day] = reg?.endDate?.split('-');
        endDate = { year: parseInt(year), month: parseInt(month), day: parseInt(day) };
      }

      this.selectedClientName = reg.clientName;

      this.form.patchValue(
        {
          firstName: reg.firstName,
          lastName: reg.lastName,
          email: reg.email,
          phoneNumber: reg.phoneNumber.substr(2),
          startDate: startDate,
          endDate: endDate

        }
      )

      this.editModalDisplay = 'block';
    }

  }

  onClickDeleteRegistration(index: number)
  {
    const reg = this.data[index].reg;

    if (!!reg && !!reg.id) {
      this.currentEditId = reg.id;
      this.deleteSingleModalDisplay = 'block';
    }

  }

  deleteRegistration()
  {
    this.deleteSingleModalDisplay = 'none';

    if( !!this.currentEditId ){
      this.adminService.deleteRegistration(this.currentEditId)
      .then(() => {
        this.allSelect = false;

      }).catch((err: any) => {
        console.log('Failed to delete registration', err);
      }).finally(() => {
          this.updateRegistrations();
          this.showProgress = false;
          this.lockControls = false;
      });
    }
  }

  saveRegistration() {

    this.spinner.show().then(() => {

      const oStart = this.form.get('startDate')?.value;
      const oEnd = this.form.get('endDate')?.value;

      let startDate = Object.keys(oStart).length === 0  ? undefined : new Date(this.parserFormatter.format(oStart));
      let endDate = Object.keys(oEnd).length === 0 ? undefined : new Date(this.parserFormatter.format(oEnd));


      if( !!startDate ){
        startDate.setTime( startDate.getTime() + (startDate.getTimezoneOffset() * 60000));
      }

      if( !!endDate ){
        endDate.setTime( endDate.getTime() + (endDate.getTimezoneOffset() * 60000));
      }

      const reg =
        {
          id: this.currentEditId,
          clientName: this.selectedClientName,
          firstName: this.form.get('firstName')?.value,
          lastName: this.form.get('lastName')?.value,
          phoneNumber: this.form.get('phoneNumber')?.value.e164Number,
          email: this.form.get('email')?.value,
          startDate: !!startDate ? startDate.toISOString() : undefined,
          endDate: !!endDate ? endDate.toISOString() : undefined
        } as Registration;

        this.failedCount = 0;
      this.adminService.updateRegistration(reg)
        .catch((err: ErrorCodeObj) => {
          console.error(err);
          this.failedCount++;
        }).finally(() =>
        {
          this.spinner.hide();
          this.editModalDisplay = 'none';
          this.updateRegistrations();
          if( this.failedCount > 0 ){
            this.failedModalDisplay = 'block';
          };
        });
    });
  }


  toDate(date: any | undefined) : Date | undefined
  {
    return !!date ? new Date(date) : undefined;
  }

  onClickClientName(name: string)
  {
    this.selectedClientName = name;
  }
}




