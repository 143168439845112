
<div *ngIf="dependents.length > 0"class="table-wrap">

<table class="table table-borderless table-striped Dependent-table">
  <tbody>
  <tr *ngFor="let dependent of dependents; index as i">
    <td>{{ i + 1 }}</td>
    <td>
      {{ dependent.info.lastName }}
    </td>
    <td>
      {{ dependent.info.firstName }}
    </td>
    <td>{{ dependent.info.type }}</td>
    <td (click)="onClickEdit(dependent.info.id)">
      <i class="fa fa-edit"></i>
    </td>
    <td (click)="onClickDelete(dependent.info.id)">
      <i class="fa fa-trash"></i>
      </td>
  </tr>
  </tbody>
</table>
</div>

<div  *ngIf="dependents.length === 0 && !loading"class="text-center mt-5 mb-5" style="font-size: 18px;"><strong class="text-dark">You currently have no dependents enrolled</strong></div>
