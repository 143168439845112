import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccessService } from 'src/app/services/access.service';

@Component({
  selector: 'app-app-access',
  templateUrl: './app-access.component.html',
  styleUrls: ['./app-access.component.scss']
})
export class AppAccessComponent implements OnInit {


  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private accessService: AccessService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
    ) {
    this.form = this.fb.group({
      code: ['', [
        Validators.required
      ]]
    });

  }
  routeParms!: any;

  ngOnInit(): void {
    this.routeParms = this.route.snapshot.queryParams;
  }

  async onClickSubmit()
  {
    const code = this.form.get('code')?.value;

    this.spinner.show().then(()=>{
      setTimeout(()=> {

        this.accessService.authDevAccess(code).then((isValid: boolean) =>{

          if( isValid ){
              this.router.navigate([''], {queryParams: this.routeParms});
          }else{
            this.toastr.error('Invalid app access code', 'Access Denied');
          }
        }).catch((err: any)=>
        {
          console.error('Unexpected error validating app access code', err);
          this.toastr.error('Unexpected error has occurred', 'Error');
        }).finally(()=> this.spinner.hide());
      }, 2000);
    });
  }

}
