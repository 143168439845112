import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorCodeObj } from '../models/error.model';
import { Subscriber, SubscriberContact, Subscription, SubscriptionAdapater } from '../models/subscription.model';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';

const MEDI_ORBIS_UPLOAD_HEADERS = "Sr,Member,Relation,FirstName,MiddleName(o),LastName,DOB,Gender,PrimaryMemberFirstName,PrimaryMemberLastName,PrimaryMemberDOB,SSN-RefNo(o),PrimaryMemberSSN-RefNo(o),Address,City,State,Zip,Country,PrimaryPhone,PhoneType,PhoneAlt(o),Email(o),Status,ActStartDate(o),ActEndDate(o),Tag1(o),Tag2(o),Tag3(o)";

@Injectable({
  providedIn: 'root'
})
export class ExporterService {

  constructor() { }

  exportSubscriptionMediOrbis(subs: Array<Subscription>) {
    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {
      if (subs.length > 0) {
        let index = 1;
        const records = new Array<string>();

        records.push(MEDI_ORBIS_UPLOAD_HEADERS+'\r\n');

        subs.forEach((sub: Subscription) => {
          const primary = sub.primary;
          const clientName = sub.reg?.clientName;

          if (primary !== null) {
            records.push(convertPrimaryToMediOrbis(primary, clientName, index++));

            sub.dependents.forEach((dep: Subscriber) =>
              {
                records.push(convertDependentToMediOrbis(dep, primary, clientName, index++));
              }
            );

          }else{
            reject(new ErrorCodeObj(HttpStatusCode.BadRequest, 'Missing primary'));
          }
        });

        let file = new File(records, 'ezaccessmd-subscription-export-'+new Date(Date.now()).toISOString()+'.csv', {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(file);
        resolve();

      } else {
        reject(new ErrorCodeObj(HttpStatusCode.BadRequest, 'Empty subscriptions'));
      }
    }
    );
  }
}

function convertPrimaryToMediOrbis(primary: Subscriber, clientName: string, index: number): string {
  const recordItems = new Array<String>();

  // Sr
  recordItems.push(''+index);

  // Member
  recordItems.push('Primary');

  // Relation
  recordItems.push('Self');

  // FirstName
  recordItems.push(primary.info.firstName);

  // MiddleName
  recordItems.push('');

  // LastName
  recordItems.push(primary.info.lastName);

  // DOB
  recordItems.push(dobToString(primary.info.dateOfBirth));

  // Gender
  const g = primary.info.genderType;
  recordItems.push(g[0] + g.substring(1).toLocaleLowerCase());


  // PrimaryMemberFirstName
  recordItems.push(primary.info.firstName);

  // PrimaryMemberLastName
  recordItems.push(primary.info.lastName);

  // PrimaryMemberDOB
  recordItems.push(dobToString(primary.info.dateOfBirth));

  // SSN-RefNo
  recordItems.push('');

  // PrimaryMemberSSN-RefNo
  recordItems.push('');

  // Address
  recordItems.push(primary.address.addressLine1);

  // City
  recordItems.push(primary.address.city);

  // State
  recordItems.push(primary.address.state);

  // Zip
  recordItems.push(primary.address.zipCode);

  // Country
  recordItems.push('US');

  // PrimaryPhone
  recordItems.push(primary?.contact?.mobilePhone?.slice(2));

  // Phone Type
  recordItems.push(getPhoneType(primary?.contact));

  // PhoneAlt
  recordItems.push(primary?.contact?.phone?.slice(2));

  // Email
  recordItems.push(primary.contact.email);

  // Status
  recordItems.push('Active');

  // ActStartDate
  recordItems.push(formatDate(primary?.service?.startDate));

  // ActEndDate
  recordItems.push(formatDate(primary?.service?.endDate));

  // Tag1
  recordItems.push(clientName);

  // Tag2
  recordItems.push('');

  // Tag3
  recordItems.push(SubscriptionAdapater.adaptSubTags(primary.service));


  return recordItems.join(',') + '\r\n';
}

function convertDependentToMediOrbis(dependent: Subscriber, primary: Subscriber, clientName: string, index: number): string {
  const recordItems = new Array<String>();

  // Sr
  recordItems.push(''+ index);

  // Member
  recordItems.push('Dependent');

  // Relation
  recordItems.push(dependent.info.type.toUpperCase() === 'SPOUSE' ? 'Spouse' : 'Child');

  // FirstName
  recordItems.push(dependent.info.firstName);

  // MiddleName
  recordItems.push('');

  // LastName
  recordItems.push(dependent.info.lastName);

  // DOB
  recordItems.push(dobToString(dependent.info.dateOfBirth));

  // Gender
  const g = dependent.info.genderType;
  recordItems.push(g[0] + g.substring(1).toLocaleLowerCase());

  // PrimaryMemberFirstName
  recordItems.push(primary.info.firstName);

  // PrimaryMemberLastName
  recordItems.push(primary.info.lastName);

  // PrimaryMemberDOB
  recordItems.push(dobToString(primary.info.dateOfBirth));

  // SSN-RefNo
  recordItems.push('');

  // PrimaryMemberSSN-RefNo
  recordItems.push('');

  // Address
  recordItems.push(dependent.address.addressLine1);

  // City
  recordItems.push(dependent.address.city);

  // State
  recordItems.push(dependent.address.state);

  // Zip
  recordItems.push(dependent.address.zipCode);

  // Country
  recordItems.push('US');

  // PrimaryPhone
  recordItems.push(dependent?.contact?.mobilePhone?.slice(2));

  // Phone Type
  recordItems.push(getPhoneType(dependent?.contact));

  // PhoneAlt
  recordItems.push(dependent?.contact?.phone?.slice(2));

  // Email
  recordItems.push(dependent.contact.email);

  // Status
  recordItems.push('Active');

  // ActStartDate
  recordItems.push(formatDate(dependent?.service?.startDate));

  // ActEndDate
  recordItems.push(formatDate(dependent?.service?.endDate));

  // Tag1
  recordItems.push(clientName);

  // Tag2
  recordItems.push('');

  // Tag3
  recordItems.push(SubscriptionAdapater.adaptSubTags(dependent.service));

  return recordItems.join(',') + '\r\n';
}

function dobToString(dateOfBirth: string): string {
  const parts = dateOfBirth.split('-');
  return parts[1] + '/' + parts[2] + '/' + parts[0];
}

function formatDate(d: string): string
{
  if( !!d )
  {
    const date = new Date(d);
    return  (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear();
  }else{
    return '';
  }
}

function getPhoneType(contact: SubscriberContact | undefined)
{
  let type = '';
  if( !!contact?.mobilePhone )
  {
    type ='CP';
  }else if( !!contact?.phone )
  {
    type = 'HP';
  }

  return type;
}
