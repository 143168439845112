import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateObjectToString'
})
export class DateObjectToStringPipe implements PipeTransform {

  transform(dateObj: any): string {

    return dateObj.year + '-' + dateObj.month + '-' + dateObj.day;
  }

}
