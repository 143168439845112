import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { DependentListInfo, Subscriber } from 'src/app/models/subscription.model';
import { SubscriptionService } from 'src/app/services/subscription.service';
import * as HttpStatus from 'http-status-codes';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-ezenroll-Dependents',
  templateUrl: './ezenroll-dependents.component.html',
  styleUrls: ['./ezenroll-dependents.component.scss']
})
export class EzEnrollDependentsComponent implements OnInit {

  dependents = new Array<Subscriber>();
  loading = true;

  constructor(
    private router: Router,
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
   this.spinner.show().then(() =>
    {
      this.subscriptionService.getDependants(false).then((deps: Subscriber[])=>
      {
        this.dependents = new Array<Subscriber>().concat(deps);
      }).catch((err: ErrorCodeObj) =>
      {
        if( err.statusCode === HttpStatus.StatusCodes.FORBIDDEN)
        {
          this.router.navigate(['enroll']);
        }
        console.error(err.msg);
      }).finally(()=>
      {
        this.spinner.hide();
        this.loading = false;
      });
    }
   );
  }

  onClickSubmit()
  {
    this.spinner.show().then(() =>
    {
      this.subscriptionService.submit()
      .then(() => this.router.navigateByUrl('/enroll/confirmation'))
      .catch((err: ErrorCodeObj) =>
      {
        if( err.statusCode === HttpStatus.StatusCodes.FORBIDDEN)
        {
          this.router.navigate(['enroll']);
        }else{
          this.toastr.show(err.msg);
        }
        console.error(err);
      }).finally(()=>this.spinner.hide());
    });

  }

  onClickBack()
  {
    this.router.navigateByUrl('/enroll/primary-contact');
  }

  onClickAddDependent()
  {
    this.router.navigateByUrl('/enroll/dependent-info');
  }

}
