import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AccessService } from '../services/access.service';

@Injectable()
export class AccessGuardService implements CanActivate {

  constructor(
    public router: Router,
    private accessService: AccessService
    ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean>
  {
    return this.accessService.hasAppDevAccess().then((isValid: boolean) =>{
      if( isValid ){
        return true;
      }else{
        this.router.navigate(['/enroll/access'], {queryParams: route.queryParams});
        return false;
      }
    }).catch(()=>{
      this.router.navigate(['/enroll/access'], {queryParams: route.queryParams});
      return false;
    });

  }

}
