import { Injectable } from '@angular/core';
import { REGISTRATION_TOKEN_NAME, USER_TOKEN_NAME } from 'src/environments/environment';
import { APIPathKey } from '../config/consts';
import { API_ENDPOINTS } from '../config/routes';
import { GenericAPIResp } from '../models/api.model';
import { ErrorCodeObj } from '../models/error.model';
import { ApiService } from './api.service';
import * as HttpStatus from 'http-status-codes';
import { UserRole } from '../models/auth.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {



  constructor(
    private api: ApiService
  ) {

  }

  async refresh() : Promise<boolean> {

    const ep = API_ENDPOINTS.get(APIPathKey.REFRESH_ACCESS_TOKEN);

    return new Promise<boolean>((resolve: (isValid: boolean) => void, reject: (code: ErrorCodeObj) => void) => {

      const token = localStorage.getItem(REGISTRATION_TOKEN_NAME);

      if( !!token )
      {
        const body = {
          "token":  token
        };

        this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {

          // Returns a request id, null if bad
          const newToken = resp.body?.token;

          if( !!newToken )
          {
            localStorage.setItem(REGISTRATION_TOKEN_NAME, newToken);
            resolve(true);
          }else{
            localStorage.removeItem(REGISTRATION_TOKEN_NAME);
            resolve(false);
          }

        }).catch((badResp: GenericAPIResp) => {
          if (ep !== undefined) {
            reject(ep.errorCodeGenerator(badResp));
          } else {
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });

      }else{
        resolve(false);
      }
    });
  }


  async requestUserTokenCode(phoneNumber: string): Promise<boolean> {

    const ep = API_ENDPOINTS.get(APIPathKey.USER_AUTH_SMS);

    return new Promise<boolean>((resolve: (isValid: boolean) => void, reject: (code: ErrorCodeObj) => void) => {

        const body = {
          "type": "USER",
          "method": "SMS",
          "value": phoneNumber
        }

        this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {

          // Returns a request id, null if bad
          const requestId = resp.body?.requestId;
          if( !!requestId )
          {
            resolve(true);
          }else{
            resolve(false);
          }

        }).catch((badResp: GenericAPIResp) => {
          if (ep !== undefined) {
            reject(ep.errorCodeGenerator(badResp));
          } else {
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });
    });
  }

  async requestUserToken(code: string, phoneNumber: string): Promise<boolean> {

    const ep = API_ENDPOINTS.get(APIPathKey.USER_AUTH);


    return new Promise<boolean>((resolve: (success: boolean) => void, reject: (code: ErrorCodeObj) => void) => {

        const body = {
          "code":  code,
          "phoneNumber": phoneNumber
        }

        this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {

          const token = resp.body?.token;
          if( !!token )
          {
            localStorage.setItem(USER_TOKEN_NAME, token);
            resolve(true);
          }else{
            localStorage.removeItem(USER_TOKEN_NAME);
            resolve(false);
          }

        }).catch((badResp: GenericAPIResp) => {
          localStorage.removeItem(USER_TOKEN_NAME);
          if (ep !== undefined) {
            reject(ep.errorCodeGenerator(badResp));
          } else {
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });
    });
  }

  async requestRegistrationToken(code: string): Promise<boolean> {
    let ep = API_ENDPOINTS.get(APIPathKey.GET_REGISTRATION_TOKEN)
    const body = {
      code: code
    }

    return new Promise<boolean>((resolve: (success: boolean) => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {

        const token = resp.body?.token;
        if( !!token )
        {
          localStorage.setItem(REGISTRATION_TOKEN_NAME, token);
          resolve(true);
        }else{
          resolve(false);
        }

      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          if( badResp.statusCode == HttpStatus.StatusCodes.NOT_FOUND ){
            resolve(false);
          }else{
            reject(ep.errorCodeGenerator(badResp));
          }
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      })
    });
  }



  async hasUserRole(roles: Array<UserRole>)
  {
    const userToken = localStorage.getItem(USER_TOKEN_NAME);
    const ep = API_ENDPOINTS.get(APIPathKey.USER_AUTH_REFRESH);

    const body = {
      "token":  userToken
    }

    return new Promise<boolean>((resolve: (success: boolean) => void, _reject: (err: any) => void) => {
      if( !!userToken )
      {
        this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
            const newToken = resp.body?.token;
            if( !!newToken ){
              const helper = new JwtHelperService();
              const decodedToken = helper.decodeToken(newToken);

              if( !!decodedToken )
              {
                const tokenRoles = decodedToken.roles as Array<string>;
                const correctRoles = tokenRoles.some((role: string) => roles.includes(role as UserRole));
                resolve( correctRoles );

              }else{
                resolve(false);
              }
            }else{
              resolve(false);
            }
        }).catch(() => resolve(false));

      }else{
        resolve(false);
      }

    });

  }

  async requestRegistrationTokenCodeSMS(phoneNumber: string): Promise<boolean> {

    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_AUTH_SMS);

    return new Promise<boolean>((resolve: (isValid: boolean) => void, reject: (code: ErrorCodeObj) => void) => {

        const body = {
          "type": "REGISTRATION",
          "method": "SMS",
          "value": phoneNumber
        }
        this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
          const requestId = resp.body?.requestId;
          if( !!requestId )
          {
            resolve(true);
          }else{
            resolve(false);
          }

        }).catch((badResp: GenericAPIResp) => {
          if (ep !== undefined) {
            reject(ep.errorCodeGenerator(badResp));
          } else {
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });
    });
  }

  async requestRegistrationTokenCodeEmail(email: string): Promise<boolean> {

    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_AUTH_EMAIL);

    return new Promise<boolean>((resolve: (isValid: boolean) => void, reject: (code: ErrorCodeObj) => void) => {

        const body = {
          "type": "REGISTRATION",
          "method": "EMAIL",
          "value": email
        }

        this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
            resolve(true);
        }).catch((badResp: GenericAPIResp) => {
          if (ep !== undefined) {
            reject(ep.errorCodeGenerator(badResp));
          } else {
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });
    });
  }




}
