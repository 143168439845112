<div class="container mt-2 text-center">
  Please select how you want to validate your eligibility.
</div>

<div class="input-group mt-2">
  <div class="container mt-2">
    <div class="row">
      <div class="col text-center">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="contactType" id="phone" value="1" (click)="setContactType('phone')" checked>
          <label class="form-check-label" for="phone">Phone Number</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="contactType" id="email" value="2" (click)="setContactType('email')">
          <label class="form-check-label" for="email">Email</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="contactType === 'phone'">
  <div class="input-group mt-3">
    <strong class="text-dark">Enter Phone Number</strong>
  </div>
  <div class="input-group mt-1">
    <input class="form-control" placeholder="Phone Number" name="phone">
  </div>
</div>

<div *ngIf="contactType === 'email'">
  <div class="input-group mt-3">
    <strong class="text-dark">Enter Email</strong>
  </div>
  <div class="input-group mt-1">
    <input class="form-control" placeholder="Email" name="email">
  </div>
</div>
