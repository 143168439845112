import { Pipe, PipeTransform } from '@angular/core';
import { ClientData } from '../pages/admin/admin-clients/admin-clients.component';

@Pipe({
  name: 'clientFilter'
})
export class ClientFilterPipe implements PipeTransform {

  transform(items: ClientData[], searchText: string): ClientData[] {

    const txt = searchText.toLocaleLowerCase();
    return items?.filter((client: ClientData) =>
    {
      return JSON.stringify(client.client).toLocaleLowerCase().includes(txt);
    });
  }

}
