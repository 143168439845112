<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar-admin actionName="Home"></app-toolbar-admin>
<div style="width: 100%; margin-left: 95px">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':activeTab==='user'}" (click)="setTab('user')">My Info</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':activeTab==='query'}" (click)="setTab('query')">Search Services</a>
    </li>
  </ul>

  <div class="tab-content">

    <div *ngIf="activeTab==='query'">
      <div class="content" role="main">
        <h3 class="">Find Services By</h3>
        <div style="width: 100%; margin: auto">

          <div class="input-group mt-2">
            <label for="searchZip" class="mt-1 labelBox">Zip Code</label>
            <input class="form-control searchBox" placeholder="Enter Zip Code" id="searchZip"
              [(ngModel)]="searchZipcode" (keyup)="handleKeyUp($event)">
            <div class="input-group-append ">
              <button class="btn btn-outline-secondary" (click)="onClickServiceByZip()" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>

          <div *ngIf="searchComplete">
            <h3 class="mt-5">Search Results</h3>
            <div style="width: 100%; margin: auto">

              <h5 class="mt-3 text-primary">County Info</h5>

              <div class="input-group mt-2">
                <label class="col-sm-4 col-form-label labelBox">County</label>
                <input class="form-control" [ngModel]="searchResult.county.name" disabled>
              </div>

              <div class="input-group mt-2">
                <label class="col-sm-4 col-form-label">State</label>
                <input class="form-control" [ngModel]="searchResult.state.longName" disabled>
              </div>

              <h5 class="mt-3 text-primary">Providers</h5>

              <div *ngIf="searchResult.providers.length > 0">
                <div *ngFor="let provider of searchResult.providers; let i = index">
                  <div class="input-group mt-2">
                    <label class="col-sm-4 col-form-label">Provider {{i+1}}</label>
                    <input class="form-control" [ngModel]="provider.name" disabled>
                  </div>
                </div>
              </div>
              <div *ngIf="searchResult.providers.length === 0">
                <div class="card-body mt-2 text-left">
                  <p class="card-text">No Service Providers in this area</p>
                </div>
              </div>

              <h5 class="mt-3 text-primary">Services Available</h5>


              <div *ngIf="searchResult.services.length > 0">
              <div *ngFor="let service of searchResult.services; let i = index">
                <div class="input-group mt-3">
                  <label class="col-sm-4 col-form-label">Service {{i+1}}</label>
                  <input class="form-control" [ngModel]="service.name " disabled>
                </div>

                <!--<span class="text-secondary" style="margin-left: 200px">Provided By</span>-->

                <div *ngFor="let provider of service.providers; let j = index" class="input-group mt-2"
                  style="margin-left: 200px">
                  <label class="col-sm-3 col-form-label">Provider {{j+1}}</label>
                  <input style="width: 225px" [ngModel]="provider.name " disabled>
                </div>

              </div>
            </div>
              <div *ngIf="searchResult.services.length === 0">
                <div class="card-body mt-2 text-left">
                  <p class="card-text">Phone Only</p>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>

    <div *ngIf="activeTab==='user'">
      <div class="content" role="main">
        <h3 class="">Welcome to the EZaccessMD Admin Portal</h3>

        <div class="card-body mt-4 text-center">
          <p class="card-text">Please select from the various tools</p>
          <p class="card-text">Future information about your user profile will be here.</p>
        </div>

      </div>
    </div>


  </div>
