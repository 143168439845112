import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AccessService } from '../services/access.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationGuardService implements CanActivate{

  constructor(
    private router: Router,
    private authService: AuthService
  ) {

  }

  canActivate() : Promise<boolean>
  {
    return this.authService.refresh().then((succes: boolean) =>{
      if( succes ){
        return true;
      }else{
        return false;
      }
    }).catch(()=>{
      this.router.navigate(['/enroll/request-code']);
      return false;
    });

  }

}
