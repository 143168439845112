import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { timeout, take, tap } from 'rxjs/operators';
import { APIEndpoint, GenericAPIResp, APIRequestMethod } from '../models/api.model';
import { APP_CONFIG } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) {

  }

  async submitRequest(endpoint?: APIEndpoint, body?: any, client?: HttpClient): Promise<GenericAPIResp> {

    const whichClient = !!client ? client : this.http;

    switch (endpoint?.method) {
      case APIRequestMethod.POST:
        return this.postRequst(endpoint, body, whichClient);
      case APIRequestMethod.GET:
        return this.getRequst(endpoint, whichClient);
      case APIRequestMethod.PUT:
        return this.putRequst(endpoint, body, whichClient);
      case APIRequestMethod.DELETE:
        return this.deleteRequst(endpoint, whichClient);
      default:
        return Promise.reject({statusCode: 500, error: true, body: "Bad endpoint", handled: false} as GenericAPIResp);
    }
  }


  private async postRequst(endpoint: APIEndpoint, body?: any, client?: HttpClient): Promise<GenericAPIResp> {

    return new Promise<GenericAPIResp>((resolve: any, reject: any) => {
      client?.post(
        endpoint.toString(),
        body,
        {
          headers: this.defaultHeaders(),
          observe: 'response'
        }
      ).pipe(
        timeout(APP_CONFIG.API.TIMEOUT),
        take(1),
        tap((resp: HttpResponse<any>) => {
          //console.log('POST Request response: ', resp);
        })
      ).subscribe(
        (successResp: HttpResponse<any>) => resolve(this.resolveSuccess(successResp)),
        (errorResp: any) => reject(this.resolveError(errorResp, endpoint))
      );
    });
  }

  private async putRequst(endpoint: APIEndpoint, body?: any, client?: HttpClient): Promise<GenericAPIResp> {

    return new Promise<GenericAPIResp>((resolve: any, reject: any) => {

      client?.put(
        endpoint.toString(),
        body,
        {
          headers: this.defaultHeaders(),
          observe: 'response'
        }
      ).pipe(
        timeout(APP_CONFIG.API.TIMEOUT),
        take(1),
        tap((resp: HttpResponse<any>) => {
          //console.log('PUT Request response: ', resp);
        })
      ).subscribe(
        (successResp: HttpResponse<any>) => resolve(this.resolveSuccess(successResp)),
        (errorResp: any) => reject(this.resolveError(errorResp, endpoint))
      );
    });

  }

  private async getRequst(endpoint: APIEndpoint, client?: HttpClient): Promise<GenericAPIResp> {

    return new Promise<GenericAPIResp>((resolve: any, reject: any) => {
      client?.get(
        endpoint.toString(),
        {
          headers: this.defaultHeaders(),
          observe: 'response',
          params: endpoint.getParams() ? endpoint.getParams() : undefined
        }
      ).pipe(
        timeout(APP_CONFIG.API.TIMEOUT),
        take(1),
        tap((resp: HttpResponse<any>) => {
          //console.log('GET Request response: ', resp);
        })
      ).subscribe(
        (successResp: HttpResponse<any>) => resolve(this.resolveSuccess(successResp)),
        (errorResp: any) => reject(this.resolveError(errorResp, endpoint))
      );
    });

  }

  private async deleteRequst(endpoint: APIEndpoint, client?: HttpClient): Promise<GenericAPIResp> {

    return new Promise<GenericAPIResp>((resolve: any, reject: any) => {
      client?.delete(
        endpoint.toString(),
        {
          headers: this.defaultHeaders(),
          observe: 'response'
        }
      ).pipe(
        timeout(APP_CONFIG.API.TIMEOUT),
        take(1),
        tap((resp: HttpResponse<any>) => {
          //console.log('DELETE Request response: ', resp);
        })
      ).subscribe(
        (successResp: HttpResponse<any>) => resolve(this.resolveSuccess(successResp)),
        (errorResp: any) => reject(this.resolveError(errorResp, endpoint))
      );
    });

  }

  private resolveSuccess(successResp: HttpResponse<any>) {

    const resp = {
      statusCode: successResp.status,
      error: false,
      body: successResp.body
    } as GenericAPIResp;


    return resp;
  }

  private resolveError(errorResp: any, endpoint: APIEndpoint): GenericAPIResp {
    // console.log('Request rejected from ' + endpoint.toString() + ' : ' + errorResp);

    if( !!errorResp ) {
      if ( (errorResp.name === 'TimeoutError') || (errorResp.status === 0)) {
        //console.log('Request Timeout');
      } else if (errorResp.status === 500) {
        //console.log('Request server error 500');
      }

      const errorObj = {
        statusCode: errorResp.status,
        error: true,
        body: errorResp.error,
        handled: true
      } as GenericAPIResp;

      return errorObj;
    }else{
      return {
        statusCode: 500,
        error: true,
        body: 'Empty Error',
        handled: true
      } as GenericAPIResp;
    }
  }

  private defaultHeaders(): HttpHeaders {

    return new HttpHeaders()
      //.set(HEADER_APP_TOKEN, BACKEND_ACCESS_TOKEN)
      //.set(HEADER_APP_VERSION, HEADER_APP_VERSION_PREFIX + AppVersion)
      .set('Content-Type', 'application/json');
  }

}
