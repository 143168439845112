<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar></app-toolbar>
<div class="content" role="main">
  <ng-container class="form" [formGroup]="form">
    <div class="form-group">

      <hr class="bg-primary">
      <h3 class="text-left mt-4 text-dark">Begin Enrollment</h3>
      <div class="text-left" style="font-size: 18px;"><strong class="text-primary">Let's start by providing personal information for the primary member</strong></div>
      <hr class="bg-primary mt-4">
      <app-personal-info formGroupName="personalInfo"></app-personal-info>

      <div class="container mt-5">
        <div class="row">
          <div class="col text-center">
            <div class="card-container">
              <button type="button" class="card card-small btn bg-secondary text-white"  (click)="onClickNext()" [disabled]="form.invalid">CONTINUE</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>
