import { Pipe, PipeTransform } from '@angular/core';
import { DependentControl } from '../pages/admin/admin-subscriptions/admin-subscriptions.component';


@Pipe({
  name: 'expandedDependents',
})
export class ExpandedDependentsPipe implements PipeTransform{

  transform(deps: DependentControl[], isExpanded: boolean ): DependentControl[] {
         return isExpanded? deps : new Array<DependentControl>();

  }

}
