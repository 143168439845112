<nav class="navbar navbar-expand navbar-light bg-white">
  <a class="navbar-brand" href="/admin/home">
    <img src="assets/ezmd-logo.svg" width="200" class="d-inline-block align-top" alt="">
  </a>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">

    <div class="navbar-nav">
      <a class="nav-item nav-link" href="/admin/home"><span [ngClass]="actionName === 'Home' ? 'text-secondary' : 'text-dark' ">Home</span></a>
      <a class="nav-item nav-link" href="/admin/registrations"><span [ngClass]="actionName === 'Registrations' ? 'text-secondary' : 'text-dark' ">Registrations</span></a>
      <a class="nav-item nav-link" href="/admin/subscriptions"><span [ngClass]="actionName === 'Enrollment' ? 'text-secondary' : 'text-dark' ">Enrollment</span></a>
      <a class="nav-item nav-link" href="/admin/clients"><span [ngClass]="actionName === 'Clients' ? 'text-secondary' : 'text-dark' ">Clients</span></a>
      <a class="nav-item nav-link" href="/admin/services"><span [ngClass]="actionName === 'Services' ? 'text-secondary' : 'text-dark' ">Services</span></a>
    </div>
  </div>
  <div class="my-2 my-lg-0">
    <h4 class="text-primary">Admin Portal</h4>
  </div>
</nav>
