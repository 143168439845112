import { Pipe, PipeTransform } from '@angular/core';
import { DependentControl, SubGroupContainer } from '../pages/admin/admin-subscriptions/admin-subscriptions.component';

@Pipe({
  name: 'subscriberGroupSort'
})
export class SubscriberGroupSortPipe implements PipeTransform {

  transform(items: SubGroupContainer[], searchText: string): SubGroupContainer[] {

    const txt = searchText.toLocaleLowerCase();
    const matches = Array<SubGroupContainer>();


    items?.forEach((group: SubGroupContainer) =>
    {
      const regMatch =  JSON.stringify(group.reg).toLocaleLowerCase().includes(txt);

      let g =
      {
        primary: group.primary,
        reg: group.reg,
        dependents: new Array<DependentControl>()
      } as SubGroupContainer;

      group.dependents.forEach((dep: DependentControl) =>
      {
        const depMatch = JSON.stringify(dep.subscriber).toLocaleLowerCase().includes(txt) ;
        if( depMatch || regMatch )
        {
          g.dependents.push(dep);
        }
      });

      let primTxt = JSON.stringify(group.primary).toLocaleLowerCase();
      primTxt += 'self';
      const match = primTxt.includes(txt) || regMatch;

      if( g.dependents.length > 0 || match )
      {
        matches.push(g);
      }

    });

    return matches;

  }

}
