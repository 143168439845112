import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-validation',
  templateUrl: './contact-validation.component.html',
  styleUrls: ['./contact-validation.component.scss']
})
export class ContactValidationComponent implements OnInit {
  contactType = 'phone';

  constructor() { }

  ngOnInit(): void {
  }

  setContactType(type: string)
  {
    this.contactType = type;
  }

  check()
  {

  }
}
