import { Pipe, PipeTransform } from '@angular/core';
import { RegData, Registration } from '../models/registration.model';

@Pipe({
  name: 'registrationFilter'
})
export class RegistrationFilterPipe implements PipeTransform {

  transform(items: RegData[], searchText: string): RegData[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return (
        it.reg.firstName?.toLocaleLowerCase().includes(searchText) ||
        it.reg.lastName?.toLocaleLowerCase().includes(searchText) ||
        it.reg.clientName?.toLocaleLowerCase().includes(searchText) ||
        it.reg.status?.toLocaleLowerCase().includes(searchText) ||
        it.reg.email?.toLocaleLowerCase().includes(searchText) ||
        it.reg.phoneNumber?.toLocaleLowerCase().includes(searchText)
      );
    });

  }

}

