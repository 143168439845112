
<form autocomplete="off" [formGroup]="form">
  <div class="input-group mt-3">
    <strong class="text-dark">Provide Enrollment Access Code</strong>
  </div>

  <div class="container mt-2">
  Please enter your enrollment access code to get started or continue with your enrollment process.
  </div>

  <div class="input-group mt-4" style="width:300px; margin:auto">
    <input class="form-control" style="text-align: center" placeholder="Enter Access Code" formControlName="code" >
  </div>

  <div class="container mt-4 text-center">
    Not received or lost your access code?
  </div>

  <div class="container mt-2 text-center">
    <a class="text-primary" style="text-decoration: underline" [routerLink]="['/enroll/request-code']">Request a New Code</a>
  </div>

  <div class="container mt-3 text-center">

    <em>Access codes are only valid for 5 minutes</em>
  </div>

</form>
