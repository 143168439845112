import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientInfo } from 'src/app/models/client.model';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { Registration } from 'src/app/models/registration.model';
import { Subscriber, Subscription, SubscriptionAdapater } from 'src/app/models/subscription.model';
import { DateObjectToStringPipe } from 'src/app/pipes/date-object-to-string.pipe';
import { DateStringToObjectPipe } from 'src/app/pipes/date-string-to-object.pipe';
import { AdminService } from 'src/app/services/admin.service';
import { ExporterService } from 'src/app/services/exporter.service';

export interface SubscriberControl {
  subscriber: Subscriber;
  isSelected: boolean;
  isExpanded: boolean;
  canDelete: boolean;
}

export interface DependentControl {
  subscriber: Subscriber;
  isSelected: boolean;
  canDelete: boolean;
}

export interface SubGroupContainer {
  reg: Registration;
  primary: SubscriberControl;
  dependents: Array<DependentControl>;
}

@Component({
  selector: 'app-admin-subscriptions',
  templateUrl: './admin-subscriptions.component.html',
  styleUrls: ['./admin-subscriptions.component.scss']
})
export class AdminSubscriptionsComponent implements OnInit {

  searchText = '';
  lockControls = false;
  allSelect = false;
  expandAll = false;
  selectionCount = 0;

  data!: Array<SubGroupContainer>;
  clients = new Array<ClientInfo>();
  subscriberStatusNames = new Array<string>();

  selectedIndexPrimary!: number;
  selectedIndexDependent!: number;

  selectedCopySubscription!: SubGroupContainer;
  selectedCopyRegistration!: Registration;
  selectedCopyPrimary!: Subscriber;
  selectedCopyDependent!: Subscriber;
  selectedCopyDependents = new Array<Subscriber>();
  returnToManageDep = false;

  selectedClientName = 'Select Client';
  selectedStatusName = 'Select Status'

  bulkStartDate!: any;
  bulkEndDate!: any;

  datesModal = 'none';
  updatePrimaryModal = 'none';
  updateDependentModal = 'none';
  manageDependentsModal = 'none';
  activateModal = 'none';
  deactivateModal = 'none';
  overrideStatusModal = 'none';

  editCount = 0;
  activateCount = 0;
  deactivateCount = 0;

  zipToCode = new Map<string, string>();

  form!: FormGroup;

  String = String;
  SubscriptionAdapter = SubscriptionAdapater;

  constructor(
    private spinner: NgxSpinnerService,
    private adminService: AdminService,
    private exporter: ExporterService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private parserFormatter: NgbDateParserFormatter
  ) {

    this.form = this.fb.group({

      info: this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        dateOfBirth: ['', Validators.required],
        genderType: ['', Validators.required]
      }),
      address: this.fb.group(
        {
          usePrimaryAddress: [{value: false, disabled: false}],
          addressLine1: [{ value: '', disabled: false }, Validators.required],
          city: [{ value: '', disabled: false }, Validators.required],
          state: [{ value: '', disabled: false }, Validators.required],
          zipCode: [{ value: '', disabled: false }, Validators.required]
        }
      ),
      contact: this.fb.group(
        {
          usePrimaryPhone: [{value: false, disabled: false}],
          phoneNumber: [{ value: '', disabled: false }, Validators.required],
          phoneType: [{ value: '', disabled: false }, Validators.required],
          email: [{ value: '', disabled: false }, [Validators.required, Validators.email]]
        }
      ),
    });
  }

  ngOnInit(): void {
    this.refreshData();
  }

  refreshData() {
    this.spinner.show().then(() => {

      Promise.all([
        this.adminService.getSubscriptions(),
        this.adminService.getClients(),
        this.adminService.getSubscriberStatusNames()
      ]).then((values: any[]) => {
        this.allSelect = false;
        this.expandAll = false;
        this.selectionCount = 0;
        this.data = new Array<SubGroupContainer>();
        this.clients = new Array<ClientInfo>();

        this.subscriberStatusNames = values[2];

        (values[1] as ClientInfo[])?.forEach((client: ClientInfo) => this.clients.push(client));

        (values[0] as Subscription[])?.forEach((sub: Subscription) => {
          //const subId = subscriber.info.id;
          const depControl = new Array<SubscriberControl>();
          sub.dependents.forEach((dep: Subscriber) => {
            depControl.push(
              {
                isSelected: false,
                subscriber: dep,
                canDelete: !!dep?.service?.status  ? (dep?.service?.status  === 'NOT SENT') || ( dep?.service?.status  === 'UNKNOWN') : true
              } as SubscriberControl
            );
          });

          this.data.push({
            reg: sub.reg,
            primary: {
              isSelected: false,
              isExpanded: false,
              subscriber: sub.primary,
              canDelete: !!sub?.primary?.service?.status ? (sub?.primary?.service?.status === 'NOT SENT') || (sub?.primary?.service?.status === 'UNKNOWN') : true
            } as SubscriberControl,
            dependents: depControl
          } as SubGroupContainer);

        });

      }).catch((err: ErrorCodeObj) => {
        this.toastr.error(err.msg);
        console.error(err);
      }).finally(() => this.spinner.hide());

    });
  }

  getCode(zipCode: string | undefined) {
    if (!!zipCode && this.zipToCode.has(zipCode)) {
      const code = this.zipToCode.get(zipCode);
      return code;
    } else {
      return 'Querying...';
    }
  }

  onClickSelectAll() {
    this.allSelect = !this.allSelect;
    this.selectionCount = 0;

    this.data.forEach((item: SubGroupContainer) => {
      item.primary.isSelected = this.allSelect;
      if (this.allSelect) {
        this.selectionCount++;
      }

      item.dependents.forEach((dep: DependentControl) => {
        dep.isSelected = this.allSelect;
        if (this.allSelect) {
          this.selectionCount++;
        }
      });

    });

  }

  onClickBox(index: number) {

    this.data[index].primary.isSelected = !this.data[index].primary.isSelected;
    if (this.data[index].primary.isSelected === false) {
      this.allSelect = false;
      this.selectionCount--;
    } else {
      this.selectionCount++;
    }
  }

  onClickExpand(index: number, value: boolean) {
    this.data[index].primary.isExpanded = value;
    if (value === false) {
      this.expandAll = false;
    }
  }

  onClickDependentBox(i: number, j: number) {
    this.data[i].dependents[j].isSelected = !this.data[i].dependents[j].isSelected;
    if (this.data[i].dependents[j].isSelected === false) {
      this.allSelect = false;
      this.selectionCount--;
    } else {
      this.selectionCount++;
    }
  }

  onClickExpandAll(val: boolean) {
    this.expandAll = val;
    this.data.forEach((item: SubGroupContainer) => {
      item.primary.isExpanded = val;
    });
  }

  onClickSetDates() {
    if (this.selectionCount > 0) {
      this.datesModal = 'block';
    }
  }

  exportToCSV() {
    const subs = new Array<Subscription>();

    this.data.forEach((sub: SubGroupContainer) => {
      const primary = sub.primary;
      const deps = new Array<Subscriber>();

      sub.dependents.forEach((dep: DependentControl) => {
        if (dep.isSelected) {
          deps.push(dep.subscriber);
        }
      });

      if (deps.length > 0 || primary.isSelected) {
        subs.push(
          {
            reg: sub.reg,
            primary: primary.subscriber,
            dependents: deps
          } as Subscription
        );
      }
    });

    this.exporter.exportSubscriptionMediOrbis(subs)
      .then(() => {

      }).catch((err: ErrorCodeObj) => {
        console.error(err);
      });
  }

  setEnrollmentDates() {
    this.spinner.show().then(() => {
      this.dismissModal();

      const subsToUpdate = new Array<Subscriber>();

      let startDate: Date | undefined;
      let endDate: Date | undefined;

      if (this.bulkStartDate !== undefined) {
        startDate = new Date(this.parserFormatter.format(this.bulkStartDate));
        startDate.setTime(startDate.getTime() + (startDate.getTimezoneOffset() * 60000));
      }

      if (this.bulkEndDate !== undefined) {
        endDate = new Date(this.parserFormatter.format(this.bulkEndDate));
        endDate.setTime(endDate.getTime() + (endDate.getTimezoneOffset() * 60000));
      }


      this.data.forEach((sub: SubGroupContainer) => {
        if (sub.primary.isSelected) {
          if (!!startDate) {
            sub.primary.subscriber.service.startDate = startDate
          }

          if (!!endDate) {
            sub.primary.subscriber.service.endDate = endDate
          }

          subsToUpdate.push(sub.primary.subscriber);
        }

        sub.dependents.forEach((dep: DependentControl) => {
          if (dep.isSelected) {
            if (!!startDate) {
              dep.subscriber.service.startDate = startDate
            }

            if (!!endDate) {
              dep.subscriber.service.endDate = endDate
            }

            subsToUpdate.push(dep.subscriber);
          }
        });
      });

      this.adminService.updateSubscribers(subsToUpdate, false)
        .then(() => this.refreshData())
        .catch((err: any) => {
          console.error(err);
          this.toastr.error('Failed to update all enrollement dates');
          this.spinner.hide();
        });
    });

  }

  dismissModal() {
    this.datesModal = 'none';
    this.updatePrimaryModal = 'none';
    this.updateDependentModal = 'none';
    this.manageDependentsModal = 'none';
    this.activateModal = 'none';
    this.deactivateModal = 'none';
    this.overrideStatusModal = 'none';
  }

  toDate(date: any | undefined): Date | undefined {
    return !!date ? new Date(date) : undefined;
  }

  copyPrimay(primaryIndex: number) {
    this.selectedIndexPrimary = primaryIndex;
    this.selectedCopySubscription = { ... this.data[primaryIndex] };
    this.selectedCopyRegistration = this.selectedCopySubscription.reg
    this.selectedCopyPrimary = this.selectedCopySubscription.primary.subscriber;
    this.selectedCopyDependents = this.selectedCopySubscription.dependents.map((container: DependentControl) => container.subscriber);
  }

  onClickEditPrimary(index: number) {
    this.copyPrimay(index);
    this.setSubscriberForm(this.selectedCopyPrimary);
    this.updatePrimaryModal = 'block';
  }

  onClickDeletePrimary(index: number)
  {
    alert('Future Development');
  }

  onClickEditDependent(primaryIndex: number, dependentIndex: number, returnDep: boolean = false) {
    this.returnToManageDep = returnDep;
    this.copyPrimay(primaryIndex);
    this.selectedCopyDependent = this.selectedCopySubscription.dependents[dependentIndex].subscriber;

    this.setSubscriberForm(this.selectedCopyDependent);
    this.updateDependentModal = 'block';
  }

  onClickDeleteDependent(primaryIndex: number, dependentIndex: number, returnDep: boolean = false) {
    /*this.returnToManageDep = returnDep;
    this.copyPrimay(primaryIndex);
    this.selectedCopyDependent = this.selectedCopySubscription.dependents[dependentIndex].subscriber;

    this.setSubscriberForm(this.selectedCopyDependent);
    this.updateDependentModal = 'block';
    */
  }

  setSubscriberForm(sub: Subscriber) {

    this.form.patchValue(
      {
        info: sub.info,
        address: sub.address,
        contact: sub.contact,
      }
    )

    // Special formats
    const dateOfBirth = new DateStringToObjectPipe().transform(sub.info.dateOfBirth);
    const phoneNumber = sub.contact.mobilePhone !== null ? sub.contact.mobilePhone?.substring(2) : sub.contact.phone?.substring(2);
    const phoneType = sub.contact.mobilePhone !== null ? 'MOBILE' : 'OTHER';

    this.form.patchValue(
      {
        info:
        {
          dateOfBirth: dateOfBirth
        },
        contact:
        {
          phoneNumber: phoneNumber,
          phoneType: phoneType
        }
      }
    )

  }

  onClickClientName(name: string) {
    this.selectedCopyPrimary.client.name = name;
    const matches =  this.clients.find((client: ClientInfo) => client.name === name);
    this.selectedCopyPrimary.client.id = !!matches ? String(matches.id) : '';
  }

  onClickClientNameDep(name: string) {
    this.selectedCopyDependent.client.name = name;
    const matches =  this.clients.find((client: ClientInfo) => client.name === name);
    this.selectedCopyDependent.client.id = !!matches ? String(matches.id) : '';
  }

  onClickCancelUpdatePrimary() {
    this.dismissModal();
  }

  onClickManageDependents() {

    this.updateSubscriber(this.selectedCopyPrimary)
    .then(()=>
    {
      this.updatePrimaryModal = 'none';
      this.manageDependentsModal = 'block';
    });
  }

  onClickAddDependent() {
    alert('Future Development');
  }

  onClickCancelManageDependent() {
      this.updatePrimaryModal = 'block';
      this.manageDependentsModal = 'none';
  }

  onClickEditDependentById(id: string) {
    const depIndex = this.selectedCopySubscription.dependents.findIndex((container: DependentControl) => container.subscriber.info.id === id);
    if (depIndex >= 0) {
      this.manageDependentsModal = 'none';
      this.onClickEditDependent(this.selectedIndexPrimary, depIndex, true);
    }

  }

  onClickDeleteDependentById(id: string) {
    /*
    const index = this.selectedCopyDependents.findIndex((sub: Subscriber) => sub.info.id === id);
    if (index >= 0) {
      this.selectedCopyDependents.splice(index, 1);
    }*/
    alert('Future Development');
  }

  onClickCancelUpdateDependent(returnManage: boolean) {

    this.dismissModal();

    if (returnManage) {
      this.manageDependentsModal = 'block';
    }
  }

  onClickSaveManageDependent(returnPrimary: boolean) {
    this.dismissModal();
    if( returnPrimary )
    {
      this.updatePrimaryModal = 'block';
    }
  }

  onClickSavePrimary() {
    this.updateSubscriber(this.selectedCopyPrimary);
  }

  onClickSaveDependent(returnManage: boolean) {
    this.updateSubscriber(this.selectedCopyDependent, returnManage);
    if (returnManage) {
      this.manageDependentsModal = 'block';
    }
  }

  updateSubscriber(sub: Subscriber, returnManage: boolean = false) : Promise<void>
  {

    return new Promise<void>((resolve: () => void, reject: (err: any) => void) =>
    {

      let info = this.form.get('info')?.value;
      let address = this.form.get('address')?.value;
      let contact = this.form.get('contact')?.value;

      //const cIndex = this.clients.findIndex((client: ClientInfo) => client.name === this.selectedCopyRegistration.clientName);
      //sub.client.id = cIndex >=0 ? String(this.clients[cIndex].id) : '';

      sub.info.firstName = info.firstName;
      sub.info.lastName = info.lastName;
      sub.info.dateOfBirth = new DateObjectToStringPipe().transform(info.dateOfBirth);
      sub.info.genderType = info.genderType;

      sub.address.addressLine1 = address.addressLine1;
      sub.address.city = address.city;
      sub.address.state = address.state;
      sub.address.zipCode = address.zipCode;

      if (contact.phoneType === 'MOBILE') {
        sub.contact.mobilePhone = contact.phoneNumber.e164Number;
        sub.contact.phone = null!;
      } else {
        sub.contact.phone = contact.phoneNumber.e164Number;
        sub.contact.mobilePhone = null!;
      }
      sub.contact.email = contact.email;

      this.spinner.show().then(() => {
        this.adminService.updateSubscribers([sub])
          .then(() => {
            this.dismissModal();
            if (returnManage) {
              this.manageDependentsModal = 'block';
            } else {
              this.refreshData();
            }
          }).catch((err: ErrorCodeObj) => {
            console.error(err);
            this.toastr.show(err.msg);
          }).finally(()=>{
            resolve();
            this.spinner.hide();
          } );
      });
    });
  }

  async onClickActivateMediorbis() {
    this.activateCount = this.selectionCount;

    if (this.activateCount > 0) {
      this.activateModal = 'block';
    }
  }

  async activateMediorbis() {
      this.uploadMediorbis(true);
  }

  async onClickDeactivateMediorbis() {

    this.deactivateCount = this.selectionCount;

    if (this.deactivateCount > 0) {
      this.deactivateModal = 'block';
    }

  }

  async deactivateMediorbis() {
    this.uploadMediorbis(false);
  }

  async uploadMediorbis(activate: boolean = true)
  {
    this.spinner.show().then(() => {
      const subs = new Array<Subscriber>();

      this.data.forEach((subContainer: SubGroupContainer) => {
        if (subContainer.primary.isSelected) {
          subs.push(subContainer.primary.subscriber);
        }

        subContainer.dependents.forEach((depContainer: DependentControl) => {
          if (depContainer.isSelected) {
            subs.push(depContainer.subscriber);
          }
        });
      });
      this.adminService.uploadMediorbis(subs, activate)
        .then(() => {
          this.dismissModal();
          this.refreshData();
        })
        .catch((err: ErrorCodeObj) => {
          console.error(err);
          this.toastr.error(err.msg);
          this.spinner.hide()
        });

    });
  }

  overrideStatus()
  {
    this.spinner.show().then(() => {
      const subs = new Array<Subscriber>();

      this.data.forEach((subContainer: SubGroupContainer) => {
        if (subContainer.primary.isSelected) {
          subs.push(subContainer.primary.subscriber);
        }

        subContainer.dependents.forEach((depContainer: DependentControl) => {
          if (depContainer.isSelected) {
            subs.push(depContainer.subscriber);
          }
        });
      });
      this.adminService.setSubscriberStatus(subs, this.selectedStatusName)
        .then(() => {
          this.selectedStatusName = 'Select Client';
          this.dismissModal();
          this.refreshData();
        })
        .catch((err: ErrorCodeObj) => {
          console.error(err);
          this.toastr.error(err.msg);
          this.spinner.hide();
        });

    });

  }

  onClickStatus(name: string)
  {
    this.selectedStatusName = name;
  }

  onClickSetStatus()
  {
    if( this.selectionCount > 0 )
    {
      this.overrideStatusModal = 'block';
    }
  }

}








