import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { Subscriber, SubscriberAddress, SubscriberContact } from 'src/app/models/subscription.model';
import { SubscriptionService } from 'src/app/services/subscription.service';
import * as HttpStatus from 'http-status-codes';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ezenroll-primary-contact',
  templateUrl: './ezenroll-primary-contact.component.html',
  styleUrls: ['./ezenroll-primary-contact.component.scss']
})
export class EzEnrollPrimaryContactComponent implements OnInit {
  form!: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {

    this.form = this.fb.group(
      {
        addressInfo: this.fb.group(
          {
            addressLine1: [{value: '', disabled: false}, Validators.required],
            city: [{value: '', disabled: false}, Validators.required],
            state: [{value: '', disabled: false}, Validators.required],
            zipCode: [{value: '', disabled: false}, [Validators.required, Validators.pattern('[0-9]{5}')]]
          }
        ),
        contactInfo: this.fb.group(
          {
            phoneNumber: [{value: '', disabled: false}, [Validators.required]],
            phoneType: [{value: '', disabled: false}, [Validators.required]],
            email: [{value: '', disabled: false}, [Validators.email]]
          }
        ),
      }
    );
  }

  ngOnInit() {
    this.spinner.show().then(()=>{

      this.subscriptionService.getPrimary(false).then((primary: Subscriber) =>
      {
        this.form.patchValue(
          {
            addressInfo: {
              addressLine1: primary.address.addressLine1,
              city: primary.address.city,
              state: primary.address.state,
              zipCode: primary.address.zipCode
            }
          }
        );

        if( primary.contact.mobilePhone !== null )
        {
          this.form.patchValue(
            {
              contactInfo: {
                phoneNumber: primary.contact.mobilePhone?.substring(2),
                phoneType: 'MOBILE',
                email: primary.contact.email
              }
            }
          );
        }else{

          this.form.patchValue(
            {
              contactInfo: {
                phoneNumber: primary.contact.phone?.substring(2),
                phoneType: 'OTHER',
                email: primary.contact.email
              }
            }
          );
        }

      }).catch((err: ErrorCodeObj) => {
        if( err.statusCode === HttpStatus.StatusCodes.FORBIDDEN)
        {
          this.router.navigate(['enroll']);
        }
        console.error(err);
      })
      .finally(()=> this.spinner.hide());
    });

  }

  onClickNext()
  {

    const addressInfo = this.form.get('addressInfo')?.value;
    const contactInfo = this.form.get('contactInfo')?.value;

    const phoneNumber = contactInfo?.phoneNumber?.e164Number;

    if( !!phoneNumber )
    {

      this.spinner.show().then(()=>
      {
        const address =
        {
          addressLine1: addressInfo.addressLine1,
          city: addressInfo.city,
          state: addressInfo.state,
          zipCode: addressInfo.zipCode
        } as SubscriberAddress;

        const phoneNumber = contactInfo?.phoneNumber.e164Number;

        const contact =
        {
          mobilePhone: contactInfo.phoneType === 'MOBILE' ? phoneNumber : undefined,
          phone: contactInfo.phoneType === 'OTHER' ? phoneNumber: undefined,
          email: contactInfo.email
        } as SubscriberContact;

        Promise.all(
          [
            this.subscriptionService.setPrimaryAddress(address),
            this.subscriptionService.setPrimaryContact(contact)
          ]
        ).then(()=>
          {
            this.router.navigateByUrl('/enroll/dependents');
          }).catch((err: ErrorCodeObj)=> console.error(err.msg))
          .finally(()=>this.spinner.hide());
      });
    }
  }

  onClickBack()
  {
    this.router.navigateByUrl('/enroll/primary-info');
  }

}
