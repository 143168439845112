import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { APIEndpoint } from '../models/api.model';
import { API_ENDPOINTS} from '../config/routes';
import { HEADER_AUTHORIZATION, REGISTRATION_TOKEN_NAME, USER_TOKEN_NAME} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //console.log('Intercepting the auth module');
    let ep = APIEndpoint.matchEndpointFromRequest(request, API_ENDPOINTS);

    if (ep) {
        if( ep.pathItems[0].currentValue === "enroll")
        {
          return next.handle(this.addRegistrationHeaders(request, ep.requiresAuth));
        }else{
          return next.handle(this.addUserHeaders(request, ep.requiresAuth));
        }
    } else {
      console.error('Unknown endpoint!', request);
      return throwError('BAD ENDPOINT');
    }
  }

  private addUserHeaders(request: HttpRequest<any>, addAuth: boolean, token?: string): HttpRequest<any> {
    let headers = request.headers
      .set('Content-Type', 'application/json');

    if (addAuth) {

      const token  = localStorage.getItem(USER_TOKEN_NAME);
      //console.log('Adding auth header', token);
      headers = headers.set(HEADER_AUTHORIZATION, 'Bearer ' + token);
    }
    return request.clone({ headers });
  }

  private addRegistrationHeaders(request: HttpRequest<any>, addAuth: boolean, token?: string): HttpRequest<any> {
    let headers = request.headers
      .set('Content-Type', 'application/json');

    if (addAuth) {

      const token  = localStorage.getItem(REGISTRATION_TOKEN_NAME);
      //console.log('Adding auth header', token);
      headers = headers.set(HEADER_AUTHORIZATION, 'Bearer ' + token);
    }
    return request.clone({ headers });
  }

}

export const authInterceptProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
};
