import { Pipe, PipeTransform } from '@angular/core';
import { Subscriber } from '../models/subscription.model';

@Pipe({
  name: 'subscriberFilter'
})
export class SubscriberFilterPipe implements PipeTransform {

  transform(items: Subscriber[], searchText: string): Subscriber[] {

    const txt = searchText.toLocaleLowerCase();
    return items?.filter((sub: Subscriber) =>
    {
      let result = false;
      result ||= JSON.stringify(sub).toLocaleLowerCase().includes(txt);
    });
  }

}
