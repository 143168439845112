<app-toolbar></app-toolbar>
<div class="content" role="main">
  <form class="form">
    <div class="form-group">

      <hr class="bg-primary">
      <h3 class="text-left mt-4 text-dark">Welcome!</h3>
      <div class="text-left" style="font-size: 18px; width: 100%">
        <strong class="text-primary">We are working on new enrollment process, to allow quick and easy enrollment in EZaccessMD.</strong>
      </div>

      <hr class="bg-primary mt-4">
      <div class="container mt-2 text-center">
        We will complete the new enrollment process soon.
      </div>

      <div class="container mt-2 text-center">
        Please check back later.
      </div>

    </div>
  </form>
</div>
