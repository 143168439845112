import { Injectable } from '@angular/core';
import { APIDynamicKey, APIPathKey } from '../config/consts';
import { API_ENDPOINTS } from '../config/routes';
import { EndpointArg, GenericAPIResp } from '../models/api.model';
import { ErrorCodeObj } from '../models/error.model';
import { ApiService } from './api.service';
import * as HttpStatus from 'http-status-codes';
import { DependentAdapater, DependentListInfo, Subscriber, SubscriberAdapater, SubscriberAddress, SubscriberContact, SubscriberInfo } from '../models/subscription.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private api: ApiService
  ) { }


  addPrimary()
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_ADD_PRIMARY);

    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        resolve();

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }

  getPrimary(parseServices: boolean = true)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_GET_PRIMARY);

    return new Promise<Subscriber>((resolve: (primary: Subscriber) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        const sub = SubscriberAdapater.adaptSingle(resp.body[0], parseServices);
        resolve(sub);

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }

  getPrimaryPersonalInfo()
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_GET_PRIMARY_INFO);

    return new Promise<SubscriberInfo>((resolve: (info: SubscriberInfo) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        const sub = SubscriberAdapater.adaptSingle( resp.body );
        resolve(sub.info);

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }

  setPrimaryPersonalInfo(info: SubscriberInfo)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_POST_PRIMARY_INFO);

    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep, info).then((resp: GenericAPIResp) =>
      {
        resolve();

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }

  getPrimaryAdress()
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_GET_PRIMARY_ADDRESS);

    return new Promise<SubscriberAddress>((resolve: (address: SubscriberAddress) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        resolve(SubscriberAdapater.adaptAddress( resp.body ));

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }

  setPrimaryAddress(info: SubscriberAddress)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_POST_PRIMARY_ADDRESS);

    return new Promise<boolean>((resolve: (success: boolean) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep, info).then((resp: GenericAPIResp) =>
      {
        resolve(true);

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  getPrimaryContact()
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_GET_PRIMARY_CONTACT);

    return new Promise<SubscriberContact>((resolve: (address:SubscriberContact) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        resolve(SubscriberAdapater.adaptContact( resp.body ));

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }


  setPrimaryContact(info: SubscriberContact)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_POST_PRIMARY_CONTACT);

    return new Promise<boolean>((resolve: (success: boolean) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep, info).then((resp: GenericAPIResp) =>
      {
        resolve(true);

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  getDependants(parseServices: boolean = true)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_GET_DEPENDENTS);

    return new Promise<Subscriber[]>((resolve: (dependants: Subscriber[]) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        resolve(SubscriberAdapater.adaptMultiple( resp.body, parseServices));

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  getDependentPersonalInfo(id: string, parseServices: boolean = true)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_GET_DEPENDENT)
      ?.evalDynamicPath([new EndpointArg(APIDynamicKey.SUBSCRIBER_ID, id)]);

    return new Promise<SubscriberInfo>((resolve: (info: SubscriberInfo) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        const sub = SubscriberAdapater.adaptSingle( resp.body, parseServices );
        resolve(sub.info);

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }

  getDependentAddress(id: string)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_GET_DEPENDENT)
      ?.evalDynamicPath([new EndpointArg(APIDynamicKey.SUBSCRIBER_ID, id)]);

    return new Promise<SubscriberAddress>((resolve: (info: SubscriberAddress) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        resolve(SubscriberAdapater.adaptAddress( resp.body ));

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }

  setDependentAddress(id: string, info: SubscriberAddress)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_POST_DEPENDENT_ADDRESS);

    return new Promise<boolean>((resolve: (success: boolean) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      const body =
      {
        subscriberId: id,
        addressLine1: info.addressLine1,
        city: info.city,
        state: info.state,
        zipCode: info.zipCode
      };

      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) =>
      {
        resolve(true);

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  getSubscriberContact(id: string)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_GET_DEPENDENT)
      ?.evalDynamicPath([new EndpointArg(APIDynamicKey.SUBSCRIBER_ID, id)]);

    return new Promise<SubscriberContact>((resolve: (info: SubscriberContact) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        resolve(SubscriberAdapater.adaptContact( resp.body ));

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }

  setSubscriberContact(id: string, info: SubscriberContact)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_POST_DEPENDENT_CONTACT);

    return new Promise<boolean>((resolve: (success: boolean) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      const body =
      {
        subscriberId: id,
        mobilePhone: info.mobilePhone,
        phone: info.phone,
        email: info.email
      }

      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) =>
      {
        resolve(true);

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  setDependent(info: SubscriberInfo)
  {
    return new Promise<string>((resolve: (id: string) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      if( !info.id )
      {

        const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_POST_DEPENDENT);
        const body =
        {
          subscriberType: info.type
        };

        this.api.submitRequest(ep, body).then((resp: GenericAPIResp) =>
        {
          info.id = resp.body?.id;

          const body2 = {
            subscriberId: info.id,
            firstName: info.firstName,
            lastName: info.lastName,
            dateOfBirth: info.dateOfBirth,
            genderType: info.genderType
          };

          if( !!info.id ) {
            const ep2 = API_ENDPOINTS.get(APIPathKey.REGISTRATION_POST_DEPENDENT_INFO);
            this.api.submitRequest(ep2, body2).then((resp2: GenericAPIResp) =>
            {
              resolve(info.id);
            }).catch((err: ErrorCodeObj) => reject(err))
          }
        }).catch((err: GenericAPIResp) =>
        {
          if( !!ep ){
            reject( ep.errorCodeGenerator(err) );
          }else{
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });

      }
      else{
        const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_PUT_DEPENDENT)
          ?.evalDynamicPath([new EndpointArg(APIDynamicKey.SUBSCRIBER_ID, info.id)]);

        const body =
        {
          subscriberType: info.type
        };

        this.api.submitRequest(ep, body).then((resp: GenericAPIResp) =>
        {
          const body2 = {
            subscriberId: info.id,
            firstName: info.firstName,
            lastName: info.lastName,
            dateOfBirth: info.dateOfBirth,
            genderType: info.genderType
          };

          const ep2 = API_ENDPOINTS.get(APIPathKey.REGISTRATION_POST_DEPENDENT_INFO);
          this.api.submitRequest(ep2, body2).then((resp2: GenericAPIResp) =>
          {
            resolve(info.id);
          }).catch((err: ErrorCodeObj) => reject(err))

        }).catch((err: GenericAPIResp) =>
        {
          if( !!ep ){
            reject( ep.errorCodeGenerator(err) );
          }else{
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });
      }
    });
  }

  removeDependent(id: string)
  {
    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) =>
    {
      const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_DELETE_DEPENDENT)
      ?.evalDynamicPath([new EndpointArg(APIDynamicKey.SUBSCRIBER_ID, id)]);


      this.api.submitRequest(ep)
        .then(() =>  resolve())
        .catch((err: GenericAPIResp) =>
        {
          if( !!ep ){
            reject( ep.errorCodeGenerator(err) );
          }else{
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });

    });
  }

  getDependent(id: string, parseServices: boolean = true)
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_GET_DEPENDENT)
      ?.evalDynamicPath([new EndpointArg(APIDynamicKey.SUBSCRIBER_ID, id)]);

    return new Promise<Subscriber>((resolve: (info: Subscriber) => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        resolve(SubscriberAdapater.adaptSingle( resp.body, parseServices));

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }

  submit()
  {
    const ep = API_ENDPOINTS.get(APIPathKey.REGISTRATION_SUBMIT)

    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) =>
    {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) =>
      {
        resolve();

      }).catch((err: GenericAPIResp) =>
      {
        if( !!ep ){
          reject( ep.errorCodeGenerator(err) );
        }else{
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });
  }


}
