<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar></app-toolbar>
<div class="content" role="main">
  <ng-container class="form" [formGroup]="form" (onChange)="onFormChange()">
    <div class="form-group">

      <h3 class="text-left mt-4 text-dark">Requesting Access</h3>
      <div class="text-left" style="font-size: 18px;"><strong class="text-primary">Enrollment requires proper eligibity
          that is confirmed by an access code</strong></div>
      <hr class="bg-primary mt-4">
      <app-request-code formGroupName="requestInfo"></app-request-code>

      <div class="container mt-5">
        <div class="row">
          <div class="col text-center">
            <div class="card-container">
              <button type="button" class="card card-small btn bg-secondary text-white" (click)="onClickRequest()" [disabled]="form.invalid">REQUEST</button>
              </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>
