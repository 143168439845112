<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar></app-toolbar>
<div class="content" role="main">
  <form class="form" [formGroup]="form">
    <div class="form-group">

      <hr class="bg-primary">
      <h3 class="text-left mt-4 text-dark">Development App Access</h3>
      <div class="text-left" style="font-size: 18px; width: 100%">
        <strong class="text-primary">We require special access to use the development version of the app</strong>
      </div>

      <hr class="bg-primary mt-4">

      <div class="container mt-2 text-center">
       Please enter your development app access code.
      </div>

      <div class="input-group mt-4" style="width:300px; margin:auto">
        <input class="form-control" style="text-align: center" formControlName="code" placeholder="Enter Access Code" name="code"  (keyup.enter)="onClickSubmit()">
      </div>

      <div class="container mt-4">
        <div class="row">
          <div class="col text-center">
            <div class="card-container">
              <button type="button" class="card card-small btn bg-secondary text-white" (click)="onClickSubmit()" [disabled]="form.invalid">SUBMIT</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>
