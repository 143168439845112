import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { Subscriber, SubscriberInfo } from 'src/app/models/subscription.model';
import { SubscriptionService } from 'src/app/services/subscription.service';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import * as HttpStatus from 'http-status-codes';
import { ToastrService } from 'ngx-toastr';
import { DateStringToObjectPipe } from 'src/app/pipes/date-string-to-object.pipe';

@Component({
  selector: 'app-ezenroll-primary-info',
  templateUrl: './ezenroll-primary-info.component.html',
  styleUrls: ['./ezenroll-primary-info.component.scss']
})
export class EzEnrollPrimaryInfoComponent implements OnInit {

  form: FormGroup;
  isNew = true;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private parserFormatter: NgbDateParserFormatter,
    private toastr: ToastrService,
  ) {

    this.form = this.fb.group({

      personalInfo: this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        dateOfBirth: ['', Validators.required],
        genderType: ['', Validators.required]
      })

    });

  }

  ngOnInit(): void {
    this.spinner.show().then(() => {
      this.subscriptionService.getPrimary(false)
        .then((sub: Subscriber) => {
          if( !!sub.info.firstName ) {
            this.isNew = false;
            this.form.patchValue(
              {
                personalInfo: {
                  firstName: sub.info.firstName,
                  lastName: sub.info.lastName,
                  dateOfBirth: new DateStringToObjectPipe().transform(sub.info.dateOfBirth),
                  genderType: sub.info.genderType
                }
              }

            )
          }
        }
        ).catch((err: ErrorCodeObj) => {
          console.error("error: ", err.msg);
          if( err.statusCode === HttpStatus.StatusCodes.FORBIDDEN)
          {
            this.router.navigate(['enroll']);
          }
        }).finally(() => this.spinner.hide());
    });
  }

  onClickNext() {

    const info = this.form.get('personalInfo')?.value;

    if( !!info )
    {
      this.spinner.show().then(()=>
      {
        const dateOfBirth = this.parserFormatter.format(info.dateOfBirth);

        const personalInfo: SubscriberInfo =
        {
          "id": info?.id,
          "type": info?.type,
          "firstName": info?.firstName,
          "lastName": info?.lastName,
          "dateOfBirth": dateOfBirth,
          "genderType": info?.genderType
        };

        Promise.resolve()
          .then(async ()=> await this.subscriptionService.addPrimary())
          .then(async ()=> await this.subscriptionService.setPrimaryPersonalInfo(personalInfo))
          .then(()=> this.router.navigateByUrl('/enroll/primary-contact'))
          .catch((err: ErrorCodeObj) => {
            console.error(err.msg);
            this.toastr.error(err.msg);
            if( err.statusCode === HttpStatus.StatusCodes.FORBIDDEN)
            {
              this.router.navigate(['enroll']);
            }
          }).finally(()=> this.spinner.hide() );
        });

    }
  }

}
