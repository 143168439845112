<form autocomplete="off" [formGroup]="form">
  <div class="input-group mt-3">
      <strong class="text-dark">{{contactName}} Address Information</strong>
  </div>

  <div *ngIf="contactName === 'Dependent'" class="form-check mt-3">
    <input class="form-check-input" type="checkbox" value="" formControlName="usePrimaryAddress" id="usePrimary" (change)="onClickPrimaryAddress($event)">
    <label class="form-check-label" for="usePrimary">
      Use Primary Address
    </label>
  </div>

  <div class="input-group mt-2">
    <input class="form-control" placeHolder="Enter Home Address" [id]="googleId" formControlName="addressLine1" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" (keyup)="checkPrimary($event)" style="width: 100%"/>
    <!--
    <input class="form-control" placeholder="Street Address" formControlName="addressLine1" (input)="onAddressChange($event)">
    -->
  </div>

  <div class="input-group mt-2">
    <input class="form-control" placeholder="City" formControlName="city" (keyup)="checkPrimary($event)" style="width:35%; margin-right: 10px;">
    <input class="form-control form-state " placeholder="State" formControlName="state" (keyup)="checkPrimary($event)" style="margin-right: 10px; text-align: center;">
    <input class="form-control" placeholder="Zip" formControlName="zipCode" (keyup)="checkPrimary($event)" style="text-align: center;">
  </div>
</form>
