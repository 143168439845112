import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { SubscriberInfo, Subscriber } from 'src/app/models/subscription.model';
import { SubscriptionService } from 'src/app/services/subscription.service';
import * as HttpStatus from 'http-status-codes';
import { ToastrService } from 'ngx-toastr';
import { DateStringToObjectPipe } from 'src/app/pipes/date-string-to-object.pipe';

@Component({
  selector: 'app-ezenroll-Dependent-info',
  templateUrl: './ezenroll-dependent-info.component.html',
  styleUrls: ['./ezenroll-dependent-info.component.scss']
})
export class EzEnrollSubscriberInfoComponent implements OnInit {

  form: FormGroup;
  dependentId!: string;
  primaryInfo!: Subscriber;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private parserFormatter: NgbDateParserFormatter,
    private toastr: ToastrService,
  ) {
    this.form = this.fb.group(
      {
        dependentInfo: this.fb.group(
          {
            memberType: ['', Validators.required]
          }
        ),
        personalInfo: this.fb.group(
          {
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            dateOfBirth: ['', Validators.required],
            genderType: ['', Validators.required]
          }
        ),
      }
    );

  }

  ngOnInit(): void {

      this.dependentId = this.route.snapshot.queryParams?.did;

      if( !! this.dependentId )
      {
        this.spinner.show().then(() =>
        {

          Promise.all(
            [
              this.subscriptionService.getPrimary(false),
              this.subscriptionService.getDependentPersonalInfo(this.dependentId, false)
            ]).then((values: any[]) =>
            {
              this.primaryInfo = values[0];
              const info = values[1] as SubscriberInfo;

              this.form.patchValue(
                {
                  dependentInfo:
                  {
                    memberType: String(info.type).toUpperCase()
                  },
                  personalInfo:
                  {
                    firstName: info.firstName,
                    lastName: info.lastName,
                    dateOfBirth: new DateStringToObjectPipe().transform( info.dateOfBirth ),
                    genderType: String(info.genderType).toUpperCase()
                  }
                }
              )
            }).catch((err: ErrorCodeObj) =>
            {
              console.error(err);
              if( err.statusCode === HttpStatus.StatusCodes.FORBIDDEN)
              {
                this.router.navigate(['enroll']);
              }else{
                this.toastr.error(err.msg);
              }

            }).finally(()=>this.spinner.hide());
        });
      }
  }


  onClickContinue()
  {
    this.spinner.show().then(() =>
    {
      const dependantInfo = this.form.get('dependentInfo')?.value;
      const personalInfo = this.form.get('personalInfo')?.value;

      const dateOfBirth = this.parserFormatter.format(personalInfo.dateOfBirth);

      const info =
      {
        id: this.dependentId,
        type: dependantInfo.memberType,
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        dateOfBirth: dateOfBirth,
        genderType: personalInfo.genderType,
      }as SubscriberInfo;

      this.subscriptionService.setDependent(info).then((id: string) =>
      {
        this.router.navigate(['/enroll/dependent-contact'], {queryParams: {did: id}});
      }).catch((err: ErrorCodeObj) =>
      {
        console.error(err.msg);
        if( err.statusCode === HttpStatus.StatusCodes.FORBIDDEN)
        {
          this.router.navigate(['enroll']);
        }
      }).finally(()=>this.spinner.hide());
    });

  }

  onClickCancel()
  {
    this.router.navigate(['/enroll/dependents']);
  }

}
