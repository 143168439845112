import { GenericAPIResp } from "../models/api.model";
import { ErrorCodeObj } from "../models/error.model";
import * as HttpStatus from 'http-status-codes';

export const ERROR_MESSAGES = {
  GENERIC: new Map<number, string>([
    [0, 'Unknown error has occurred'],
    [HttpStatus.StatusCodes.NOT_IMPLEMENTED, 'Feature not ready, unimplemented'],
    [HttpStatus.StatusCodes.INTERNAL_SERVER_ERROR, 'Application has experienced a server error'],
  ]),
  ADMIN: {
    REQUEST_CODE: new Map<HttpStatus.StatusCodes, string>(
      [
        [0, 'Unknown error has occurred'],
        [HttpStatus.StatusCodes.BAD_REQUEST, 'No account with phone number'],
      ]
    ),
    LOGIN: new Map<HttpStatus.StatusCodes, string>(
      [
        [0, 'Unknown error has occurred'],
        [HttpStatus.StatusCodes.BAD_REQUEST, 'Invalid security code'],
      ]
    )
  },
  REGISTRATION: {
    GENERIC:
    new Map<HttpStatus.StatusCodes, string>(
      [
        [0, 'Unknown error has occurred'],
        [HttpStatus.StatusCodes.BAD_REQUEST, 'Unexpected error as occured, please try again'],
        [HttpStatus.StatusCodes.FORBIDDEN, 'Access expired, need to re-authenticate'],
      ]
    ),
    REQUEST_TOKEN: new Map<HttpStatus.StatusCodes, string>(
      [
        [0, 'Unknown error has occurred'],
        [HttpStatus.StatusCodes.BAD_REQUEST, 'Invalid enrollment code'],
      ]
    ),
    REQUEST_TOKEN_EMAIL: new Map<HttpStatus.StatusCodes, string>(
      [
        [0, 'Unknown error has occurred'],
        [HttpStatus.StatusCodes.BAD_REQUEST, 'Invalid email provided'],
      ]
    ),
    REQUEST_TOKEN_SMS: new Map<HttpStatus.StatusCodes, string>(
      [
        [0, 'Unknown error has occurred'],
        [HttpStatus.StatusCodes.BAD_REQUEST, 'Invalid phone number provided'],
      ]
    ),
    NON_UNIQUE_EMAIL:
    new Map<HttpStatus.StatusCodes, string>(
      [
        [0, 'Unknown error has occurred'],
        [HttpStatus.StatusCodes.BAD_REQUEST, 'Email provided already in use, use a different email'],
        [HttpStatus.StatusCodes.FORBIDDEN, 'Access expired, need to re-authenticate'],
      ]
    )
  },
  SUBSCRIPTION: {
    GENERIC:
    new Map<HttpStatus.StatusCodes, string>(
      [
        [0, 'Unknown error has occurred'],
        [HttpStatus.StatusCodes.BAD_REQUEST, 'Unexpected error as occured, please try again'],
        [HttpStatus.StatusCodes.FORBIDDEN, 'Access expired, need to re-authenticate'],
      ]
    )
  }

};

export class AdminErrorCodeGenerator {
  static errorRequestCode(resp: GenericAPIResp): ErrorCodeObj {
    return ErrorCodeObj.generate(resp.statusCode, ERROR_MESSAGES.ADMIN.REQUEST_CODE);
  }

  static errorLogin(resp: GenericAPIResp): ErrorCodeObj {
    return ErrorCodeObj.generate(resp.statusCode, ERROR_MESSAGES.ADMIN.LOGIN);
  }
}

export class RegistrationErrorCodeGenerator {
  static errorRequestCode(resp: GenericAPIResp): ErrorCodeObj {
    return ErrorCodeObj.generate(resp.statusCode, ERROR_MESSAGES.REGISTRATION.REQUEST_TOKEN);
  }

  static errorRequestCodeEmail(resp: GenericAPIResp): ErrorCodeObj {
    return ErrorCodeObj.generate(resp.statusCode, ERROR_MESSAGES.REGISTRATION.REQUEST_TOKEN_EMAIL);
  }

  static errorRequestCodeSMS(resp: GenericAPIResp): ErrorCodeObj {
    return ErrorCodeObj.generate(resp.statusCode, ERROR_MESSAGES.REGISTRATION.REQUEST_TOKEN_SMS);
  }

  static errorAddDependentContact(resp: GenericAPIResp): ErrorCodeObj {
    if( JSON.stringify(resp.body).search('Email not unique') >= 0 )
    {
      return  ErrorCodeObj.generate(resp.statusCode, ERROR_MESSAGES.REGISTRATION.NON_UNIQUE_EMAIL);
    }else{
      return ErrorCodeObj.generate(resp.statusCode, ERROR_MESSAGES.REGISTRATION.GENERIC);
    }
  }
}

export class SubscriptionErrorCodeGenerator {
  static errorGeneric(resp: GenericAPIResp): ErrorCodeObj {
    return ErrorCodeObj.generate(resp.statusCode, ERROR_MESSAGES.SUBSCRIPTION.GENERIC);
  }


}
