<div class="toolbar bg-white" role="banner">
  <div style="margin: auto; width: 100%;">
    <div style="width: 150px; float:left">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 619 168.96">
        <title>ezmd-logo</title>
        <path
          d="M158.54,94.83,146.17,83.9l.17-24.75a3,3,0,0,0-3-3H130.69a3,3,0,0,0-3,3V68l-13.2-11.18a3,3,0,0,0-3.79-.1l-49,37.12a3,3,0,0,0,1.83,5.44H73.9v33c0,12.94-7.69,19.7-15.29,19.7a12.73,12.73,0,0,1-9.71-4.54c-3.06-3.53-4.68-8.78-4.68-15.16V88.55L74.84,45.82l.17-.27c8-14.16,9.69-25.15,5-32.67-5.08-8.06-15.83-9.41-21-9.58a6.3,6.3,0,1,0-5.52,9.33A6.27,6.27,0,0,0,58.9,9.38c4.17.17,12.47,1.22,15.94,6.73s1.66,14.48-5,26.31L52.11,67.14a.67.67,0,0,1-.15.06c-9.34,7.23-18.8,1.93-21.15.39l-18-25.17c-6.67-11.81-8.41-20.91-5-26.3S19.36,9.54,23.56,9.38a6.32,6.32,0,1,0,0-6.07c-5.25.18-15.87,1.58-20.9,9.57-4.74,7.52-3.07,18.51,5,32.67L38.16,88.26v44c0,7.86,2.13,14.48,6.16,19.12A18.69,18.69,0,0,0,58.61,158C69.22,158,80,149.14,80,132.23v-36a3,3,0,0,0-3-3H72.54L112.4,63l16.33,13.83a3,3,0,0,0,5-2.31V62.16h6.54l-.16,23.08a3.05,3.05,0,0,0,1,2.29l7.4,6.54H141.8a3,3,0,0,0-3,3V146a11.61,11.61,0,1,0,6.06.27V100.13h11.71a3,3,0,0,0,2-5.3Zm-11.69,62.52a5.56,5.56,0,1,1-5.56-5.56A5.57,5.57,0,0,1,146.85,157.35Z"
          style="fill:#f26724" />
        <path d="M187.24,135.42V65.16h37.34V77.7H200.29v18h20.48v12.55H200.29v14.65h24.29v12.55Z"
          style="fill:#00afaa" />
        <path
          d="M235.4,135.42v-.2l23.19-52.9c.9-2.11,2.21-4.52,2.21-4.52l-.2-.1H239.22V65.16h41.15v.2L257.19,118c-.91,2.1-2.41,4.81-2.41,4.81l.2.1h22.08v12.55Z"
          style="fill:#00afaa" />
        <path
          d="M289,97.46a12.78,12.78,0,0,1,1.2-5,14.26,14.26,0,0,1,2.94-4.13,13,13,0,0,1,4.32-2.79,14.07,14.07,0,0,1,5.29-1,15.25,15.25,0,0,1,6.92,1.64V84.87h9.14v50.67h-9.14v-1.25a16.4,16.4,0,0,1-6.92,1.54,14.07,14.07,0,0,1-5.29-1,13.61,13.61,0,0,1-4.37-2.79,13.29,13.29,0,0,1-3-4.18,12,12,0,0,1-1.11-5.1V97.46Zm8.85,24.91a4.47,4.47,0,0,0,1.44,3.32,4.79,4.79,0,0,0,3.46,1.39,6.08,6.08,0,0,0,2-.38,8.15,8.15,0,0,0,1.92-1,6,6,0,0,0,1.45-1.49,3.29,3.29,0,0,0,.57-1.83V97.17a3.23,3.23,0,0,0-.57-1.82,5.86,5.86,0,0,0-1.45-1.49,7.09,7.09,0,0,0-3.94-1.3,4.87,4.87,0,0,0-3.46,1.35,4.28,4.28,0,0,0-1.44,3.26Z"
          style="fill:#616766" />
        <path
          d="M354,118.33v3.94a12.89,12.89,0,0,1-1.05,5.2,13.34,13.34,0,0,1-7.07,7,13.18,13.18,0,0,1-5.24,1,12.81,12.81,0,0,1-5.19-1,13.61,13.61,0,0,1-4.19-2.84,14.35,14.35,0,0,1-2.88-4.18,12.29,12.29,0,0,1-1.11-5.2V96.69a14,14,0,0,1,4-9.28,13,13,0,0,1,4.19-2.78,13.12,13.12,0,0,1,5.19-1,12.88,12.88,0,0,1,5.09,1A13.47,13.47,0,0,1,354,96.69v3H345.4V96.41a4.72,4.72,0,0,0-9.43,0v25.48a4.72,4.72,0,0,0,9.43,0v-3.56Z"
          style="fill:#616766" />
        <path
          d="M388.11,118.33v3.94a12.73,12.73,0,0,1-1.06,5.2,13.51,13.51,0,0,1-2.84,4.18,13.33,13.33,0,0,1-4.23,2.84,13.15,13.15,0,0,1-5.24,1,12.81,12.81,0,0,1-5.19-1,13.56,13.56,0,0,1-4.18-2.84,14.38,14.38,0,0,1-2.89-4.18,12.44,12.44,0,0,1-1.1-5.2V96.69a13.56,13.56,0,0,1,1.15-5.09,13.73,13.73,0,0,1,2.84-4.19,12.9,12.9,0,0,1,4.18-2.78,13.12,13.12,0,0,1,5.19-1,12.9,12.9,0,0,1,5.1,1,13.57,13.57,0,0,1,4.23,2.78A13.35,13.35,0,0,1,387,91.6a14,14,0,0,1,1.15,5.09v3h-8.56V96.41a4.71,4.71,0,0,0-9.42,0v25.48a4.71,4.71,0,0,0,9.42,0v-3.56Z"
          style="fill:#616766" />
        <path
          d="M422.61,115.16v7.11a12.29,12.29,0,0,1-1.11,5.2,14.35,14.35,0,0,1-2.88,4.18,13.56,13.56,0,0,1-4.23,2.84,13,13,0,0,1-5.15,1,12.81,12.81,0,0,1-5.19-1,13.56,13.56,0,0,1-4.18-2.84,14.38,14.38,0,0,1-2.89-4.18,12.44,12.44,0,0,1-1.1-5.2V96.79a13.85,13.85,0,0,1,1.15-5.1,13.31,13.31,0,0,1,2.89-4.18,13.54,13.54,0,0,1,4.23-2.79,13.36,13.36,0,0,1,10.19,0,13.78,13.78,0,0,1,4.23,2.79,13.31,13.31,0,0,1,2.89,4.18,13.85,13.85,0,0,1,1.15,5.1v11.06h-18V122a4.71,4.71,0,0,0,9.42,0v-6.82Zm-18-14.62h9.42v-4a4.71,4.71,0,0,0-9.42,0Z"
          style="fill:#616766" />
        <path
          d="M452.88,114.68c.7,1.09,1.33,2.06,1.87,2.93a22.8,22.8,0,0,1,1.35,2.4,12.92,12.92,0,0,1,.82,2.17,8.78,8.78,0,0,1,.29,2.3,8.52,8.52,0,0,1-1,3.9,12.91,12.91,0,0,1-2.74,3.56,15.2,15.2,0,0,1-4.14,2.64,12.65,12.65,0,0,1-5.19,1.06,14.45,14.45,0,0,1-5.19-.91,10.65,10.65,0,0,1-4.19-2.89,13.93,13.93,0,0,1-2.78-5,22.1,22.1,0,0,1-1-7.12h8.46v1.35a13.29,13.29,0,0,0,.19,2.21,6.73,6.73,0,0,0,.72,2.12,4.45,4.45,0,0,0,1.44,1.58,4.08,4.08,0,0,0,2.36.63,4,4,0,0,0,4-3.56,4.69,4.69,0,0,0-.72-2.16,12.21,12.21,0,0,0-1.49-2.17l-11.25-15.19a18.94,18.94,0,0,1-2.79-4.71,13.35,13.35,0,0,1-1-5,8.45,8.45,0,0,1,1-3.9,13.13,13.13,0,0,1,2.74-3.6,13.75,13.75,0,0,1,4.13-2.65,13.3,13.3,0,0,1,5.19-1,13.94,13.94,0,0,1,5.2,1,11.6,11.6,0,0,1,4.18,2.89,13.69,13.69,0,0,1,2.79,4.9,22.38,22.38,0,0,1,1,7.12h-8.46V98.14a13.36,13.36,0,0,0-.2-2.22,6.22,6.22,0,0,0-.72-2.06,4.69,4.69,0,0,0-1.44-1.54,4.08,4.08,0,0,0-2.36-.63,3.78,3.78,0,0,0-3,1.21A3.63,3.63,0,0,0,440,95.25a4.71,4.71,0,0,0,.73,2.07,15.81,15.81,0,0,0,1.49,2.26Z"
          style="fill:#616766" />
        <path
          d="M487.47,114.68c.7,1.09,1.33,2.06,1.87,2.93a22.8,22.8,0,0,1,1.35,2.4,13.84,13.84,0,0,1,.82,2.17,8.78,8.78,0,0,1,.29,2.3,8.52,8.52,0,0,1-1,3.9,12.76,12.76,0,0,1-2.75,3.56,15,15,0,0,1-4.13,2.64,12.65,12.65,0,0,1-5.19,1.06,14.54,14.54,0,0,1-5.2-.91,10.69,10.69,0,0,1-4.18-2.89,14.1,14.1,0,0,1-2.79-5,22.38,22.38,0,0,1-1-7.12H474v1.35a13.29,13.29,0,0,0,.19,2.21,6.73,6.73,0,0,0,.72,2.12,4.45,4.45,0,0,0,1.44,1.58,4.08,4.08,0,0,0,2.36.63,4,4,0,0,0,4-3.56,4.61,4.61,0,0,0-.73-2.16,11.71,11.71,0,0,0-1.49-2.17l-11.25-15.19a19.2,19.2,0,0,1-2.78-4.71,13.17,13.17,0,0,1-1-5,8.58,8.58,0,0,1,1-3.9,13.54,13.54,0,0,1,2.74-3.6,13.93,13.93,0,0,1,4.14-2.65,13.3,13.3,0,0,1,5.19-1,13.85,13.85,0,0,1,5.19,1A11.55,11.55,0,0,1,488,87.56a13.69,13.69,0,0,1,2.79,4.9,22.38,22.38,0,0,1,1,7.12h-8.47V98.14a13.32,13.32,0,0,0-.19-2.22,6.22,6.22,0,0,0-.72-2.06A4.69,4.69,0,0,0,481,92.32a4.08,4.08,0,0,0-2.36-.63,3.76,3.76,0,0,0-3,1.21,3.58,3.58,0,0,0-1.06,2.35,4.67,4.67,0,0,0,.72,2.07,16.71,16.71,0,0,0,1.49,2.26Z"
          style="fill:#616766" />
        <path
          d="M545.69,135.42V92.16c0-2,.1-4.82.1-4.82h-.2s-1.2,2.41-2.21,4l-12,20.28h-.2L519.29,91.56c-.9-1.61-2.41-4.42-2.41-4.42h-.2s.1,2.91.1,5v43.26h-13V65.16h14.46L528.83,84c.9,1.6,2.31,4.41,2.31,4.41h.2l2.41-4.51,10.54-18.77h14.45v70.26Z"
          style="fill:#00afaa" />
        <path
          d="M575.13,135.42V65.16H593.3c19.38,0,25.7,16.86,25.7,35.13s-6.32,35.13-25.7,35.13Zm18.17-12.55c8.94,0,12.65-9.93,12.65-22.58S602.24,77.7,593.3,77.7h-5.12v45.17Z"
          style="fill:#00afaa" />
        <path d="M115.87,33.54a28.89,28.89,0,0,1,40.84,0"
          style="fill:#fff;stroke:#f26724;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4.50264893247655px" />
        <path d="M121.73,39.41a20.58,20.58,0,0,1,29.11,0"
          style="fill:#fff;stroke:#f26724;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4.50264893247655px" />
        <path d="M127.33,45a12.66,12.66,0,0,1,17.91,0"
          style="fill:#fff;stroke:#f26724;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4.50264893247655px" />
      </svg>
    </div>
    <div style="font-size: 21px; float: right; margin-top: 10px">
      <span class="text-primary">EZ</span><span class="text-dark">enroll</span>
    </div>
  </div>
</div>
