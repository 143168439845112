import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientInfo } from 'src/app/models/client.model';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { AdminService } from 'src/app/services/admin.service';

export interface ClientData
{
  client: ClientInfo
  isSelected: boolean
};

@Component({
  selector: 'app-admin-clients',
  templateUrl: './admin-clients.component.html',
  styleUrls: ['./admin-clients.component.scss']
})
export class AdminClientsComponent implements OnInit {

  searchText = '';
  lockControls = false;
  allSelect = false;

  data = new Array<ClientData>();

  deleteModalDisplay = 'none';

  deleteIndex = -1;

  constructor(
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    this.refreshData();
  }

  refreshData()
  {
    this.spinner.show().then(()=>
    {
      this.adminService.getClients().then((clients: Array<ClientInfo>) =>
      {
        this.data = new Array<ClientData>();
        clients.forEach((client: ClientInfo) => this.data.push({client: client, isSelected: false}));
      }).catch((err: ErrorCodeObj) =>
      {
        console.error('Failed to query clients', err.msg);
      }).finally(() => {
        this.spinner.hide();
      });
    });
  }

  dismissModal()
  {
    this.deleteModalDisplay = 'none';

  }

  onClickDelete()
  {
    alert('Future Development');
  }

  onClickSelectAll()
  {
    this.allSelect = !this.allSelect;
    this.data.forEach((client: ClientData) =>
    {
      client.isSelected = this.allSelect;
    });

  }

  onClickContact(index: number)
  {
    alert('Future Development');
  }

  onClickEditClient(index: number)
  {
    alert('Future Development');
  }

  onClickDeleteClient(index: number)
  {
    this.deleteIndex = index;
    this.deleteModalDisplay = 'block';
  }

  onClickAdd()
  {
    alert('Future Development');
  }

  onClickSelectClient(_index: number)
  {

  }


  async fileChangeClient(event: any)
  {
    let input = event.target;
    let reader = new FileReader();
    reader.readAsText(input.files[0]);
    reader.onloadstart = () =>
    {
      this.spinner.show();
    };

    reader.onload = async() => {

      const uploadData = new Array<ClientInfo>();
      let csvData = reader.result;
      let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

      csvRecordsArray = csvRecordsArray.splice(1);

      csvRecordsArray.forEach(element => {
        if (element.length > 0) {
          const splitData = element.split(',');

          if (!!splitData[0] ) {

            const record: ClientInfo = {
              name: splitData[0],
              address: {
                addressLine1: splitData[1],
                addressLine2: splitData[2],
                city: splitData[3],
                state: splitData[4],
                zipCode: splitData[5],
              },
              contact: {
                email: splitData[6],
                phone: splitData[7],
                fax: splitData[8]
              }
            };
            uploadData.push(record);
          }
        }
      });

      const chunkSize = 1000;
      const total = uploadData.length;
      const partitions = Math.floor(total / chunkSize);

      for( let i=0; i<partitions; i++)
      {
        console.log('Uploading chunk ' + (i+1) + ' of ' + (partitions + 1));
        const start = i*chunkSize;
        const end = start + chunkSize;
        const chunk = uploadData.slice(start, end);

        console.log('Chunk', chunk);
        await this.adminService.addClients(chunk)
          .then(() => {
            console.log('Chunk ' + (i+1) + ' complete');
          }).catch((err:ErrorCodeObj) => {
            console.error(err);
          });
      }


      console.log('Uploading chunk '+ (partitions + 1) + ' of ' + (partitions + 1));

      const lastChunk = partitions > 0 ? uploadData.slice(partitions*chunkSize+1, total) : uploadData;

      console.log('Last chunk', lastChunk);
      await this.adminService.addClients(lastChunk)
      .then(() => {
        console.log('Chunk '+ (partitions + 1) + ' complete');
      }).catch((err:ErrorCodeObj) => {
        console.error(err);
      });

      input.value = null;
      //this.spinner.hide();
      this.refreshData();

    }

    reader.onerror = () =>
    {
      console.log('error uploading');
    }

    reader.onabort = () =>
    {
      console.log('reader aborted');
    }

  }

  onClickUpload()
  {
    document.getElementById('uploadClients')?.click();
  }

  deleteClient()
  {
    this.spinner.show().then(() =>
    {
      this.adminService.deleteClient(this.data[this.deleteIndex].client)
      .then(() =>
      {
        this.dismissModal();
        this.refreshData();
      }).catch((err: ErrorCodeObj) =>
      {
        console.error(err);
        this.toastr.error(err.msg);
        this.spinner.hide();
      });
    });
  }
}

