<ngx-spinner type="line-scale"></ngx-spinner>
<div class="content-admin-center">

  <div class="card" style="width: 350px; height: 550px; padding: 20px">

    <h5 class="card-header bg-white"> <img class="card-img-top" src="assets/ezmd-logo.svg" alt="logo"></h5>

    <div class="card-body mt-4">
      <h5 class="card-title text-dark text-center"><span class="text-primary">ADMIN PORTAL LOGIN</span></h5>
      <p class="card-text">Please login into the portal by requesting a one-time use security code.</p>
    </div>

    <div class="card-body mt-4">
      <form class="form-admin" [formGroup]="form">
      <div class="wrapper">
        <ngx-intl-tel-input
          [preferredCountries]="preferredCountries"
          [onlyCountries]=onlyContries
          [enableAutoCountrySelect]="true"
          [enablePlaceholder]="true"
          customPlaceholder="Mobile Phone Number"
          [searchCountryFlag]="true"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
          [selectFirstCountry]="true"
          [selectedCountryISO]="CountryISO.UnitedStates"
          [maxLength]="'15'"
          [phoneValidation]="true"
          [separateDialCode]="true"
          [numberFormat]="PhoneNumberFormat.International"
          name="phone"
          formControlName="phone"
                      >
        </ngx-intl-tel-input>
      </div>
      <div  class="container mt-4 text-center">
        <em>Your phone number must be registered prior by an account manager.</em>
        <!--
        <br>
        <a class="text-primary" style="text-decoration: underline; cursor:pointer" >Enter Code Here</a>
        -->
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col text-center">
            <div class="card-container">
              <button type="button" class="card card-lg btn bg-secondary text-white" (click)="onClickSubmit()" [disabled]="form.invalid">REQUEST</button>
            </div>
          </div>
        </div>
      </div>

      </form>
    </div>

  </div>

</div>
