import { AddressInfo, ContactInfo } from "./info.model";

export interface ClientContactInfo
{
  phone?: string;
  email?: string;
  fax?: string;
}

export interface ClientAddressInfo
{
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export interface ClientPersonContactInfo
{
  mobilePhone?: string;
  phone?: string;
  email?: string;
  fax?: string;
}

export interface ClientPersonInfo
{
  firstName: string;
  lastName: string;
}

export interface ClientInfo
{
  id?: string;
  name: string;
  address?: ClientAddressInfo;
  contact?: ClientContactInfo;
  person?: ClientPersonInfo;
}

export interface ClientPerson
{
  info?: ClientPersonInfo;
  contact?: ClientPersonContactInfo
}

export class ClientAdapter
{

  static adaptPersonInfo(response: any): ClientPersonInfo
  {
     return {
        firstName: response?.firstName,
        lastName: response?.lastName,
      } as ClientPersonInfo;
  }

  static adaptPersonContact(response: any): ClientPersonContactInfo
  {
     return {
        mobilePhone: response?.mobilePhone,
        phone: response?.phone,
        email: response?.email,
        fax: response?.fax
      } as ClientPersonContactInfo;
  }

  static adaptClientContact(response: any): ClientContactInfo
  {
     return {
        phone: response?.phone,
        email: response?.email,
        fax: response?.fax
      } as ClientContactInfo;
  }

  static adaptClientAddress(response: any): ClientAddressInfo
  {
     return {
        addressLine1: response?.addressLine1,
        addressLine2: response?.addressLine2,
        city: response?.city,
        state: response?.state,
        zipCode: response?.zipCode
      } as ClientAddressInfo;
  }

  static adaptPerson(response: any) : ClientPerson
  {
    return {
      info: response?.personalInfo ? this.adaptPersonInfo(response?.personalInfo) : undefined,
      cintact: response?.contact ? this.adaptClientContact(response?.contact) : undefined
    } as ClientPerson;
  }

  static adaptSingle(response: any) : ClientInfo
  {
    return {
      id: response.id,
      name: response.name,
      address: response?.primaryAddress ? this.adaptClientAddress(response?.primaryAddress) : undefined,
      contact: response?.primaryContact ? this.adaptClientContact(response?.primaryContact) : undefined,
      person: response?.primaryPerson ? this.adaptPerson(response?.primaryPerson) : undefined,
    } as ClientInfo;
  }

  static adaptMultiple(response: any) : Array<ClientInfo>
  {
    let clients = new Array<ClientInfo>();

    response.forEach( (item: any) => {
      clients.push(   this.adaptSingle(item) );
    });

    return  clients;
  }
}
