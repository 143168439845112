import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateStringToObject'
})
export class DateStringToObjectPipe implements PipeTransform {

  transform(dateString: string): any {

    const splits = dateString?.split("-");

    if( !!splits && (splits.length === 3))
    {
      return {
        year: parseInt(splits[0]),
        month: parseInt(splits[1]),
        day: parseInt(splits[2])
      }
    }else{
      return undefined;
    }
  }

}
