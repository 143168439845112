<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar></app-toolbar>
<div class="content" role="main">
  <ng-container class="form" [formGroup]="form">
    <div class="form-group">

      <hr class="bg-primary">
      <h3 class="text-left mt-4 text-dark">Contact Info</h3>
      <div class="text-left" style="font-size: 18px;"><strong class="text-primary">Please provide the primary address
          and contact info</strong></div>
      <hr class="bg-primary mt-4">
      <app-address-info formGroupName="addressInfo"></app-address-info>
      <app-contact-info formGroupName="contactInfo"></app-contact-info>

      <div class="container mt-5">
        <div class="row">
          <div class="col text-center">
            <div class="card-container">
              <button class="card btn card-small btn bg-secondary text-white card-small "(click)="onClickBack()">BACK</button>
              </div>
          </div>
          <div class="col text-center">
            <div class="card-container">
              <button class="card btn card-small btn bg-secondary text-white" (click)="onClickNext()" [disabled]="form.invalid">NEXT</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>
