<h3 class="text-left mt-4 text-dark">Thank you!</h3>
<div class="text-left" style="font-size: 18px;"><strong class="text-primary"> You have successfully enrolled in EZaccessMD</strong></div>
<hr class="bg-primary mt-4">

<H4 class="text-center">Check your Email</H4>
<div class="text-center text-secondary">
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg>
</div>
<div class="text-center">
  You should receive an email shortly confirming your enrollment info.
</div>



