<form autocomplete="off" [formGroup]="form">
  <div class="input-group mt-3">
    <strong class="text-dark">{{contactName}} Personal Information</strong>
  </div>
  <div class="input-group mt-2">
    <input class="form-control" placeholder="First Name" formControlName="firstName">
  </div>
  <div class="input-group mt-2">
    <input class="form-control" placeholder="Last Name" formControlName="lastName">
  </div>
  <div class="input-group mt-2">
    <input class="form-control" placeholder="Select Date of Birth" formControlName="dateOfBirth" ngbDatepicker [minDate]="{year: 1900, month: 1, day: 1}" #d="ngbDatepicker" readonly (click)="d.toggle()">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
        <i class="bi bi-calendar-plus"></i>
      </button>
    </div>
  </div>


  <div class="input-group mt-2 ml-3">
    <div class="container mt-2">
      <div class="row">

        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="genderType" name="genderType" id="genderM" value="MALE">
            <label class="form-check-label" for="genderM">Male</label>
          </div>
        </div>

        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="genderType" name="genderType" id="genderO" value="OTHER">
            <label class="form-check-label" for="genderO">Other</label>
          </div>
        </div>

      </div>

      <div class="row">

        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="genderType" name="genderType" id="genderF" value="FEMALE">
            <label class="form-check-label" for="genderF">Female</label>
          </div>
        </div>

        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="genderType" name="genderType" id="genderU" value="UNSPECIFIED">
            <label class="form-check-label" for="genderU">Unspecified</label>
          </div>
        </div>

      </div>
    </div>
  </div>
</form>
