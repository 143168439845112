import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceSort'
})
export class ServiceSortPipe implements PipeTransform {

  transform(services: string[]): string[] {

    const sorted = new Array<string>();
    const foundIndex = new Array<number>();

    // This sort is most likely temporary until backend api does it instead
    foundIndex.push(services.findIndex((val: string) => val === 'RAD'));
    foundIndex.push(services.findIndex((val: string) => val === 'LAB'));
    foundIndex.push(services.findIndex((val: string) => val === 'LAB+COVID'));
    foundIndex.push(services.findIndex((val: string) => val === 'POCD'));

    foundIndex.forEach((ind: number) =>
    {
      if( ind >= 0)
      {
        sorted.push(services[ind]);
      }
    });

    return sorted;
  }

}
