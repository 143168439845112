import { APIEndpoint, APIRequestMethod } from '../models/api.model';
import { APIDynamicKey, APIPathKey } from './consts';
import { AdminErrorCodeGenerator, RegistrationErrorCodeGenerator, SubscriptionErrorCodeGenerator } from './error';

export const API_ENDPOINTS = new Map<APIPathKey, APIEndpoint>()
    .set(
      APIPathKey.GET_REGISTRATION_TOKEN,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'auth'])
        .setRequiresAuth(false)
        .registerErrorCodeGenerator(RegistrationErrorCodeGenerator.errorRequestCode)
    )
    .set(
      APIPathKey.REGISTRATION_AUTH_SMS,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'auth', 'request'])
        .setRequiresAuth(false)
        .registerErrorCodeGenerator(RegistrationErrorCodeGenerator.errorRequestCodeSMS)

    )
    .set(
      APIPathKey.REGISTRATION_AUTH_EMAIL,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'auth', 'request'])
        .setRequiresAuth(false)
        .registerErrorCodeGenerator(RegistrationErrorCodeGenerator.errorRequestCodeEmail)
    )
    .set(
      APIPathKey.REFRESH_ACCESS_TOKEN,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'auth', 'refresh'])
        .setRequiresAuth(false)
    )
    .set(
      APIPathKey.GET_APP_ACCESS_TOKEN,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'access', 'auth'])
        .setRequiresAuth(false)
    )
    .set(
      APIPathKey.REFRESH_APP_ACCESS_TOKEN,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'access', 'auth', 'refresh'])
        .setRequiresAuth(false)
    )
    .set(
      APIPathKey.REGISTRATION_GET_PRIMARY,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['enroll', 'subscription', 'primary'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_ADD_PRIMARY,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'subscription', 'primary'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_GET_PRIMARY_INFO,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['enroll', 'subscription', 'primary', 'personalInfo'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_POST_PRIMARY_INFO,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'subscription', 'primary', 'personalInfo'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_GET_PRIMARY_ADDRESS,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['enroll', 'subscription', 'primary', 'address'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_POST_PRIMARY_ADDRESS,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'subscription', 'primary', 'address'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_GET_PRIMARY_CONTACT,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['enroll', 'subscription', 'primary', 'contact'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_POST_PRIMARY_CONTACT,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'subscription', 'primary', 'contact'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_GET_DEPENDENTS,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['enroll', 'subscription', 'dependent'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_GET_DEPENDENT,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['enroll', 'subscription', 'dependent'])
        .addDynamicPath(APIDynamicKey.SUBSCRIBER_ID, 'null')
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_POST_DEPENDENT,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'subscription', 'dependent'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_DELETE_DEPENDENT,
      new APIEndpoint(APIRequestMethod.DELETE)
        .addStaticPath(['enroll', 'subscription', 'dependent'])
        .addDynamicPath(APIDynamicKey.SUBSCRIBER_ID, 'null')
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_PUT_DEPENDENT,
      new APIEndpoint(APIRequestMethod.PUT)
        .addStaticPath(['enroll', 'subscription', 'dependent'])
        .addDynamicPath(APIDynamicKey.SUBSCRIBER_ID, 'null')
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_POST_DEPENDENT_INFO,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'subscription', 'dependent', 'personalInfo'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_POST_DEPENDENT_ADDRESS,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'subscription', 'dependent', 'address'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.REGISTRATION_POST_DEPENDENT_CONTACT,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'subscription', 'dependent', 'contact'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(RegistrationErrorCodeGenerator.errorAddDependentContact)
    )
    .set(
      APIPathKey.REGISTRATION_SUBMIT,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['enroll', 'subscription', 'submit'])
        .setRequiresAuth(true)
        .registerErrorCodeGenerator(SubscriptionErrorCodeGenerator.errorGeneric)
    )
    .set(
      APIPathKey.GET_PRE_REGISTRATION_INFO,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['enroll', 'preregistration'])
    )
    .set(
      APIPathKey.USER_AUTH_SMS,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'user', 'auth', 'request'])
        .setRequiresAuth(false)
        .registerErrorCodeGenerator(AdminErrorCodeGenerator.errorRequestCode)
    )
    .set(
      APIPathKey.USER_AUTH,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'user', 'auth'])
        .setRequiresAuth(false)
        .registerErrorCodeGenerator(AdminErrorCodeGenerator.errorLogin)
    )
    .set(
      APIPathKey.USER_AUTH_REFRESH,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'user', 'auth', 'refresh'])
        .setRequiresAuth(false)
    )
    .set(
      APIPathKey.ADMIN_GET_REGISTRATION,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['admin', 'registration'])
    )
    .set(
      APIPathKey.ADMIN_SEND_SMS,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'notify', 'enroll', 'sms'])
    )
    .set(
      APIPathKey.ADMIN_SEND_EMAIL,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'notify', 'enroll', 'email'])
    )
    .set(
      APIPathKey.ADMIN_ADD_REGISTRATION,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'registration'])
    )
    .set(
      APIPathKey.ADMIN_UPDATE_REGISTRATION,
      new APIEndpoint(APIRequestMethod.PUT)
        .addStaticPath(['admin', 'registration'])
        .addDynamicPath(APIDynamicKey.REGISTRATION_ID, 'null')
    )
    .set(
      APIPathKey.ADMIN_GET_CLIENT,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['admin', 'client'])
    )
    .set(
      APIPathKey.ADMIN_ADD_CLIENTS,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'client'])
    )
    .set(
      APIPathKey.ADMIN_DELETE_CLIENT,
      new APIEndpoint(APIRequestMethod.DELETE)
        .addStaticPath(['admin', 'client'])
        .addDynamicPath(APIDynamicKey.CLIENT_ID, 'null')
    )

    .set(
      APIPathKey.ADMIN_DELETE_REGISTRATION,
      new APIEndpoint(APIRequestMethod.DELETE)
        .addStaticPath(['admin', 'registration'])
        .addDynamicPath(APIDynamicKey.REGISTRATION_ID, 'null')
    )
    .set(
      APIPathKey.ADMIN_GET_SUBSCRIPTIONS,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['admin', 'subscription'])
    )
    .set(
      APIPathKey.ADMIN_GET_SERVICE_CODE_ZIP,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['admin', 'service', 'zipcode'])
        .addDynamicPath(APIDynamicKey.ZIPCODE, 'null')
    )
    .set(
      APIPathKey.ADMIN_ADD_ZIP_COUNTY,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'manage', 'zipcounty'])
    )
    .set(
      APIPathKey.ADMIN_ADD_SERVICE_BY_COUNTY,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'manage', 'service', 'county'])
    )
    .set(
      APIPathKey.ADMIN_GET_SERVICE_BY_COUNTY,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['admin', 'service', 'zipcode'])
        .addDynamicPath(APIDynamicKey.ZIPCODE, 'null')
    )
    .set(
      APIPathKey.ADMIN_UPDATE_SUBSCRIBERS,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'subscriber'])
    )
    .set(
      APIPathKey.ADMIN_UPDATE_REGISTATION_STATUS,
      new APIEndpoint(APIRequestMethod.PUT)
        .addStaticPath(['admin', 'registration'])
        .addDynamicPath(APIDynamicKey.REGISTRATION_ID, 'null')
        .addStaticPath(['status'])
    )
    .set(
      APIPathKey.ADMIN_UPLOAD_MEDIORBIS,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'edi', 'mediorbis', 'upload'])
    )
    .set(
      APIPathKey.ADMIN_SET_SUBSCRIBER_STATUS,
      new APIEndpoint(APIRequestMethod.POST)
        .addStaticPath(['admin', 'subscriber', 'status'])
    )

    .set(
      APIPathKey.ADMIN_GET_SUBSCRIBER_STATUS_NAMES,
      new APIEndpoint(APIRequestMethod.GET)
        .addStaticPath(['admin', 'enum', 'status', 'subscriber'])
    )
