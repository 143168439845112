<app-toolbar-admin actionName="Home"></app-toolbar-admin>
<div class="content" role="main">

  <h3 class="text-left mt-4 mb-2text-dark">Access Denied</h3>
  <div class="text-left mt-2" style="font-size: 100px;">
    <i class="fa fa-ban fa-10x text-secondary" ></i>
  </div>

  <div class="text-left mt-5" style="font-size: 18px;">
    <strong class="text-primary">Your user account does not have access to this page</strong>

  </div>
  <div class="text-left mt-5" style="font-size: 18px;">
    <em class="text-dark">Please contact the admistrator if this page is reached by mistake</em>
  </div>
  <hr class="bg-primary mt-4">


