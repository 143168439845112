<ngx-spinner type="line-scale"></ngx-spinner>
<div class="content-admin-center">

  <div class="card" style="width: 350px; height: 550px; padding: 20px">

    <h5 class="card-header bg-white"> <img class="card-img-top" src="assets/ezmd-logo.svg" alt="logo"></h5>

    <div class="card-body mt-4">
      <h5 class="card-title text-dark text-center"><span class="text-primary">ADMIN PORTAL LOGIN</span></h5>
      <p class="card-text">Please enter the 6-digit security code that you received.</p>
    </div>

    <div class="card-body mt-4">
      <form class="form-admin" [formGroup]="form">
      <div  class="text-center">
        <input class="form-control text-center" placeholder="Security Code" formControlName="code">
      </div>
      <div  class="container mt-4 text-center">
        <em>Need a new code?</em>
        <br>
        <a class="text-primary" style="text-decoration: underline; cursor:pointer" (click)="onClickRequestNewCode()">Request New Code Here</a>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col text-center">
            <div class="card-container">
              <button type="button" class="card card-lg btn bg-secondary text-white" (click)="onClickLogin()" [disabled]="form.invalid">LOGIN</button>
            </div>
          </div>
        </div>
      </div>

      </form>
    </div>

  </div>

</div>
