import { Injectable } from '@angular/core';
import { APIDynamicKey, APIPathKey } from '../config/consts';
import { API_ENDPOINTS } from '../config/routes';
import { EndpointArg, GenericAPIResp } from '../models/api.model';
import { ErrorCodeObj } from '../models/error.model';
import { Registration, RegistrationAdapter } from '../models/registration.model';
import { ApiService } from './api.service';
import * as HttpStatus from 'http-status-codes';
import { HttpParams } from '@angular/common/http';
import { Subscriber, Subscription, SubscriptionAdapater } from '../models/subscription.model';
import { ClientAdapter, ClientInfo } from '../models/client.model';
import { ServiceSearchResults, ServiceServicesInfo, UploadCountyService, ZipCountyMap } from '../models/service.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private api: ApiService
  ) { }


  async getSubscriptions(): Promise<Array<Subscription>> {


    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_GET_SUBSCRIPTIONS);

    return new Promise<Array<Subscription>>((resolve: (subs: Array<Subscription>) => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) => {

        resolve(SubscriptionAdapater.adaptMultiple(resp.body));

      }).catch((badResp: GenericAPIResp) => {

        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  async getRegistrations(): Promise<Registration[] | null> {


    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_GET_REGISTRATION);

    return new Promise<Registration[] | null>((resolve: (reg: Registration[] | null) => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) => {

        resolve(RegistrationAdapter.adaptMultiple(resp.body));

      }).catch((badResp: GenericAPIResp) => {

        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  async addRegistration(reg: Registration): Promise<void> {


    const epAdd = API_ENDPOINTS.get(APIPathKey.ADMIN_ADD_REGISTRATION);
    const epClient = API_ENDPOINTS.get(APIPathKey.ADMIN_GET_CLIENT)
      ?.setParams(new HttpParams().set('name', reg.clientName));

    const bodyClient =
    {
      name: reg.clientName
    };

    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(epClient, bodyClient).then((resp: GenericAPIResp) => {

        if (resp.body.length == 1) {
          const clientId = resp.body[0].id;

          const bodyAdd =
          {
            firstName: reg?.firstName,
            lastName: reg?.lastName,
            email: reg.email,
            phoneNumber: reg.phoneNumber,
            startDate: reg?.startDate,
            endDate: reg?.endDate,
            clientId: clientId
          };

          this.api.submitRequest(epAdd, bodyAdd).then((resp: GenericAPIResp) => {
            resolve();
          }).catch((badResp: GenericAPIResp) => {

            if (epAdd !== undefined) {
              reject(epAdd.errorCodeGenerator(badResp));
            } else {
              reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
            }
          });
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'No client match'));
        }
      }).catch((badResp: GenericAPIResp) => {

        if (epClient !== undefined) {
          reject(epClient.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  async updateRegistration(reg: Registration): Promise<void> {

    if (!!reg.id) {

      const epUpdate = API_ENDPOINTS.get(APIPathKey.ADMIN_UPDATE_REGISTRATION)?.evalDynamicPath([new EndpointArg(APIDynamicKey.REGISTRATION_ID, reg.id)]);
      const epClient = API_ENDPOINTS.get(APIPathKey.ADMIN_GET_CLIENT)
        ?.setParams(new HttpParams().set('name', reg.clientName));

      const bodyClient =
      {
        name: reg.clientName
      };

      return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {
        this.api.submitRequest(epClient, bodyClient).then((resp: GenericAPIResp) => {

          if (resp.body.length == 1) {
            const clientId = resp.body[0].id;

            const bodyUpdate =
            {
              firstName: reg?.firstName,
              lastName: reg?.lastName,
              email: reg.email,
              phoneNumber: reg.phoneNumber,
              startDate: reg?.startDate,
              endDate: reg?.endDate,
              clientId: clientId
            };

            this.api.submitRequest(epUpdate, bodyUpdate).then((resp: GenericAPIResp) => {
              resolve();
            }).catch((badResp: GenericAPIResp) => {

              if (epUpdate !== undefined) {
                reject(epUpdate.errorCodeGenerator(badResp));
              } else {
                reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
              }
            });
          } else {
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'No client match'));
          }
        }).catch((badResp: GenericAPIResp) => {

          if (epClient !== undefined) {
            reject(epClient.errorCodeGenerator(badResp));
          } else {
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });
      });
    } else {
      Promise.reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'missing regirtation id'));
    }
  }

  async deleteRegistration(regId: string): Promise<void> {

    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_DELETE_REGISTRATION)
      ?.evalDynamicPath([new EndpointArg(APIDynamicKey.REGISTRATION_ID, regId)]);


    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) => {
        resolve();
      }).catch((badResp: GenericAPIResp) => {

        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }


  async sendSMS(regId: string): Promise<void> {


    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_SEND_SMS);
    const body =
    {
      registrationId: regId
    }

    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
        resolve();
      }).catch((badResp: GenericAPIResp) => {

        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  async sendEmail(regId: string): Promise<void> {


    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_SEND_EMAIL);
    const body =
    {
      registrationId: regId
    }

    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
        resolve();
      }).catch((badResp: GenericAPIResp) => {

        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  async getServiceCodeByZipCode(zipCode: string): Promise<string | null> {


    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_GET_SERVICE_CODE_ZIP)?.evalDynamicPath([new EndpointArg(APIDynamicKey.ZIPCODE, zipCode)]);

    return new Promise<string | null>((resolve: (reg: string | null) => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) => {

        if (resp.statusCode === HttpStatus.StatusCodes.OK) {
          resolve(resp.body);
        } else {
          resolve(null);
        }

      }).catch((badResp: GenericAPIResp) => {

        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  async getClients(): Promise<Array<ClientInfo>> {

    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_GET_CLIENT)?.setParams(new HttpParams());

    return new Promise<Array<ClientInfo>>((resolve: (clietns: Array<ClientInfo>) => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) => {
        resolve(ClientAdapter.adaptMultiple(resp.body));
      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }


  async addClients(clients: Array<ClientInfo>): Promise<void> {

    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_ADD_CLIENTS);

    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {
      const body =
      {
        clients: clients
      };

      this.api.submitRequest(ep, body).then((_resp: GenericAPIResp) => {
        resolve();
      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  async deleteClient(client: ClientInfo): Promise<void> {

    if( !!client.id )
    {
      const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_DELETE_CLIENT)
        ?.evalDynamicPath([new EndpointArg(APIDynamicKey.CLIENT_ID, client.id)]);

      return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {

        this.api.submitRequest(ep).then((_resp: GenericAPIResp) => {
          resolve();
        }).catch((badResp: GenericAPIResp) => {
          if (ep !== undefined) {
            reject(ep.errorCodeGenerator(badResp));
          } else {
            reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
          }
        });
      });
    }else
    {
      return Promise.reject( new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Missing client id'));
    }
  }

  async getSubscriberStatusNames(): Promise<Array<string>> {

    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_GET_SUBSCRIBER_STATUS_NAMES);

    return new Promise<Array<string>>((resolve: (clietns: Array<string>) => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) => {

        const names = new Array<string>();
        resp.body.forEach((element:any, index: number) => {
            names.push(element?.name);
            if( index === (resp.body.length-1) )
            {
              resolve(names);
            }
        })


      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  async updateSubscribers(subs: Array<Subscriber>, setOverrideStatus: boolean = true): Promise<void> {

    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_UPDATE_SUBSCRIBERS);

    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {

      const body = {
        subscribers: subs,
        setOverrideStatus: setOverrideStatus
      };

      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
        resolve();
      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });
    });

  }

  async addZipCountyMap(map: Array<ZipCountyMap>): Promise<void> {
    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_ADD_ZIP_COUNTY);

    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {

      const body = {
        map: map
      };

      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
        resolve();
      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });

    });

  }


  async addServiceByCounty(map: Array<UploadCountyService>): Promise<void> {
    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_ADD_SERVICE_BY_COUNTY);

    return new Promise<void>((resolve: () => void, reject: (code: ErrorCodeObj) => void) => {

      const body = {
        map: map
      };

      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
        resolve();
      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });

    });

  }

  async getServicesByZipCode(zipCode: string): Promise<ServiceSearchResults> {

    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_GET_SERVICE_BY_COUNTY)?.evalDynamicPath([new EndpointArg(APIDynamicKey.ZIPCODE, zipCode)]);

    return new Promise<ServiceSearchResults>((resolve: (result: ServiceSearchResults) => void, reject: (code: ErrorCodeObj) => void) => {
      this.api.submitRequest(ep).then((resp: GenericAPIResp) => {

        resolve(resp.body as ServiceSearchResults);
      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });

    });
  }

  async updateRegistrationStatus(id: string, status: string): Promise<void> {

    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_UPDATE_REGISTATION_STATUS)
      ?.evalDynamicPath([new EndpointArg(APIDynamicKey.REGISTRATION_ID, id)]);

    return new Promise<void>((resolve: (result: void) => void, reject: (code: ErrorCodeObj) => void) => {

      const body =
      {
        status: status
      };

      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
        resolve();
      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });

    });
  }

  async uploadMediorbis( subscribers: Subscriber[], activate: boolean) : Promise<void>
  {

    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_UPLOAD_MEDIORBIS);

    return new Promise<void>((resolve: (result: void) => void, reject: (code: ErrorCodeObj) => void) => {

      const info = new Array<any>();

      subscribers.forEach((sub: Subscriber) =>
      {
        info.push(
          {
            id: sub.info.id,
            status: activate ? 'ACTIVE' : 'INACTIVE'
          }
        );
      });

      const body =
      {
        subscribers: info
      };

      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
        resolve();
      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });

    });
  }

  async setSubscriberStatus( subscribers: Subscriber[], status: string) : Promise<void>
  {

    const ep = API_ENDPOINTS.get(APIPathKey.ADMIN_SET_SUBSCRIBER_STATUS);

    return new Promise<void>((resolve: (result: void) => void, reject: (code: ErrorCodeObj) => void) => {

      const info = new Array<any>();

      subscribers.forEach((sub: Subscriber) =>
      {
        info.push(
          {
            id: sub.info.id,
            status: status
          }
        );
      });

      const body =
      {
        subscribers: info
      };

      this.api.submitRequest(ep, body).then((resp: GenericAPIResp) => {
        resolve();
      }).catch((badResp: GenericAPIResp) => {
        if (ep !== undefined) {
          reject(ep.errorCodeGenerator(badResp));
        } else {
          reject(new ErrorCodeObj(HttpStatus.StatusCodes.BAD_REQUEST, 'Bad endpoint'));
        }
      });

    });
  }
}
