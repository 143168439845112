<div class="input-group mt-3">
  <strong class="text-dark">Relationship to Primary Member</strong>
</div>

<form autocomplete="off" [formGroup]="form">
<div class="input-group mt-3">
  <div class="container">
    <div class="row">
      <div class="col text-center">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="memberType" id="typeSpouse" value="SPOUSE" formControlName="memberType">
          <label class="form-check-label" for="typeSpouse">Spouse</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="memberType" id="typeChild" value="CHILD" formControlName="memberType">
          <label class="form-check-label" for="typeChild">Child</label>
        </div>

      </div>
    </div>
  </div>
</div>
</form>
