import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit {

  companyConfirm: boolean = false;

  employerNames = [
    "NRG Experimental LLC"
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
