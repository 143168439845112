import { HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { API_CONFIG } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ErrorCodeObj } from './error.model';
import { APIPathKey } from '../config/consts';
import { ERROR_MESSAGES } from '../config/error';
import * as HttpStatus from 'http-status-codes';

export interface GenericAPIResp {
    statusCode: number;
    error: boolean;
    body: any;
    handled: boolean;
}

export enum APIRequestMethod {
    POST = 'POST',
    PUT = 'PUT',
    GET = 'GET',
    DELETE = 'DELETE'
}

export class ResponseMessage {
    constructor(
        public error: boolean,
        public msg: string) {}
}

export class APIResponseMessage extends ResponseMessage {

    constructor(
        error: boolean,
        msg: string,
        public body: any,
    ) {
        super(error, msg);
    }
}


export class EndpointPath {

    pathKey: string;
    defaultValue: string | undefined;
    isDyanmic = false;
    currentValue: string;

    constructor(pathKey: string, defaultValue?: string | null, isDynamic?: boolean) {

        this.pathKey = pathKey;

        if (defaultValue) {
            this.defaultValue = defaultValue;
            this.currentValue = defaultValue;
        } else {
            this.currentValue = pathKey;
        }

        if (isDynamic || defaultValue) {
            this.isDyanmic = true;
        }
    }
}

export class EndpointArg {

    constructor(public key: string, public value: string, public pathIndex: number = -1) { }
}


export class APIEndpoint {
    apiBase: APIBase;
    method: APIRequestMethod;
    pathItems = Array<EndpointPath>();
    params: HttpParams |  undefined;
    errorCodeGenerator: ((response: GenericAPIResp) => ErrorCodeObj);// | undefined = undefined;
    mockFunction: ((request: HttpRequest<any>) => Observable<HttpResponse<any>>) | undefined = undefined;
    requiresAuth = true;

    constructor(method: APIRequestMethod, base?: APIBase) {
        if (!base) {
            this.apiBase = API_CONFIG as APIBase;
        } else {
            this.apiBase = base;
        }
        this.method = method;

        this.errorCodeGenerator = this.genericErrorCodeGenerator;
    }

    static getBaseUrl(): string {
            return API_CONFIG.protocol + '://' + API_CONFIG.url + '/' + API_CONFIG.path + '/' + API_CONFIG.version;
    }

    stripVersion(): APIEndpoint {
        this.apiBase.version = undefined;
        return this;
    }

    setRequiresAuth(required: boolean): APIEndpoint {
        this.requiresAuth = required;
        return this;
    }

    addStaticPath(pathName: (string | Array<string>)): APIEndpoint {
        if (typeof pathName === 'string') {
            pathName = [pathName];
        }
        pathName.forEach((path: string) => {
            this.pathItems.push(new EndpointPath(path));
        });

        return this;
    }

    addDynamicPath(pathName: string, defaultValue: string | undefined) {
        if(defaultValue !== undefined ){
          this.pathItems.push(new EndpointPath(pathName, defaultValue, true));
        }
        return this;
    }

    evalDynamicPath(args: Array<EndpointArg>): APIEndpoint {

        let success = true;

        // Copy endpoint
        const ep = new APIEndpoint(this.method, this.apiBase);
        ep.errorCodeGenerator = this.errorCodeGenerator;
        ep.mockFunction = this.mockFunction;
        this.pathItems.forEach((item: EndpointPath) => {
            if (item.isDyanmic) {
                ep.addDynamicPath(item.pathKey, item.defaultValue);
            } else {
                ep.addStaticPath(item.pathKey);
            }
        });

        args.forEach((arg: EndpointArg) => {
            if (success) {
                const found = ep.pathItems.find((item: EndpointPath, index: number) => {
                    return (item.pathKey === arg.key) && (item.isDyanmic) && (arg.pathIndex < 0 || index === arg.pathIndex);
                });

                if (found) {
                    found.currentValue = encodeURIComponent(arg.value);
                } else {
                    success = false;
                }
            }
        });


        return ep;
    }

    setParams(params: HttpParams): APIEndpoint{
        this.params = params;
        return this;
    }

    getParams(): HttpParams | undefined{
        return this.params;
    }

    toString(base?: APIBase) {
        let urlBase = this.apiBase;

        if (!!base) {
            urlBase = base as APIBase;
        }

        let fullURL = urlBase.protocol + '://';
        fullURL += urlBase.url + '/' + urlBase.path + '/';
        fullURL += (urlBase.version) ? urlBase.version + '/' : '';

        this.pathItems.forEach((item: EndpointPath) => {
            fullURL += item.currentValue + '/';
        });

        if (fullURL.charAt(fullURL.length - 1) === '/') {
            fullURL = fullURL.substr(0, fullURL.length - 1);
        }

        return fullURL;
    }

    registerMockFunction(fun: any): APIEndpoint{
        this.mockFunction = fun;
        return this;
    }

    registerErrorCodeGenerator(gen: (response: GenericAPIResp) => ErrorCodeObj ): APIEndpoint {
        this.errorCodeGenerator = gen;
        return this;
    }

    genericErrorCodeGenerator(resp: GenericAPIResp): ErrorCodeObj {
      return ErrorCodeObj.generate(0, ERROR_MESSAGES.GENERIC);;
  }


    static getRegexPattern(ep: APIEndpoint): RegExp {
        let pattern = '';

        ep.pathItems.forEach((pathItem:EndpointPath ) => {

            if( pathItem.isDyanmic ) {
                //pattern += '\\/\\S+'
                pattern += '\\/((?!\\\/).)+';
            }else {
                pattern += '\\\/' + pathItem.currentValue;
            }
        });

        return new RegExp(pattern+'$');
    }

    static matchEndpointFromRequest(request: HttpRequest<any>, routes: Map<APIPathKey, APIEndpoint>): APIEndpoint {
        const strippedUrl = request.url.replace(this.getBaseUrl(), '');
        const matches = Array.from(routes.values()).filter((ep: APIEndpoint) =>
           (APIEndpoint.getRegexPattern(ep).test(strippedUrl) && (ep.method === request.method)) );

        // Assume the first match
        let matchEp = matches[0];

        if( matches.length > 1 ) {
            // Look for an extact match
            const extactIndex = matches.map((ep: APIEndpoint) => ep.toString().replace(this.getBaseUrl(), '') === strippedUrl )
                .findIndex((val: boolean)=>val);

            // TODO: Can still fail and have (2) matches if both are variable patterns
            if( extactIndex >= 0 ) {
                matchEp = matches[extactIndex];
            }else {

            }
        }
        return matchEp;
    }

}

export interface APIBase {
    protocol: string;
    url: string;
    path: string;
    version: string | undefined;
}

export interface QueryParam {
    key: string;
    value: string;
}

