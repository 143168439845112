import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  onlyContries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Canada]
	form = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  async onClickSubmit()
  {
    const phoneNumber = this.form.get('phone')?.value;


    if( !!phoneNumber)
    {
      const e164 = phoneNumber.e164Number;

      this.spinner.show().then(()=>
      {
        this.authService.requestUserTokenCode(e164).then(()=>{
          this.router.navigate(['admin/login/code'], {queryParams: {phoneNumber: e164}})
        }).catch((err: ErrorCodeObj ) =>{
          console.error(err);
          this.toastr.error(err.msg);

        }).finally(()=>{
          this.spinner.hide();
        });
      });
    }

  }

}
