<ngx-spinner type="line-scale"></ngx-spinner>
<app-toolbar-admin actionName="Enrollment"></app-toolbar-admin>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':datesModal}" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Change Enrollment Dates</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="input-group mt-2">
          <input class="form-control" placeholder="Start Enrollment Date" ngbDatepicker [minDate]="{year: 2020, month: 1, day: 1}" #d="ngbDatepicker" readonly (click)="d.toggle()" [(ngModel)]="bulkStartDate">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
              <i class="bi bi-calendar-plus"></i>
            </button>
          </div>
        </div>

        <div class="input-group mt-2">
          <input class="form-control" placeholder="End Enrollment Date" ngbDatepicker [minDate]="{year: 2020, month: 1, day: 1}" #e="ngbDatepicker" readonly (click)="e.toggle()" [(ngModel)]="bulkEndDate">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="e.toggle()" type="button">
              <i class="bi bi-calendar-plus"></i>
            </button>
          </div>
        </div>
        <div class="input-group mt-2">

          <p>You are about to set enroll dates for ({{ editCount }}) subscriptions!</p>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="setEnrollmentDates()">Update</button>
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':updatePrimaryModal}" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Primary Info </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container class="form" [formGroup]="form">
          <div class="form-group">

            <div class="form-group row">
              <label for="clientName" class="col-sm-4 col-form-label">Client Name</label>
              <div class="col-sm-8">
                <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                  <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">{{!!selectedCopyPrimary?.client?.name ? selectedCopyPrimary?.client?.name : 'Select Client'}}</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownManual">
                    <button *ngFor="let client of clients; index as i" ngbDropdownItem (click)="onClickClientName(client.name)">{{client.name}}</button>
                  </div>
                </div>
              </div>
            </div>

            <app-personal-info formGroupName="info"></app-personal-info>
            <app-address-info formGroupName="address" googleId="addressPrimSub"></app-address-info>
            <app-contact-info formGroupName="contact"></app-contact-info>

          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onClickSavePrimary()">Save</button>
        <button type="button" class="btn btn-primary" (click)="onClickManageDependents()">Manage Dependents</button>
        <button type="button" class="btn btn-secondary" (click)="onClickCancelUpdatePrimary()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':updateDependentModal}" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Dependent Info </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container class="form" [formGroup]="form">
          <div class="form-group">
            <div class="form-group row">
              <label for="clientName" class="col-sm-4 col-form-label">Client Name</label>
              <div class="col-sm-8">
                <div class="d-inline-block" ngbDropdown #myDropDep="ngbDropdown">
                  <button class="btn btn-outline-primary mr-2" id="dropdownManualDep" ngbDropdownAnchor (focus)="myDropDep.open()">{{!!selectedCopyDependent?.client?.name ? selectedCopyDependent?.client?.name : 'Select Client'}}</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownManualDep">
                    <button *ngFor="let client of clients; index as i" ngbDropdownItem (click)="onClickClientNameDep(client.name)">{{client.name}}</button>
                  </div>
                </div>
              </div>
            </div>

            <app-personal-info contactName="Dependent" formGroupName="info"></app-personal-info>
            <app-address-info contactName="Dependent" formGroupName="address" [primaryAddress]="selectedCopyPrimary?.address" googleId="addressDepSub"></app-address-info>
            <app-contact-info contactName="Dependent" formGroupName="contact" [primaryContact]="selectedCopyPrimary?.contact"></app-contact-info>

          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onClickSaveDependent(returnToManageDep)">Save</button>
        <button type="button" class="btn btn-secondary" (click)="onClickCancelUpdateDependent(returnToManageDep)">Cancel</button>
      </div>
    </div>
  </div>
</div>


<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':manageDependentsModal}" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Manage Dependents</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container class="form" [formGroup]="form">
          <div class="form-group">
            <div *ngIf="selectedCopyDependents.length > 0"class="table-wrap">

              <table class="table table-borderless table-striped Dependent-table">
                <tbody>
                <tr *ngFor="let dependent of selectedCopyDependents; index as i">
                  <td>{{ i + 1 }}</td>
                  <td>
                    {{ dependent.info.lastName }}
                  </td>
                  <td>
                    {{ dependent.info.firstName }}
                  </td>
                  <td>{{ dependent.info.type }}</td>
                  <td (click)="onClickEditDependentById(dependent.info.id)">
                    <i class="fa fa-edit"></i>
                  </td>
                  <td (click)="onClickDeleteDependentById(dependent.info.id)">
                    <i class="fa fa-trash"></i>
                    </td>
                </tr>
                </tbody>
              </table>
              </div>

              <div  *ngIf="selectedCopyDependents.length === 0"class="text-center mt-5 mb-5" style="font-size: 18px;"><strong class="text-dark">You currently have no dependents enrolled</strong></div>
            <div class="card-container">
              <button type="button" class="btn bg-primary text-white" tabindex="0" (click)="onClickAddDependent()">
                Add Dependent
              </button>
            </div>

          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onClickSaveManageDependent(true)">Save</button>
        <button type="button" class="btn btn-secondary" (click)="onClickCancelManageDependent()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':activateModal}" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Activate Subscribers in Mediorbis</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="input-group mt-2">
          <p>You are about to <span>activate</span> ({{ activateCount }}) subscribers in Mediorbis!</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="activateMediorbis()">Activate</button>
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':deactivateModal}" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Deactivate Subscribers in Mediorbis</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="input-group mt-2">
          <p>You are about to <strong>deactive</strong> ({{ deactivateCount }}) subscribers in Mediorbis!</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="deactivateMediorbis()">Deactivate</button>
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':overrideStatusModal}" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Override Enrollment Status</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group">

          <div class="form-group row">
            <label for="clientName" class="col-sm-4 col-form-label">Enrollment Status</label>
            <div class="col-sm-8">
              <div class="d-inline-block" ngbDropdown #myDropStatus="ngbDropdown">
                <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor (focus)="myDropStatus.open()">{{selectedStatusName}}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                  <button *ngFor="let status of subscriberStatusNames; index as i" ngbDropdownItem (click)="onClickStatus(status)">{{status}}</button>
                </div>
              </div>
            </div>
          </div>
          </div>

          <div class="input-group mt-2">
            <p>You are about to <strong>override</strong> ({{ selectionCount }}) enrollment status!</p>
          </div>
        </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="overrideStatus()">Override</button>
        <button type="button" class="btn btn-secondary" (click)="dismissModal()">Cancel</button>
      </div>
    </div>
  </div>
</div>




<div class="" style="margin:auto; margin-top: 20px; margin-bottom:20px; width: 400px">
    <input type="text" placeholder="Search..." [(ngModel)]="searchText" style="width: 100%;">
</div>

<div class="table-wrap">

  <table class="table table-borderless table-striped Dependent-table">
    <thead>
    <tr>
      <th scope="col" style="width: 5%"><input type="checkbox" (click)="onClickSelectAll()" [checked]="allSelect"></th>
      <th scope="col" style="width: 5%" *ngIf="!expandAll" scope="row"><button class="text-dark" (click)="onClickExpandAll(true)"><i class="fa fa-chevron-right"></i></button></th>
      <th  scope="col" style="width: 5%" *ngIf="expandAll" scope="row"><button class="text-dark" (click)="onClickExpandAll(false)"><i class="fa fa-chevron-down"></i></button></th>
      <th scope="col">Actions</th>
      <th scope="col">Dependents</th>
      <th scope="col">Registration Status</th>
      <th scope="col">Enrollment Status</th>
      <th scope="col">Client</th>

      <!--<th scope="col">Count</th>-->
      <th scope="col">Type</th>
      <th scope="col">PXRCO Services</th>
      <th scope="col">Last Name</th>
      <th scope="col">First Name</th>
      <th scope="col">Gender</th>
      <th scope="col">DOB</th>
      <th scope="col">Email</th>
      <th scope="col">Phone Type</th>
      <th scope="col">Phone</th>
      <th scope="col">Benefit Start</th>
      <th scope="col">Benefit End</th>
      <th scope="col">Address</th>
    </tr>
    </thead>

    <tbody *ngFor="let item of data | subscriberGroupSort : searchText; index as i">
      <tr >
        <td scope="row"><input type="checkbox" (click)="onClickBox(i)" [checked]="item.primary.isSelected"></td>
        <td scope="row"><strong>{{ i + 1 }}</strong></td>
        <td style="padding: 0">
          <div class="row">
            <div class="col text-center text-dark"
              style="font-size: 25px; padding-top: 7px; padding-left: 5px; padding-right: 5px;">
              <i class="fa fa-edit" (click)="onClickEditPrimary(i)" style="cursor: pointer;"></i>
            </div>
            <div class="col text-center text-dark"
              style="font-size: 25px; padding-top: 7px; padding-left: 5px; padding-right: 5px;">
              <i *ngIf="item.primary.canDelete" class="fa fa-trash" (click)="onClickDeletePrimary(i)" style="cursor: pointer;"></i>
            </div>
          </div>
        </td>
        <td *ngIf="(!item.primary.isExpanded && item.dependents.length >0)" scope="row"><button class="text-dark" (click)="onClickExpand(i,true)"><span class="depCount">{{item.dependents.length}}</span><i class="fa fa-chevron-right"></i></button></td>
        <td *ngIf="(item.primary.isExpanded  && item.dependents.length >0)" scope="row"><button class="text-dark" (click)="onClickExpand(i,false)"><span class="depCount">{{item.dependents.length}}</span><i class="fa fa-chevron-down"></i></button></td>
        <td *ngIf="item.dependents.length==0" scope="row"><span class="depCount">0</span></td>

        <td>{{ item.reg.status }}</td>
        <td>{{ item.primary?.subscriber?.service?.status }}</td>
        <td>{{ item.primary?.subscriber?.client?.name }}</td>

        <!--<td>{{ item.sub.dependents.length + 1}}</td>-->
        <td>SELF</td>
        <td>{{ SubscriptionAdapter.adaptSubTags(item.primary?.subscriber?.service) }}</td>
        <td>{{ item.primary?.subscriber?.info?.lastName }}</td>
        <td>{{ item.primary?.subscriber?.info?.firstName }}</td>
        <td>{{ item.primary?.subscriber?.info?.genderType }}</td>
        <td>{{ item.primary?.subscriber?.info?.dateOfBirth }}</td>
        <td>{{ item.primary?.subscriber?.contact?.email }}</td>
        <td>{{ !!item.primary?.subscriber?.contact?.mobilePhone ? 'MOBILE': 'OTHER' }}</td>
        <td>{{ !!item.primary?.subscriber?.contact?.mobilePhone ? item.primary?.subscriber?.contact?.mobilePhone : item.primary?.subscriber?.contact?.phone }}</td>

        <td>{{ item.primary?.subscriber?.service?.startDate | date : 'short' }}</td>
        <td>{{ item.primary?.subscriber?.service?.endDate | date : 'short' }}</td>
        <td>{{ item.primary?.subscriber?.address?.addressLine1 + ',' + item.primary?.subscriber?.address?.city + ',' + item.primary?.subscriber?.address?.state + ',' + item.primary?.subscriber?.address?.zipCode }}</td>
      </tr>

      <tr *ngFor="let dep of item.dependents | expandedDependents : item.primary.isExpanded; index as j">
        <td scope="row"><input type="checkbox" (click)="onClickDependentBox(i,j)" [checked]="dep.isSelected"></td>
        <td></td>
        <td style="padding: 0">
          <div class="row">
            <div class="col text-center text-dark"
              style="font-size: 25px; padding-top: 7px; padding-left: 5px; padding-right: 5px;">
              <i class="fa fa-edit" (click)="onClickEditDependent(i,j)" style="cursor: pointer;"></i>
            </div>
            <div class="col text-center text-dark"
              style="font-size: 25px; padding-top: 7px; padding-left: 5px; padding-right: 5px;">
              <i *ngIf="dep.canDelete" class="fa fa-trash" (click)="onClickDeleteDependent(i,j)" style="cursor: pointer;"></i>
            </div>
          </div>
        </td>
        <td><em>{{String.fromCharCode(65+j) }}</em></td>
        <td>{{ item.reg.status }}</td>
        <td>{{ dep.subscriber.service.status }}</td>
        <td>{{ dep.subscriber.client.name }}</td>
        <td>{{ dep.subscriber.info.type }}</td>
        <td>{{ SubscriptionAdapter.adaptSubTags(dep.subscriber.service) }}</td>
        <td>{{ dep.subscriber.info.lastName }}</td>
        <td>{{ dep.subscriber.info.firstName }}</td>
        <td>{{ dep.subscriber.info.genderType }}</td>
        <td>{{ dep.subscriber.info.dateOfBirth }}</td>
        <td>{{ dep.subscriber.contact.email }}</td>
        <td>{{ !!dep.subscriber.contact.mobilePhone ? 'MOBILE': 'OTHER' }}</td>
        <td>{{ !!dep.subscriber.contact.mobilePhone ? dep.subscriber.contact.mobilePhone: dep.subscriber.contact.phone }}</td>
        <td>{{ dep.subscriber.service?.startDate | date : 'short' }}</td>
        <td>{{ dep.subscriber.service?.endDate | date : 'short' }}</td>
        <td>{{ dep?.subscriber?.address?.addressLine1 + ',' + dep?.subscriber?.address?.city + ',' + dep?.subscriber?.address?.state + ',' + dep?.subscriber?.address?.zipCode }}</td>
      </tr>
    </tbody>
  </table>
  </div>

  <div class="container mt-5">
    <div class="row">

      <div class="col text-center">
        <div class="card-container">
          <button class="card card-small bg-light text-dark btn" (click)="exportToCSV()"><i class="fa fa-file"></i>EXPORT</button>
          </div>
      </div>

      <div class="col text-center">
        <div class="card-container">
          <button class="card card-small bg-light text-dark btn" (click)="onClickSetDates()" [disabled]="lockControls"><i class="fa fa-calendar"></i>SET DATES</button>
          </div>
      </div>

      <div class="col text-center">
        <div class="card-container">
          <button class="card card-small bg-light text-dark btn" (click)="onClickSetStatus()" [disabled]="lockControls"><i class="fa fa-pencil"></i>SET STATUS</button>
          </div>
      </div>

      <div class="col text-center">
        <div class="card-container">
          <button class="card card-small bg-light text-dark btn" (click) = "onClickActivateMediorbis()" [disabled]="lockControls"><i class="fa fa-check"></i>ACTIVATE</button>
          </div>
      </div>

      <div class="col text-center">
        <div class="card-container">
          <button class="card card-small bg-light text-dark btn" (click) = "onClickDeactivateMediorbis()" [disabled]="lockControls"><i class="fa fa-ban"></i>DEACTIVATE</button>
          </div>
      </div>

    </div>
  </div>



