import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as HttpStatus from 'http-status-codes';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { Subscriber, SubscriberAddress, SubscriberContact } from 'src/app/models/subscription.model';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-ezenroll-dependent-contact-info',
  templateUrl: './ezenroll-dependent-contact-info.component.html',
  styleUrls: ['./ezenroll-dependent-contact-info.component.scss']
})
export class EzenrollSubscriberContactInfoComponent implements OnInit {

  form: FormGroup;
  dependentId!: string;
  dependent!: Subscriber;
  primary!: Subscriber;
  loading = true;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    this.form = this.fb.group(
      {
        addressInfo: this.fb.group(
          {
            usePrimaryAddress: [{value: false, disabled: false}],
            addressLine1: [{value: '', disabled: false}, Validators.required],
            city: [{value: '', disabled: false}, Validators.required],
            state: [{value: '', disabled: false}, Validators.required],
            zipCode: [{value: '', disabled: false},  [Validators.required, Validators.pattern('[0-9]{5}')]]
          }
        ),
        contactInfo: this.fb.group(
          {
            usePrimaryPhone: [{value: false, disabled: false}],
            phoneNumber: [{value: '', disabled: false}],
            phoneType: [{value: 'MOBILE', disabled: false}, Validators.required],
            email: [{value: '', disabled: false}, [Validators.email]]
          }
        ),
      }
    );
   }

  ngOnInit(): void {

    this.spinner.show().then(() =>
    {
      this.dependentId = this.route.snapshot.queryParams?.did;

      Promise.all(
        [
          this.subscriptionService.getPrimary(false),
          this.subscriptionService.getDependent(this.dependentId, false)
        ]).then((values: any[]) =>
        {
          this.primary = values[0] as Subscriber;
          this.dependent = values[1] as Subscriber;

          let phoneType = 'MOBILE';
          if( !!this.dependent.contact?.mobilePhone  || !!this.dependent.contact?.phone )
          {
            phoneType = !!this.dependent.contact?.mobilePhone ? 'MOBILE': 'OTHER'
          }
        this.form.patchValue(
          {
            addressInfo:
            {
              addressLine1:  this.dependent.address?.addressLine1,
              city: this.dependent.address?.city,
              state: this.dependent.address?.state,
              zipCode: this.dependent.address?.zipCode
            },
            contactInfo:
            {
              phoneNumber: !!this.dependent.contact.mobilePhone ? this.dependent.contact.mobilePhone?.substring(2) : this.dependent.contact?.phone?.substring(2),
              phoneType: phoneType,
              email: this.dependent.contact?.email
            }
          }
        );
      }).catch((err: ErrorCodeObj) =>
        {
        console.error(err);
        if( err.statusCode === HttpStatus.StatusCodes.FORBIDDEN)
        {
          this.router.navigate(['enroll']);
        }else{
          this.toastr.error(err.msg);
        }
      }).finally(()=>{
        this.spinner.hide();
        this.loading = false;
      });
    });

  }

  onClickAdd()
  {
    this.spinner.show().then(() =>
    {
      const dependentId = this.route.snapshot.queryParams?.did;

      const addressInfo = this.form.get('addressInfo')?.value;
      const contactInfo = this.form.get('contactInfo')?.value;

      const phoneNumber = contactInfo?.phoneNumber?.e164Number;

      const address = {
        addressLine1: addressInfo?.addressLine1,
        city: addressInfo?.city,
        state: addressInfo?.state,
        zipCode: addressInfo?.zipCode
      } as SubscriberAddress;

      const contact =
      {
        mobilePhone: contactInfo?.phoneType === 'MOBILE' ? phoneNumber : null,
        phone: contactInfo?.phoneType === 'OTHER' ? phoneNumber : null,
        email: contactInfo?.email
      } as SubscriberContact;

      Promise.all(
        [
          this.subscriptionService.setDependentAddress(dependentId, address),
          this.subscriptionService.setSubscriberContact(dependentId, contact)
        ]
      ).then(() =>
      {
        this.router.navigate(['/enroll/dependents']);
      }).catch((err: ErrorCodeObj) =>
      {
        console.error(err.msg);
        if( err.statusCode === HttpStatus.StatusCodes.FORBIDDEN)
        {
          this.router.navigate(['enroll']);
        }else if(err.statusCode === HttpStatus.StatusCodes.BAD_REQUEST ){
          this.toastr.error(err.msg);
        }

      }).finally(()=> this.spinner.hide());

   });

  }

  onClickBack()
  {
    const params = this.route.snapshot.queryParams;
    this.router.navigate(['/enroll/dependent-info'], {queryParams: params});
  }

}


