export interface Registration
{
  id?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber: string;
  status?: string;
  clientName: string;
  startDate?: any;
  endDate?: any;
  lastModified?: Date;
}

export interface RegData {
  reg: Registration;
  isSelected: boolean;
}


export class RegistrationAdapter {

  static adaptSingle(response: any): Registration
  {
     return {
        id: response?.id,
        firstName: response?.firstName,
        lastName: response?.lastName,
        email: response?.email,
        phoneNumber: response?.phoneNumber,
        clientName: response?.client?.name,
        status: response?.status?.name,
        startDate: response?.startDate,
        endDate: response?.endDate,
        lastModified: response?.updatedAt

     } as Registration;
  }

  static adaptMultiple(response: any): Registration[]
  {
    let registrations = new Array<Registration>();

    response.forEach( (item: any) => {
      registrations.push({
        id: item?.id,
        firstName: item?.firstName,
        lastName: item?.lastName,
        email: item?.email,
        phoneNumber: item?.phoneNumber,
        clientName: item?.client?.name,
        status: item?.status?.name,
        startDate: item?.startDate,
        endDate: item?.endDate,
        lastModified: item?.updatedAt

     } as Registration);
    });

    return  registrations;

  }
}
