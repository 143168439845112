import { Pipe, PipeTransform } from '@angular/core';
import { getOutputFileNames } from 'typescript';
import { serialize } from 'v8';
import { DependentControl, SubGroupContainer } from '../pages/admin/admin-subscriptions/admin-subscriptions.component';

@Pipe({
  name: 'subscriptionFilter'
})
export class SubscriptionFilterPipe implements PipeTransform {

  transform(items: SubGroupContainer[], searchText: string): SubGroupContainer[] {

    const txt = searchText.toLocaleLowerCase();
    return items?.filter((group: SubGroupContainer) =>
    {
      let result = false;
      result ||= JSON.stringify(group.primary).toLocaleLowerCase().includes(txt);
      //group.primary.subscriber.info.type

      group.dependents.forEach((dep: DependentControl) =>
      {
        result ||= JSON.stringify(dep.subscriber).toLocaleLowerCase().includes(txt);
      })

      return result;
    });
  }

}

