import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EzEnrollConfirmationComponent } from './pages/ezenroll-steps/ezenroll-confirmation/ezenroll-confirmation.component';
import { EzEnrollSubscriberInfoComponent } from './pages/ezenroll-steps/ezenroll-dependent-info/ezenroll-dependent-info.component';
import { EzEnrollDependentsComponent } from './pages/ezenroll-steps/ezenroll-dependents/ezenroll-dependents.component';
import { EzEnrollPrimaryContactComponent } from './pages/ezenroll-steps/ezenroll-primary-contact/ezenroll-primary-contact.component';
import { EzEnrollPrimaryInfoComponent } from './pages/ezenroll-steps/ezenroll-primary-info/ezenroll-primary-info.component';
import { EzEnrollProvideAccessCodeComponent } from './pages/ezenroll-steps/ezenroll-provide-access-code/ezenroll-provide-access-code.component';
import { EzEnrollRequestCodeComponent } from './pages/ezenroll-steps/ezenroll-request-code/ezenroll-request-code.component';
import { ComingSoonComponent } from './pages/static/coming-soon/coming-soon.component';
import { AppAccessComponent } from './pages/ezenroll-generic/app-access/app-access.component';
import { EzenrollSubscriberContactInfoComponent } from './pages/ezenroll-steps/ezenroll-dependent-contact-info/ezenroll-dependent-contact-info.component';
import { UserAuthGuardService } from './guards/auth-guard.service';
import { AccessGuardService } from './guards/access-guard.service';
import { RegistrationGuardService } from './guards/registration-guard.service';
import { AdminLoginComponent } from './pages/admin/admin-login/admin-login.component';
import { AdminLoginCodeComponent } from './pages/admin/admin-login-code/admin-login-code.component';
import { AdminHomeComponent } from './pages/admin/admin-home/admin-home.component';
import { AdminRegistrationsComponent } from './pages/admin/admin-registrations/admin-registrations.component';
import { AdminSubscriptionsComponent } from './pages/admin/admin-subscriptions/admin-subscriptions.component';
import { AdminAccessDeniedComponent } from './pages/admin/admin-access-denied/admin-access-denied.component';
import { AdminClientsComponent } from './pages/admin/admin-clients/admin-clients.component';
import { AdminServicesComponent } from './pages/admin/admin-services/admin-services.component';

let routes: Routes = [];

if( environment.production )
{
  routes = [
    { path: '**', component: ComingSoonComponent },
  ];
}else{
   routes = [
    { path: '', redirectTo: 'enroll/request-code', pathMatch: 'full' },
    { path: 'enroll', redirectTo: 'enroll/request-code', pathMatch: 'full'},
    { path: 'enroll/start', component: EzEnrollProvideAccessCodeComponent, canActivate: [AccessGuardService]},
    { path: 'enroll/confirmation', component: EzEnrollConfirmationComponent, canActivate: [AccessGuardService]},
    { path: 'enroll/request-code', component: EzEnrollRequestCodeComponent, canActivate: [AccessGuardService]},
    { path: 'enroll/primary-info', component: EzEnrollPrimaryInfoComponent, canActivate: [AccessGuardService, RegistrationGuardService]},
    { path: 'enroll/primary-contact', component: EzEnrollPrimaryContactComponent, canActivate: [AccessGuardService, RegistrationGuardService]},
    { path: 'enroll/dependents', component: EzEnrollDependentsComponent, canActivate: [AccessGuardService, RegistrationGuardService]},
    { path: 'enroll/dependent-info', component: EzEnrollSubscriberInfoComponent, canActivate: [AccessGuardService, RegistrationGuardService]},
    { path: 'enroll/dependent-contact', component: EzenrollSubscriberContactInfoComponent, canActivate: [AccessGuardService, RegistrationGuardService]},
    { path: 'enroll/access', component: AppAccessComponent},

    { path: 'admin', redirectTo: 'admin/home', pathMatch: 'full'},
    { path: 'admin/login', component: AdminLoginComponent},
    { path: 'admin/login/code', component: AdminLoginCodeComponent},
    { path: 'admin/home', component: AdminHomeComponent, canActivate: [UserAuthGuardService], data: {roles: ['ADMIN', 'USER', 'BROKER']}},
    { path: 'admin/registrations', component: AdminRegistrationsComponent, canActivate: [UserAuthGuardService], data: {roles: ['ADMIN', 'USER', 'BROKER']}},
    { path: 'admin/subscriptions', component: AdminSubscriptionsComponent, canActivate: [UserAuthGuardService], data: {roles: ['ADMIN']}},
    { path: 'admin/clients', component: AdminClientsComponent, canActivate: [UserAuthGuardService], data: {roles: ['ADMIN']}},
    { path: 'admin/services', component: AdminServicesComponent, canActivate: [UserAuthGuardService], data: {roles: ['ADMIN']}},
    { path: 'admin/access-denied', component: AdminAccessDeniedComponent}
  ];
}
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
