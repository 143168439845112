import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorCodeObj } from 'src/app/models/error.model';
import { DependentListInfo, Subscriber } from 'src/app/models/subscription.model';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-manage-Dependents',
  templateUrl: './manage-dependents.component.html',
  styleUrls: ['./manage-dependents.component.scss']
})
export class ManageDependentsComponent implements OnInit {

  @Input() dependents = new Array<Subscriber>();
  @Input() loading: boolean = true;

  constructor(
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    this.dependents = new Array<Subscriber>();
   }

  ngOnInit(): void {

  }

  onClickDelete(id: string)
  {
    this.subscriptionService.removeDependent(id).then(()=>
    {
      this.dependents = this.dependents.filter((dep: Subscriber) => dep.info.id !== id);
    }).catch((err: ErrorCodeObj) => console.error(err.msg));

  }

  onClickEdit(id: string)
  {
    this.router.navigate(['enroll/dependent-info'], {queryParams: {did: id}});
  }


}
