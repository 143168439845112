import { Component, OnInit } from '@angular/core';
import * as HttpStatus from 'http-status-codes';

@Component({
  selector: 'app-ezenroll-confirmation',
  templateUrl: './ezenroll-confirmation.component.html',
  styleUrls: ['./ezenroll-confirmation.component.scss']
})
export class EzEnrollConfirmationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
