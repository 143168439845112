import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { SubscriberAddress } from 'src/app/models/subscription.model';

@Component({
  selector: 'app-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.scss']
})
export class AddressInfoComponent implements OnInit {

  @Input() contactName = "Primary Member";
  @Input() formGroupName!: string;
  @Input() primaryAddress!: SubscriberAddress | undefined;
  @Input() googleId = 'google-places';

  usePrimaryAddress = false;

  form!: FormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  onClickPrimaryAddress(event: any)
  {
    this.usePrimaryAddress = event.target.checked;

    if( event.target.checked )
    {
      this.form.controls['addressLine1'].setValue(this.primaryAddress?.addressLine1);
      this.form.controls['city'].setValue(this.primaryAddress?.city);
      this.form.controls['state'].setValue(this.primaryAddress?.state);
      this.form.controls['zipCode'].setValue(this.primaryAddress?.zipCode);

    }else{
      this.form.controls['addressLine1'].reset();
      this.form.controls['city'].reset();
      this.form.controls['state'].reset();
      this.form.controls['zipCode'].reset();
    }
  }

  handleAddressChange(address: any) {

    let count = 0;
    if( address?.address_components?.length )
    {
      count = address?.address_components?.length;
    }

    let streetNumber = '';
    let route = '';
    let city = '';
    let state = '';
    let zipCode = '';

    if( address?.address_components )
    {
      address?.address_components.forEach((element: any) => {

        if( element?.types[0] === "street_number") {
          streetNumber = element?.long_name;
        }

        if( element?.types[0] === "route") {
          route = element?.long_name;
        }

        //if( element?.types[0] === "administrative_area_level_3") {
        // city = element?.long_name;
        //}

        if( element?.types[0] === "locality" || element?.types[1] === "sublocality") {
          city = element?.long_name;
        }

        if( element?.types[0] === "administrative_area_level_1") {
          state = element?.short_name;
        }

        if( element?.types[0] === "postal_code") {
        zipCode = element?.long_name;
        }

        count--;
        if( count == 0 ){
          this.form.patchValue(
            {
              addressLine1: String(streetNumber + ' ' + route).trim(),
              city: city,
              state: state,
              zipCode: zipCode
            }
          );
        }
      });
    }

  }

  checkPrimary(_event: any)
  {
    const matchesPrimary =
      (this.form.controls['zipCode'].value === this.primaryAddress?.zipCode)
      &&
      (this.form.controls['addressLine1'].value === this.primaryAddress?.addressLine1)
      &&
      (this.form.controls['city'].value === this.primaryAddress?.city)
      &&
      (this.form.controls['state'].value === this.primaryAddress?.state);

    this.form.controls['usePrimaryAddress'].patchValue(matchesPrimary);
    }
}

