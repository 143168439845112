import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-Dependent-info',
  templateUrl: './dependent-info.component.html',
  styleUrls: ['./dependent-info.component.scss']
})
export class SubscriberInfoComponent implements OnInit {

  @Input() formGroupName!: string;

  form!: FormGroup;
  constructor(
    private rootFormGroup: FormGroupDirective
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

}
