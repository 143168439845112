import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServiceSearchResults } from 'src/app/models/service.model';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  activeTab = 'user';
  searchZipcode = '';
  countyName = '';
  searchComplete = false;

  searchResult:  ServiceSearchResults = {
    county: {
      id: '',
      name: ''
    },
    state: {
      id: '',
      shortName: '',
      longName: ''
    },
    providers: [],
    services: []
  };

  constructor(
    private spinner: NgxSpinnerService,
    private adminService: AdminService,
    private toastr: ToastrService
  ) { }


  ngOnInit(): void {
  }

  setTab(name: string)
  {
    this.activeTab = name;
  }


  onClickServiceByCounty()
  {
    alert('Future Development');
  }

  async onClickServiceByZip()
  {
    this.searchZipcode = this.searchZipcode.trim();

    if( this.searchZipcode.length === 5)
    {
      this.spinner.show().then(() =>
      {
        this.adminService.getServicesByZipCode(this.searchZipcode)
          .then((result: ServiceSearchResults)=>
          {
            this.searchResult = result;
            this.searchComplete = true;
          }).catch((err: any) => {
            console.error(err);
            this.searchComplete = false;
            this.toastr.error('Failed to find services for ' + this.searchZipcode);
          })
          .finally(()=> this.spinner.hide());
      });
    }
  }

  async handleKeyUp(event: any)
  {
    if( event.keyCode === 13)
    {
      this.spinner.show().then(()=>
      {
        this.onClickServiceByZip();
      });
    }
  }
}
